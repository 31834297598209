import { AutoComplete } from "primereact/autocomplete";
import { useState } from "react";

function AutoCompleteCustom({arrayValues, ...props}) {
  const [filteredList, setFilteredList] = useState();
  
  const searchList = (event) => {
    setTimeout(() => {
      let filtered;
      if (!event.query.trim().length) {
        filtered = [...arrayValues];
      } else {
        filtered = arrayValues.filter((data) => {
          return data[props.field].toLowerCase().includes(event.query.toLowerCase());
        });
      }
      setFilteredList(filtered);
    }, 250);
  };

  return <AutoComplete completeMethod={searchList} suggestions={filteredList} {...props }/>;
}

export default AutoCompleteCustom;
