import React, { useContext, useState, useEffect } from "react";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { AutoComplete } from "primereact/autocomplete";
import { TYPES_NOVEDAD } from "../data/tipoNovedades";

const FieldRecursos = ({ recursos, setRecursos, tipoNovedad }) => {
  const {
    recursosArray,
    recursosFilteredManager,
    recursosSameManager,
    periodos,
    getPeriodos,
  } = useContext(NovedadesContext);
  const [filteredRecursos, setFilteredRecursos] = useState();
  const [arrayRecursos, setArrayRecursos] = useState([]);

  const [periodo, setPeriodo] = useState("");
  const [recurso, setRecurso] = useState("");
  const [sbActual, setSbActual] = useState(null);
  const [importe, setImporte] = useState(null);
  const [retroactivo, setRetroactivo] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    recursos.push({
      Periodo: periodo,
      Recurso: recurso.Resumen,
      SbActual: sbActual,
      Importe: importe,
      Retroactivo: retroactivo,
      Delete: recurso.Resumen + importe + periodo + retroactivo,
    });
    setRecurso("");
    setSbActual(null);
    setImporte(null);
    setRetroactivo(false);
  };

  useEffect(() => {
    if (tipoNovedad === TYPES_NOVEDAD.AJSB) {
      let array = recursosFilteredManager?.map((item) => {
        let Resumen =
          item.Apellido + " " + item.Nombre + " (" + item.Legajo + ")";
        return {
          ...item,
          Resumen,
        };
      });
      setArrayRecursos(array);
    } else {
      let array = recursosSameManager?.map((item) => {
        let Resumen =
          item.Apellido + " " + item.Nombre + " (" + item.Legajo + ")";
        return {
          ...item,
          Resumen,
        };
      });
      setArrayRecursos(array);
    }
    // setRecursos([]);
    // } else {
    //   let array = recursosArray.map((item) => {
    //     let Resumen =
    //       item.Apellido + " " + item.Nombre + " (" + item.Legajo + ")";
    //     return {
    //       ...item,
    //       Resumen,
    //     };
    //   });
    //   setArrayRecursos(array);
    // setRecursos([]);
    // }
  }, []);

  useEffect(() => {
    getPeriodos();
  }, []);

  const searchRecursos = (event) => {
    setTimeout(() => {
      let _filteredRecursos;
      if (!event.query.trim().length) {
        _filteredRecursos = [...arrayRecursos];
      } else {
        _filteredRecursos = arrayRecursos.filter((p) => {
          return p.Resumen.toLowerCase().includes(event.query.toLowerCase());
        });
      }
      setFilteredRecursos(_filteredRecursos);
    }, 250);
  };

  const deleteRecurso = (rowData) => {
    setRecursos(recursos.filter((e) => e.Delete !== rowData.Delete));
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text button-icon"
        onClick={() => deleteRecurso(rowData)}
        aria-label="Submit"
      />
    );
  };

  return (
    <>
      <div className="container-border">
        <br />
        <h4 className="subtitleh4">Recurso</h4>
        <div className="row-container">
          <div className="input-container small">
            <span className="p-float-label">
              <Dropdown
                className="input-container row"
                id="periodo"
                name="periodo"
                value={periodo}
                onChange={(e) => {
                  setPeriodo(e.value);
                }}
                options={periodos}
              />
              <label htmlFor="periodo">Período*</label>
            </span>
          </div>
          <div className="input-container medium">
            <span className="p-float-label">
              <AutoComplete
                id="recurso"
                dropdown
                value={recurso}
                suggestions={filteredRecursos}
                completeMethod={searchRecursos}
                field="Resumen"
                forceSelection
                onChange={(e) => {
                  setRecurso(e.value);
                  setSbActual(e.value?.Sueldo);
                }}
              />
              <label htmlFor="recurso">Recurso*</label>
            </span>
          </div>
        </div>

        <div className="row-container">
          {tipoNovedad === TYPES_NOVEDAD.AJSB && (
            <div className="input-container small basico">
              <span className="p-float-label">
                <InputNumber
                  minFractionDigits={2}
                  maxFractionDigits={3}
                  id="sbActual"
                  value={sbActual}
                  autoComplete="off"
                  disabled
                />
                <label htmlFor="sbActual">Básico Actual*</label>
              </span>
            </div>
          )}

          {tipoNovedad === TYPES_NOVEDAD.AJSB ? (
            <div className="input-container small basico">
              <span className="p-float-label">
                <InputNumber
                  min={sbActual}
                  minFractionDigits={2}
                  maxFractionDigits={3}
                  id="importe"
                  value={importe}
                  autoComplete="off"
                  onValueChange={(e) => {
                    setImporte(e.value);
                  }}
                />
                <label htmlFor="importe">Nuevo Básico*</label>
              </span>
            </div>
          ) : (
            <div className="input-container small div-importe">
              <span className="p-float-label">
                <InputNumber
                  minFractionDigits={2}
                  maxFractionDigits={3}
                  id="importe"
                  value={importe}
                  autoComplete="off"
                  onValueChange={(e) => {
                    setImporte(e.value);
                  }}
                />
                <label htmlFor="importe">Importe*</label>
              </span>
            </div>
          )}

          <div className="input-container small">
            <Button
              disabled={(!importe || !recurso || !periodo) && true}
              onClick={(e) => handleClick(e)}
              type="submit"
              label="Agregar"
              className="short-button"
            />
          </div>
        </div>
      </div>
      <div className="container-tableCecos">
        <DataTable
          emptyMessage="No hay registros"
          value={recursos}
          responsiveLayout="scroll"
          style={{ fontFamily: "var(--main-font)" }}
        >
          <Column field="Recurso" header="Recurso"></Column>
          <Column field="Periodo" header="Periodo"></Column>
          <Column field="SbActual" header="Básico Actual"></Column>
          <Column field="Importe" header="Importe"></Column>
          <Column
            headerStyle={{ width: "4rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </>
  );
};

export default FieldRecursos;
