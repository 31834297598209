export const TYPES_NOVEDAD = {
  AJSB: "AJSB",
  AMDS: "AMDS",
};

export const tipoNovedadesAjustes = [
  { id: 1, type: TYPES_NOVEDAD.AJSB, desc: "Actualizar sueldo básico" },
  { id: 2, type: TYPES_NOVEDAD.AMDS, desc: "Actualización masiva de sueldos" },
];

export const tipoNovedadesDescuentos = [
  { id: 1, type: "DEPR", desc: "Descuento préstamo" },
  { id: 2, type: "DEBC", desc: "Descuento beneficio comedor" },
  { id: 3, type: "DECA", desc: "Embargo" },
  { id: 4, type: "DEPP", desc: "Diferencia prepaga" },
];

export const tipoNovedadesGratificaciones = [
  { id: 1, type: "GRBO", desc: "Bono" },
  { id: 2, type: "GRAT", desc: "Gratificación" },
  { id: 3, type: "GRRE", desc: "Gratificación referidos" },
  { id: 4, type: "GRPE", desc: "Plus eventual" },
];
