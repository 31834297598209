import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import "./ModalGuardarCotizacion.css";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import { useContext } from "react";

const ModalGuardarButton = () => {
  const { setIsOpenModal, rutaSeleccionada, setGuardarCoti } =
    useContext(CotizadorContext);
  const navigate = useNavigate();

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        visible={true}
        closable={false}
        style={{ width: "380px", height: "231px" }}
      >
        <p className="title-modal-guardar-coti">Confirmación</p>
        <div style={{ display: "flex" }}>
          <i
            className="pi pi-exclamation-triangle"
            id="exclamation-triangle-modal"
          ></i>
          <p className="p-modal-guardar-coti">
            ¿Estás seguro de que querés salir?
          </p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "36px" }}
        >
          <p onClick={() => setIsOpenModal(false)} className="p-no-modal">
            No
          </p>
          <button
            onClick={() => {
              if (rutaSeleccionada) {
                navigate(rutaSeleccionada);
                setIsOpenModal(false);
                setGuardarCoti(false);
              } else {
                navigate("/cotizador");
                setIsOpenModal(false);
              }
            }}
            className="button-salir-modal"
          >
            Sí, salir
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalGuardarButton;
