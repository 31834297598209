import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import "./modalHoras.css";

const ModalHoras = ({ position, cancel, totalHorasTalento }) => {
  const { horasDelProyecto, setHorasDelProyecto } = useContext(CotizadorContext);
  const [horasDesvio, setHorasDesvio] = useState(0);

  useEffect(() => {
    setHorasDesvio(totalHorasTalento - horasDelProyecto);
  }, [horasDelProyecto, totalHorasTalento]);

  const onHide = (name) => {
    cancel(false);
  };

  const handleAplicar = () => {
    setHorasDelProyecto(totalHorasTalento)
    cancel(false);
  };

  const footer = (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <Button
        label="ACEPTAR NUEVA ESTIMACION"
         onClick={handleAplicar}
        className="boton-footer-cancelar-modal"
        style={{ color: "#535353", backgroundColor: "transparent" }}
      />
      <Button
        label="AJUSTAR HORAS"
        className="boton-footer-aplicar-modal"
       onClick={() => cancel(false)}
      />
    </div>
  );

  return (
    <>
      <Dialog
        draggable={false}
        closable={false}
        id="custom-horas-modal"
        style={{
          width: "887px",
          height: "443px",
          borderRadius: "16px!important",
        }}
        visible={true}
        position={position}
        onHide={() => onHide("onHide")}
      >
        <div>
          <i
            className={"pi pi-exclamation-circle"}
            style={{ fontSize: "80px" }}
          ></i>
        </div>
        <div className="dialog-title">
          Tenés un desvío de {horasDesvio} horas.
        </div>
        <div className="dialog-subtitle">
          Configuraste <strong>{horasDelProyecto}hs</strong> para el proyecto, y
          las horas de los roles totalizan un valor de
          <strong> {totalHorasTalento}hs</strong>.
          <br />
          ¿Cómo querés continuar?
        </div>
        <div className="dialog-content"></div>
        {footer}
      </Dialog>
    </>
  );
};

export default ModalHoras;
