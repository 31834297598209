import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Container from "./primitives/Container";
import List from "./primitives/List";
import ItemList from "./primitives/ItemList";
import Link from "./primitives/Link";
import Span from "./primitives/Span";
import { AuthContext } from "../contexts/AuthContext";
import "../styles/menu.css";
import { Tooltip } from "primereact/tooltip";
import { copyClipboard } from "../helpers/copyClipboard";
import { CotizadorContext } from "../contexts/CotizadorContext";
import { appEnvironment, APP_ENV_TYPES } from "../helpers/appEnvironment";

const Menu = () => {
  const [expanded, setExpanded] = useState(false);
  const { usuario, logout, getTokenFromSessionStorage } =
    useContext(AuthContext);
  const { guardarCoti, setIsOpenModal, setRutaSeleccionada } =
    useContext(CotizadorContext);
  const navigate = useNavigate();

  const environment = appEnvironment();

  const handleClick = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const iniciales = usuario.Email.substring(0, 2).toUpperCase();

  const onClickLogout = () => {
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar sesión?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        logout();
        navigate("/login");
      },
      reject: () => console.log(),
    });
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".main-menu")) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [expanded]);

  const handleDoubleClick = () => {
    copyClipboard(getTokenFromSessionStorage(process.env.REACT_APP_TOKEN_KEY));
  };

  const hanldeNavigation = (route) => {
    if (guardarCoti) {
      setIsOpenModal(true);
      setRutaSeleccionada(route);
    } else {
      navigate(route);
    }
  };

  return (
    <Container
      className={`card ${expanded && "card-expanded"}`}
      onClick={handleClickOutside}
    >
      <Tooltip target=".custom-target-icon" />
      <ConfirmDialog />
      <Container as="nav" className={`main-menu ${expanded && "expanded"}`}>
        <List>
          <ItemList style={{ marginLeft: "5px" }}>
            <Container className="avatar-custom">
              <Avatar
                label={iniciales}
                onDoubleClick={handleDoubleClick}
                className="mr-2"
                size="large"
                style={{
                  backgroundColor: "#ffffffc2",
                  color: "#40498E",
                }}
                shape="circle"
              />
              <Span className="nav-text name-user">{usuario.Email}</Span>
            </Container>
          </ItemList>
          <ItemList onClick={(e) => handleClick(e)}>
            <Link href="" className="item-menu">
              <i
                className={`custom-target-icon pi pi-fw ${
                  expanded ? "pi-times" : "pi-bars"
                }`}
                style={{ width: "60px" }}
                data-pr-tooltip={expanded ? "" : "Menú"}
              ></i>
            </Link>
          </ItemList>
          <ItemList className="has-subnav">
            <div
              className="item-menu"
              onClick={() => hanldeNavigation("/miportal")}
            >
              <i
                className="custom-target-icon pi pi-fw pi-user"
                style={{ width: "60px" }}
                data-pr-tooltip="Portal del patagonian"
              ></i>
              <Span className="nav-text">Portal del patagonian</Span>
            </div>
          </ItemList>
          {usuario.Rol !== "" && (
            <ItemList className="has-subnav">
              <div
                className="item-menu"
                onClick={() => hanldeNavigation("/bandeja")}
              >
                <i
                  className="custom-target-icon pi pi-fw pi-inbox"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Bandeja de entrada"
                ></i>
                <Span className="nav-text">Bandeja de entrada</Span>
              </div>
            </ItemList>
          )}
          {usuario.Rol !== "" && (
            <ItemList className="has-subnav">
              <div
                className="item-menu"
                onClick={() => hanldeNavigation("/novedades")}
              >
                <i
                  className="custom-target-icon pi pi-fw pi-send"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Bandeja de salida"
                ></i>
                <Span className="nav-text">Bandeja de salida</Span>
              </div>
            </ItemList>
          )}
          {usuario.Rol !== "" && (
            <ItemList className="has-subnav">
              <div
                className="item-menu"
                onClick={() => hanldeNavigation("/informes")}
              >
                <i
                  className="custom-target-icon pi pi-info-circle"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Informes"
                ></i>
                <Span className="nav-text">Informes</Span>
              </div>
            </ItemList>
          )}
          {(usuario.EsAdministrador || usuario.Rol === "po") && (
            <ItemList className="has-subnav">
              <div
                className="item-menu"
                onClick={() => hanldeNavigation("/panelfreelance")}
              >
                <i
                  className="custom-target-icon pi pi-users"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Freelance"
                ></i>
                <Span className="nav-text">Freelance</Span>
              </div>
            </ItemList>
          )}
          {usuario.EsAdministrador && (
            <ItemList className="has-subnav">
              <div
                className="item-menu"
                onClick={() => hanldeNavigation("/paneladmin")}
              >
                <i
                  className="custom-target-icon pi pi-fw pi-wrench"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Panel de administrador"
                ></i>
                <Span className="nav-text">Panel de administrador</Span>
              </div>
            </ItemList>
          )}

          {/*(usuario.EsAdministrador ||
            usuario.Rol === "servicios" ||
            usuario.Rol === "admin" ||
            usuario.Rol === "timesheetReport") && (
              <ItemList className="has-subnav">
                <NavLink to="/proyectos" className="item-menu">
                  <i
                    className="custom-target-icon pi pi-briefcase"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Proyectos"
                  ></i>
                  <Span className="nav-text">Proyectos</Span>
                </NavLink>
              </ItemList>
            )*/}

          <ItemList className="has-subnav">
            <div
              className="item-menu"
              onClick={() => hanldeNavigation("/timesheet")}
            >
              <i
                className="custom-target-icon pi pi-fw pi-calendar"
                style={{ width: "60px" }}
                data-pr-tooltip="Timesheet"
              ></i>
              <Span className="nav-text">Timesheet</Span>
            </div>
          </ItemList>

          {environment !== APP_ENV_TYPES.PROD && (
            <>
              {" "}
              {(usuario.Rol === "board" ||
                usuario.Rol === "servicios" ||
                usuario.Rol === "comercial") && (
                <ItemList className="has-subnav">
                  <div
                    className="item-menu"
                    onClick={() => hanldeNavigation("/cotizador")}
                  >
                    <i
                      className="custom-target-icon pi pi-calculator"
                      style={{ width: "60px" }}
                      data-pr-tooltip="Cotizador"
                    ></i>
                    <Span className="nav-text">Cotizador</Span>
                  </div>
                </ItemList>
              )}{" "}
              {(usuario.Rol === "board" ||
                usuario.Rol === "ta" ||
                usuario.Rol === "po" ||
                usuario.Rol === "servicios" ||
                usuario.Rol === "comercial") && (
                <ItemList className="has-subnav">
                  <div
                    className="item-menu"
                    onClick={() => hanldeNavigation("/Tarifario")}
                  >
                    <i
                      className="custom-target-icon pi"
                      style={{
                        width: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      data-pr-tooltip="Tarifario"
                    >
                      <span className="material-symbols-outlined">
                        request_quote
                      </span>
                    </i>
                    <Span className="nav-text">Tarifario</Span>
                  </div>
                </ItemList>
              )}
              <ItemList className="has-subnav">
                <div
                  className="item-menu"
                  onClick={() => hanldeNavigation("/habilidades")}
                >
                  <i
                    className="custom-target-icon pi"
                    style={{
                      width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    data-pr-tooltip="Mis habilidades"
                  >
                    <span class="material-symbols-outlined">psychology</span>
                  </i>
                  <Span className="nav-text">Mis habilidades</Span>
                </div>
              </ItemList>
            </>
          )}

          <ItemList onClick={onClickLogout}>
            <Link className="item-menu">
              <i
                className="custom-target-icon pi pi-fw pi-sign-out"
                data-pr-tooltip="Cerrar sesión"
              ></i>
              <Span className="nav-text">Cerrar sesión</Span>
            </Link>
          </ItemList>
        </List>
      </Container>
    </Container>
  );
};

export default Menu;
