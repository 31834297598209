import { useRef, useContext, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { AuthContext } from "../contexts/AuthContext";
import AutoCompleteCustom from "./AutoCompleteCustom";
import FileUploadControl from "./FileUploadControl";
import FieldProveedor from "./FieldProveedor";
import { fechaHora } from "../helpers";
import { validateTotUploadSize } from "../helpers/validateTotUploadSize";
import "../styles/formNuevoCC.css";

const FormNuevoCC = ({ setDisplayPosition, displayPosition, position }) => {
  const { addNovedad, getClientes, clientes } = useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  // const [comentarios, setComentarios] = useState("");
  const [agregarProv, setAgregarProv] = useState(false);
  const [razonSocial, setRazonSocial] = useState("");
  const [cuit, setCuit] = useState("");
  const [nuevoProv, setNuevoProv] = useState(null);
  const [adjuntos, setAdjuntos] = useState();

  const toast = useRef(null);

  useEffect(() => {
    getClientes();
  }, []);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);
    if (name === "displayPosition") {
      formik.resetForm();
      setNuevoProv(null);
      setRazonSocial("");
      setCuit("");
      setAgregarProv(false);
      setAdjuntos([]);
    }
  };
  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      cecos: "",
      cliente: "",
      comentarios: "",
    },
    validate: (data) => {
      if (nuevoProv) {
        data.cliente = nuevoProv;
      }
      let errors = {};
      if (!data.cecos) {
        errors.cecos = "Campo requerido.";
      }
      if (!data.cliente) {
        errors.cliente = "Campo requerido.";
      }
      return errors;
    },

    onSubmit: (data) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "La suma de porcentajes de proyectos debe ser igual a 100",
        life: 5000,
      });
      setFormData(data);
      setShowMessage(true);
      onHide("displayPosition");
      setAdjuntos([]);
      formik.resetForm();
      addNovedad(
        {
          Tipo: "NC",
          Descripcion: nuevoProv
            ? "Nuevo centro de costos y cliente"
            : "Nuevo centro de costos",
          Fecha: fechaHora().fecha,
          Hora: fechaHora().hora,
          Usuario: username,
          Cliente: nuevoProv
            ? formik.values.cliente
            : formik.values.cliente.Resumen,
          ClienteNuevo: nuevoProv ? true : false,
          CecosNuevo: formik.values.cecos,
          Comentarios: formik.values.comentarios,
        },
        adjuntos
      );
    },
  });

  return (
    <Dialog
      draggable={false}
      header="Nuevo centro de costos"
      blockScroll={true}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-nuevo-cc">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          {agregarProv ? (
            <FieldProveedor
              setNuevoProv={setNuevoProv}
              setAgregarProv={setAgregarProv}
              setCuit={setCuit}
              setRazonSocial={setRazonSocial}
              cuit={cuit}
              razonSocial={razonSocial}
            />
          ) : (
            <>
              <div className="p-fluid grid">
                {!nuevoProv ? (
                  <div className="input-container">
                    <span className="p-float-label">
                      <AutoCompleteCustom
                        id="cliente"
                        arrayValues={clientes}
                        dropdown
                        field="Resumen"
                        value={formik.values.cliente}
                        forceSelection
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("cliente"),
                        })}
                      />
                      <label
                        htmlFor="cliente"
                        className={classNames({
                          "p-error": isFormFieldValid("cliente"),
                        })}
                      >
                        Cliente*
                      </label>
                    </span>
                    {getFormErrorMessage("cliente")}
                  </div>
                ) : (
                  <div className="input-container">
                    <span className="p-float-label">
                      <InputText
                        id="cliente"
                        name="cliente"
                        disabled
                        value={nuevoProv}
                      />
                      <label htmlFor="cliente">Nuevo Cliente</label>
                    </span>
                  </div>
                )}
                <Button
                  icon="pi pi-plus-circle"
                  className="p-button-rounded p-button-text button-icon"
                  type="button"
                  tooltip="Nuevo cliente"
                  tooltipOptions={{ position: "top" }}
                  onClick={() => setAgregarProv(true)}
                  aria-label="Agregar"
                />
              </div>
              <div className="row-container">
                <div className="input-container medium">
                  <span className="p-float-label">
                    <InputText
                      id="cecos"
                      value={formik.values.cecos}
                      autoComplete="off"
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("cecos"),
                      })}
                    />
                    <label
                      htmlFor="cecos"
                      className={classNames({
                        "p-error": isFormFieldValid("cecos"),
                      })}
                    >
                      Centro de costos*
                    </label>
                  </span>
                  {getFormErrorMessage("cecos")}
                </div>
              </div>
              <div className="row-container">
                <div className="input-container coment">
                  <span className="p-float-label">
                    <InputTextarea
                      id="comentarios"
                      maxLength={500}
                      autoResize
                      value={formik.values.comentarios}
                      autoComplete="off"
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="comentarios">Comentarios</label>
                  </span>
                </div>
              </div>
              <FileUploadControl setAdjuntos={setAdjuntos} />
              <div className="final-submit">
                <Button type="submit" label="Guardar" disabled={!validateTotUploadSize(adjuntos)} />
              </div>
            </>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default FormNuevoCC;
