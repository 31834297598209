import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "../Tarifario.css";
import { useContext, useEffect, useState } from "react";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import { Paginator } from "primereact/paginator";
import SearchingData from "../../../imagenes/SearchingData.png";

const BasesNominaInterna = () => {
  const { getTarifario, tarifario } = useContext(CotizadorContext);
  const [first, setFirst] = useState(0);
  const [filtros, setFiltros] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const onPageChange = async (event) => {
    const paginaActual = event.page + 1;
    setFirst(event.first);
    if (filtros === "") {
      getTarifario(1, paginaActual, filtros);
    } else {
      getTarifario(1, paginaActual, filtros);
    }
  };

  useEffect(() => {
    getTarifario(1, 1, "");
  }, []);

  const handleChange = (e) => {
    const nuevoFiltro = e.target.value;
    setFiltros(nuevoFiltro);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (nuevoFiltro === "") {
      getTarifario(1, 1, "");
      setFirst(0);
    } else {
      const timeoutId = setTimeout(() => {
        getTarifario(1, first + 1, nuevoFiltro);
      }, 500);
      setDebounceTimeout(timeoutId);
    }
  };

  const header = (
    <div className="">
      <Button
        type="button"
        icon="pi pi-filter"
        className="button-borrar"
        outlined="true"
        onClick={() => {
          getTarifario(1, 1, "");
          setFirst(0);
          setFiltros("");
        }}
      />
      <span className="p-input-icon-left">
        <i className="pi pi-search busca" />
        <InputText
          type="search"
          placeholder="Ingresá un rol..."
          style={{ marginLeft: "10px" }}
          className="input-buscar-tarifario"
          onChange={handleChange}
          value={filtros}
        />
      </span>
    </div>
  );

  const customHeader = (title) => (
    <div className="th-tabla-tarifario">
      <span>{title}</span>
    </div>
  );

  const formatCurrency = (value) => {
    const numberValue = Number(value);
    return `${numberValue.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const currencyBody = (rowData, field) => {
    return formatCurrency(rowData[field]);
  };

  return (
    <div className="datatable-responsive-demo">
      <div style={{ overflowX: "auto" }}>
        <DataTable
          rows={10}
          className="p-datatable-gridlines"
          header={header}
          style={{ background: "rgba(244, 247, 252, 0.75)" }}
          value={tarifario.paginatedData}
          emptyMessage={
            <div className="custom-empty-message">
              <img src={SearchingData} alt="No hay datos" />
              No hay resultados para tu búsqueda.
            </div>
          }
        >
          <Column
            field="role"
            header={customHeader("Rol")}
            style={{ minWidth: "5rem" }}
            className="tablas-tarifario"
          />
          <Column
            field="capability"
            header={customHeader("Capability")}
            className="tablas-tarifario"
          />
          <Column
            field="skill"
            header={customHeader("Skill principal")}
            className="tablas-tarifario"
          />
          <Column
            field="seniority"
            header={customHeader("Seniority")}
            className="tablas-tarifario"
          />
          <Column
            field="lowSalaryBand"
            header={customHeader("Banda salarial baja")}
            className="tablas-tarifario"
            body={(rowData) => currencyBody(rowData, "lowSalaryBand")}
          />
          <Column
            field="mediumSalaryBand"
            header={customHeader("Banda salarial media")}
            className="tablas-tarifario"
            body={(rowData) => currencyBody(rowData, "mediumSalaryBand")}
          />
          <Column
            field="highSalaryBand"
            header={customHeader("Banda salarial alta")}
            className="tablas-tarifario"
            body={(rowData) => currencyBody(rowData, "highSalaryBand")}
          />
        </DataTable>
      </div>
      <div className="card">
        <Paginator
          first={first}
          rows={20}
          totalRecords={tarifario.amount}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default BasesNominaInterna;
