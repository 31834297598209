import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import GoogleLoginButton from "../components/GoogleLoginButton";
import { AuthContext } from "../contexts/AuthContext";
import logoitp from "../imagenes/logo.png";
import { ProgressSpinner } from "primereact/progressspinner";
import { appEnvironment, APP_ENV_TYPES } from "../helpers/appEnvironment";
import "../styles/pages/login.css";

const Login = () => {
  const { usuario, loginGoogle, loadingUser, loadGoogle } =
    useContext(AuthContext);

  window.handleCredentialResponse = handleCredentialResponse;

  const environment = appEnvironment();

  function handleCredentialResponse(response) {
    // console.log(response.credential);
    var credential = response.credential;
    loginGoogle(credential);
  }

  return (
    <>
      {loadingUser ? (
        <div className="loading-message">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {usuario ? (
            usuario.Rol !== "" ? (
              <Navigate to="/novedades" />
            ) : (
              <Navigate to="/miportal" />
            )
          ) : (
            <div className="login-container">
              <div className="background-container">
                <p>
                  Crecemos,
                  <br /> innovamos y <br />
                  evolucionamos
                  <br /> juntos.
                </p>
              </div>
              <div className="googlelogin-conteiner">
                <img
                  className="img_login"
                  src={logoitp}
                  alt="logo it patagonia"
                />
                <h1>Portal de novedades</h1>
                {environment !== APP_ENV_TYPES.PROD &&
                  environment !== APP_ENV_TYPES.ND && (
                    <h2 className="titulo" style={{ color: "red" }}>
                      Ambiente {environment.toUpperCase()}
                    </h2>
                  )}
                {loadGoogle ? <ProgressSpinner /> : <GoogleLoginButton />}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Login;
