import { Card } from "primereact/card";
import "./tarjeta.css";

const cardStyle = { width: "250px", margin: "10px" };

const iconContStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const iconStyle = {
  color: "#084bfa",
  fontSize: "2.2rem",
  marginBottom: "1.5rem",
  display: "block",
  textAlign: "center",
};

const titleStyle = {
  color: "#129bfa",
  display: "block",
  fontSize: "1.5rem",
  textAlign: "center",
};

const valueStyle = {
  color: "#1bd1f9",
  display: "block",
  fontWeight: "bold",
  fontSize: "1.3rem",
  textAlign: "center",
};

const Tarjeta = ({ icon, title, value, handleClick, style, animation}) => {
  return (
    <div className={animation?"tarjeta":""} style={style}>
      <Card style={cardStyle} onClick={handleClick}>
        <div style={iconContStyle}>
          <i className={icon} style={iconStyle}></i>
        </div>
        <span style={titleStyle}>{title}</span>
        <span style={valueStyle}>{value}</span>
      </Card>
    </div>
  );
};

export { Tarjeta };
