import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useState } from "react";
import "./Habilidades.css";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";
import { Dropdown } from "primereact/dropdown";

export const ModalHabilidaes = ({
  position,
  cancel,
  infoModal,
  skills,
  guardarSkill,
  datos,
  editSkill,
  setEditSkill,
  deleteSkills,
  tecnologias,
  subTecnologias,
  guardarCertificaciones,
  nivelEstudios,
}) => {
  const {
    habilidadSeleccionada,
    setHabilidadSeleccionada,
    putCertificaciones,
    putNivelEstudios,
    getSubTecnologias,
    postNivelEstudios,
  } = useContext(HabilidadesContext);

  const [skillsSeleccionadas, setSkillsSeleccionadas] = useState([]);
  const [SubskillsSeleccionadas, setSubskillsSeleccionadas] = useState("");
  const [tecnologiaSeleccionada, setTenologiaSeleccionada] = useState("");
  const [subExistente, setSubExistente] = useState(false);
  const [filtros, setFiltros] = useState([]);
  const [skillsEliminar, setSkillsEliminar] = useState([]);
  const [archivo, setArchivo] = useState("");
  const [inputOne, setInputOne] = useState(
    habilidadSeleccionada ? habilidadSeleccionada.certificationTitle : ""
  );
  const [inputTwo, setInputTwo] = useState(
    habilidadSeleccionada ? habilidadSeleccionada.institutionName : ""
  );
  const [nivelEstudio, setNivelEstudio] = useState(
    habilidadSeleccionada ? { name: habilidadSeleccionada.levelAchieved } : ""
  );
  const [titulo, setTitulo] = useState(
    habilidadSeleccionada ? habilidadSeleccionada.degree : ""
  );
  const [nombreInsti, setNombreInsti] = useState(
    habilidadSeleccionada ? habilidadSeleccionada.institution : ""
  );

  const onHide = () => {
    cancel(false);
    setEditSkill(false);
    setHabilidadSeleccionada("");
  };
  const headerContent = (
    <div>
      <p className="p1-modal-habilidades">{`${
        editSkill ? "Editar" : "Añadir"
      } ${infoModal}`}</p>
    </div>
  );

  useEffect(() => {
    if (infoModal === "Skills Complementarias" && editSkill) {
      const transformedSkills = datos.map((skill) => ({
        _id: skill.idSkill,
        idObjet: skill._id,
        name: skill.name,
      }));
      setSkillsSeleccionadas(transformedSkills);
    }
  }, [infoModal, datos]);

  useEffect(() => {
    setFiltros([...skillsSeleccionadas]);
  }, [skillsSeleccionadas, datos]);

  useEffect(() => {
    if (tecnologiaSeleccionada) {
      setFiltros((prevFiltros) => {
        const filtroExistente = prevFiltros.find(
          (filtro) => filtro.tecnologia._id === tecnologiaSeleccionada._id
        );
        const existeSubtecnologia = prevFiltros.some(
          (filtro) => filtro.subtecnologia
        );

        if (existeSubtecnologia && SubskillsSeleccionadas !== "") {
          setSubExistente(
            prevFiltros.find(
              (filtro) =>
                filtro.subtecnologia?.name === SubskillsSeleccionadas?.name
            )
          );
        }

        if (filtroExistente && !subExistente && SubskillsSeleccionadas !== "") {
          setSubExistente(true);
          return prevFiltros.map((filtro) =>
            filtro.tecnologia._id === tecnologiaSeleccionada._id
              ? {
                  ...filtro,
                  subtecnologia: SubskillsSeleccionadas,
                  name: `${filtro.tecnologia.name} - ${SubskillsSeleccionadas.name}`,
                }
              : filtro
          );
        } else if (!filtroExistente) {
          const nuevoFiltro = {
            _id: `${tecnologiaSeleccionada._id}-${
              SubskillsSeleccionadas?._id || "no-sub"
            }`,
            name: SubskillsSeleccionadas
              ? `${tecnologiaSeleccionada.name} - ${SubskillsSeleccionadas.name}`
              : tecnologiaSeleccionada.name,
            tecnologia: tecnologiaSeleccionada,
            subtecnologia: SubskillsSeleccionadas || null,
          };
          setSubExistente(false);
          return [...prevFiltros, nuevoFiltro];
        } else if (SubskillsSeleccionadas !== "") {
          const subtecnologiaExistente = prevFiltros.some(
            (filtro) =>
              filtro.tecnologia._id === tecnologiaSeleccionada._id &&
              filtro.subtecnologia?._id === SubskillsSeleccionadas._id
          );

          if (subtecnologiaExistente) {
            console.log("La subtecnología ya existe, no se añadirá.");
            return prevFiltros;
          }

          const nuevoFiltro = {
            _id: `${tecnologiaSeleccionada._id}-${
              SubskillsSeleccionadas?._id || "sub"
            }`,
            name: SubskillsSeleccionadas
              ? `${tecnologiaSeleccionada.name} - ${SubskillsSeleccionadas.name}`
              : tecnologiaSeleccionada.name,
            tecnologia: tecnologiaSeleccionada,
            subtecnologia: SubskillsSeleccionadas || null,
          };

          setSubExistente(true);
          return [...prevFiltros, nuevoFiltro];
        }
        return prevFiltros;
      });

      if (tecnologiaSeleccionada && SubskillsSeleccionadas) {
        setTenologiaSeleccionada("");
        setSubskillsSeleccionadas("");
      }
    }
  }, [tecnologiaSeleccionada, SubskillsSeleccionadas]);

  const handleSkills = (e) => {
    setSkillsSeleccionadas(e.value);
  };

  const removeFiltro = (filtro) => {
    const nuevosFiltros = filtros.filter((item) => item._id !== filtro._id);
    setFiltros(nuevosFiltros);
    setSkillsSeleccionadas(nuevosFiltros);
    if (editSkill) {
      setSkillsEliminar((prevSkills) => [...prevSkills, filtro]);
    }
  };

  const handleGuardarSkills = () => {
    guardarSkill(skillsSeleccionadas);
  };

  const handleGuardarCertificaciones = () => {
    const skill = {
      certificationTitle: inputOne,
      institutionName: inputTwo,
      certificacionFile: archivo,
    };
    guardarCertificaciones(skill);
  };

  const handleEditarCertificaciones = () => {
    const skill = {
      id: habilidadSeleccionada._id,
      certificationTitle: inputOne,
      institutionName: inputTwo,
      certificacionFile: archivo,
    };
    putCertificaciones(skill);
    setEditSkill(false);
    setHabilidadSeleccionada("");
    cancel(false);
  };

  const handleEliminarSkills = () => {
    const arrayDeIds = skillsEliminar.map((filtro) => ({
      _id: filtro.idObjet,
    }));
    deleteSkills(arrayDeIds);
  };

  const handleGuardarNivelEstudios = () => {
    const skill = {
      idLevelAchieved: nivelEstudio._id,
      degree: titulo,
      institution: nombreInsti,
    };
    postNivelEstudios(skill);
    cancel(false);
  };

  const handleEditarNivelEstudio = () =>{
    const skill = {
      id: habilidadSeleccionada._id,
      idLevelAchieved: nivelEstudio._id,
      degree: titulo,
      institution: nombreInsti,
    };
    putNivelEstudios(skill);
    cancel(false)
  }

  const transformSkills = (skills) => {
    if (!editSkill && datos.length > 0) {
      skills = skills.filter(
        (skill) => !datos.some((datosItem) => datosItem.name === skill.name)
      );
    }
    return skills.map((skill) => {
      const { _id, name } = skill;
      return { _id, name };
    });
  };

  const getModalTitle = () => {
    if (infoModal === "Certificaciones") {
      return "Título de la certificación*";
    } else if (infoModal === "Skills Complementarias") {
      return "Skill";
    } else if (infoModal === "Nivel de estudios") {
      return "";
    }
    return infoModal;
  };

  const tamañoMaxArchivo = 1 * 1000 * 1000;
  const chooseOptions = {
    icon: "pi pi-fw pi-paperclip",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  return (
    <Dialog
      draggable={false}
      closable={true}
      id="custom-bg-modal"
      style={{
        width: "614px",
        height: infoModal === "Certificaciones" ? "451px" : "373px",
      }}
      className="custom-modal"
      visible={true}
      position={position}
      onHide={() => onHide("onHide")}
      header={headerContent}
    >
      <div style={{ padding: "15px 20px 10px 20px" }}>
        <label className="h2-modal-habilidades">{getModalTitle()}</label>
        {infoModal === "Skills Complementarias" && !editSkill && (
          <MultiSelect
            placeholder={`Seleccioná una ${
              infoModal === "Skills Complementarias" ? "Skill" : infoModal
            }`}
            style={{ width: "100%", height: "41px" }}
            className="w-full md:w-20rem select-clientes-home"
            filter
            options={transformSkills(skills)}
            optionLabel="name"
            value={transformSkills(skillsSeleccionadas)}
            onChange={handleSkills}
            disabled={editSkill}
          />
        )}

        {infoModal === "Tecnologías" && !editSkill && (
          <>
            <Dropdown
              placeholder={`Seleccioná una ${
                infoModal === "Skills Complementarias" ? "Skill" : infoModal
              }`}
              style={{ width: "100%", height: "41px", marginBottom: "15px" }}
              className="w-full md:w-20rem select-clientes-home"
              filter
              options={transformSkills(tecnologias)}
              optionLabel="name"
              value={tecnologiaSeleccionada}
              onChange={(e) => {
                setTenologiaSeleccionada(e.value);
                const selectedTecno = e.value;
                if (selectedTecno && selectedTecno._id) {
                  getSubTecnologias(selectedTecno._id);
                }
              }}
              disabled={editSkill}
            />
            <label className="h2-modal-habilidades">Subtecnología</label>
            <Dropdown
              placeholder={"Seleccioná una Subtecnología"}
              style={{ width: "100%", height: "41px" }}
              className="w-full md:w-20rem select-clientes-home"
              filter
              options={transformSkills(subTecnologias)}
              optionLabel="name"
              value={SubskillsSeleccionadas}
              onChange={(e) => setSubskillsSeleccionadas(e.value)}
              disabled={tecnologiaSeleccionada === ""}
            />
          </>
        )}

        {infoModal === "Certificaciones" && (
          <>
            <InputText
              placeholder={`P. ej.: Desarrollador Java`}
              style={{ width: "100%", height: "41px", marginBottom: "25px" }}
              className="w-full md:w-20rem select-clientes-home"
              value={inputOne}
              onChange={(e) => setInputOne(e.target.value)}
            />
            <label className="h2-modal-habilidades">
              Nombre de la institución*
            </label>
            <InputText
              placeholder={`P. ej.: Coderhouse`}
              style={{ width: "100%", height: "41px", marginBottom: "30px" }}
              className="w-full md:w-20rem select-clientes-home"
              value={inputTwo}
              onChange={(e) => setInputTwo(e.target.value)}
            />
            <label className="h2-modal-habilidades-certi">
              Contenido Multimedia*
            </label>
            <FileUpload
              style={{
                paddingRight: 15,
                marginTop: "5px",
              }}
              chooseOptions={chooseOptions}
              auto
              name="fileUploadControl"
              customUpload
              uploadHandler={(e) => setArchivo(e.files[0])}
              accept="image/*,.pdf,.jpg"
              maxFileSize={tamañoMaxArchivo}
              emptyTemplate={
                <p className="m-0">{`Arrastre o examine archivos. (tamaño máximo ${
                  tamañoMaxArchivo / 1000 / 1000
                } MB)`}</p>
              }
              invalidFileSizeMessageSummary="Error! "
              invalidFileSizeMessageDetail={`El tamaño del archivo a adjuntar supera el máximo permitido.`}
              onRemove={() => setArchivo("")}
            />
          </>
        )}

        {infoModal === "Nivel de estudios" && (
          <>
            <Dropdown
              placeholder={"Seleccioná una Subtecnología"}
              style={{ width: "100%", height: "41px", marginBottom: "10px" }}
              className="w-full md:w-20rem select-clientes-home"
              filter
              options={nivelEstudios}
              optionLabel="name"
              value={nivelEstudios.find(
                (item) => item.name === nivelEstudio.name
              )}
              onChange={(e) => setNivelEstudio(e.value)}
            />
            <label className="h2-modal-habilidades">Título*</label>
            <InputText
              placeholder={`P. ej.: Coderhouse`}
              style={{ width: "100%", height: "41px", marginBottom: "10px" }}
              className="w-full md:w-20rem select-clientes-home"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <label className="h2-modal-habilidades">
              Nombre de la institución*
            </label>
            <InputText
              placeholder={`P. ej.: Coderhouse`}
              style={{ width: "100%", height: "41px", marginBottom: "10px" }}
              className="w-full md:w-20rem select-clientes-home"
              value={nombreInsti}
              onChange={(e) => setNombreInsti(e.target.value)}
            />
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "0px 20px 10px",
          maxHeight: infoModal === "Tecnologías" ? "83px" : "none",
          overflowY: "auto",
        }}
      >
        {filtros.map((filtro) => (
          <div key={filtro._id} className="filtros-select">
            <p>{filtro.name}</p>
            <i
              className="pi pi-times-circle custom-icon-habilidades"
              style={{ cursor: "pointer" }}
              onClick={() => removeFiltro(filtro)}
            ></i>
          </div>
        ))}
      </div>
      <footer className="footer-modal-habilidades">
        <Button
          className="button-modal-habilidades-cancelar"
          onClick={() => {
            cancel(false);
            setEditSkill(false);
            setHabilidadSeleccionada("");
          }}
        >
          Cancelar
        </Button>
        <Button
          className="button-modal-habilidades"
          onClick={() => {
            infoModal === "Skills Complementarias" &&
              (editSkill ? handleEliminarSkills() : handleGuardarSkills());

            infoModal === "Certificaciones" &&
              (editSkill
                ? handleEditarCertificaciones()
                : handleGuardarCertificaciones());

            infoModal === "Nivel de estudios" &&
              (editSkill
                ? handleEditarNivelEstudio()
                : handleGuardarNivelEstudios());
          }}
          disabled={
            (infoModal === "Skills Complementarias" &&
              ((filtros.length === 0 && skillsEliminar.length === 0) ||
                datos.length === skillsSeleccionadas.length)) ||
            (infoModal === "Tecnologías" && filtros.length === 0) ||
            (infoModal === "Certificaciones" &&
              !editSkill &&
              (inputOne === "" || inputTwo === "" || archivo === "")) ||
            (infoModal === "Certificaciones" &&
              editSkill &&
              (inputOne === "" || inputTwo === "")) ||
            (infoModal === "Nivel de estudios" &&
              (nivelEstudio === "" || titulo === "" || nombreInsti === ""))
          }
        >
          Guardar
        </Button>
      </footer>
    </Dialog>
  );
};
