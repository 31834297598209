import Menu from "../../components/Menu"
import logoitp from "../../imagenes/logo.png";
import Habilidades from "./Habilidades";

const index = () => {
  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0.5rem 0 0.2rem 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      </div>
      <Habilidades />
    </div>
  );
};

export default index;
