import { useState, useRef } from "react";
import Menu from "../../../components/Menu";
import logoitp from "../../../imagenes/logo.png";
import TablaUsuariosRoles from "./TablaUsuariosRoles";
import styles from "./usuarios.module.css";
import SpeedDialCustom from "../../../components/SpeedDialCustom";
import FormNuevoUsuarioRol from "./FormNuevoUsuarioRol";
import { Toast } from "primereact/toast";

const UsuariosRoles = () => {
  const [positionModal, setPositionModal] = useState("right");
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const toast = useRef(null);
  // const [nuevoUsuario, setNuevoUsuario] = useState(false);

  const dialogFuncMap = {
    FormUser: setDisplayUserModal,
  };
  const speedDialClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    if (position) {
      setPositionModal(position);
    }
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades">
        <div>
          {" "}
          <h3 className="">Usuarios con roles</h3>
          <SpeedDialCustom speedDialClick={speedDialClick} tipoNovedad={3} />
        </div>
      </div>
      <TablaUsuariosRoles />
      <FormNuevoUsuarioRol
        setDisplayPosition={setDisplayUserModal}
        displayPosition={displayUserModal}
        position={positionModal}
      />
    </div>
  );
};

export default UsuariosRoles;
