import { Dialog } from "primereact/dialog";
import React, { useContext, useState } from "react";
import { ModalEliminarHabilidades } from "./ModalEliminarHabilidades";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";

export const ModalVerTodos = ({
  position,
  infoModal,
  datos,
  open,
  setEditSkill,
  cancel,
}) => {
  const {
    setHabilidadSeleccionada,
    nivelEstudioUser,
    skillUser,
    certificaciones,
  } = useContext(HabilidadesContext);
  const [isOpenModalEliminar, setIsOpenModalEliminar] = useState(false);

  const onHide = () => {
    open(false);
    setEditSkill(false);
  };

  const headerContent = (
    <div>
    <p className="p1-modal-habilidades">
      {infoModal === "Skills Complementarias" && skillUser.length
        ? `${infoModal} (${skillUser.length})`
        : infoModal === "Certificaciones" && certificaciones.length
        ? `${infoModal} (${certificaciones.length})`
        : infoModal === "Nivel de estudios" && nivelEstudioUser.length
        ? `${infoModal} (${nivelEstudioUser.length})`
        : infoModal}
    </p>
  </div>
  
  );


  return (
    <Dialog
      draggable={false}
      closable={true}
      id="custom-bg-modal"
      style={{
        width: "614px",
        height: "323px",
      }}
      className="custom-modal"
      visible={true}
      position={position}
      onHide={() => onHide("onHide")}
      header={headerContent}
    >
      {infoModal === "Skills Complementarias" && (
        <div className="body-card-habilidades">
          {skillUser.map((item, index) => (
            <p
              key={item._id}
              className={`p-body-card-habilidades ${
                index > 0 ? "p-body-card-habilidades-border" : ""
              }`}
            >
              {item.name}
            </p>
          ))}
        </div>
      )}

      {infoModal === "Certificaciones" &&
        certificaciones.map((item, index) => (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="body-card-habilidades-certi"
                key={item._id}
                style={{ width: "100%", padding: "0 0 8px 1.25rem" }}
              >
                <p
                  className={`p-body-card-habilidades-certi ${
                    index > 0 ? "p-body-card-habilidades-border" : ""
                  }`}
                >
                  {item.certificationTitle}
                </p>
                <p className={``}>{item.institutionName}</p>
              </div>
              <div
                className={`p-body-card-habilidades-certi ${
                  index > 0 ? "p-body-card-habilidades-border" : ""
                }`}
                style={{ marginRight: "20px", display: "flex" }}
              >
                <span
                  style={{
                    paddingLeft: "10px",
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  className="material-symbols-outlined"
                  onClick={() => {
                    cancel(true);
                    setHabilidadSeleccionada(item);
                    setEditSkill(true);
                  }}
                >
                  edit
                </span>
                <span
                  style={{
                    paddingLeft: "10px",
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  className="material-symbols-outlined"
                  onClick={() => {
                    setIsOpenModalEliminar(true);
                    setHabilidadSeleccionada(item);
                  }}
                >
                  delete
                </span>
              </div>
            </div>
          </>
        ))}

      {infoModal === "Nivel de estudios" &&
        nivelEstudioUser.map((item, index) => (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="body-card-habilidades-certi"
                key={item._id}
                style={{ width: "100%", padding: "0 0 8px 1.25rem" }}
              >
                <p
                  className={`p-body-card-habilidades-certi ${
                    index > 0 ? "p-body-card-habilidades-border" : ""
                  }`}
                >
                  {item.degree}
                </p>
                <p className={``}>{item.institution}</p>
              </div>
              <div
                className={`p-body-card-habilidades-certi ${
                  index > 0 ? "p-body-card-habilidades-border" : ""
                }`}
                style={{ marginRight: "20px", display: "flex" }}
              >
                <span
                  style={{
                    paddingLeft: "10px",
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  className="material-symbols-outlined"
                  onClick={() => {
                    cancel(true);
                    setHabilidadSeleccionada(item);
                    setEditSkill(true);
                  }}
                >
                  edit
                </span>
                <span
                  style={{
                    paddingLeft: "10px",
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  className="material-symbols-outlined"
                  onClick={() => {
                    setIsOpenModalEliminar(true);
                    setHabilidadSeleccionada(item);
                  }}
                >
                  delete
                </span>
              </div>
            </div>
          </>
        ))}

      {isOpenModalEliminar && (
        <ModalEliminarHabilidades
          open={setIsOpenModalEliminar}
          infoModal={infoModal}
          setEditSkill={setEditSkill}
        />
      )}
    </Dialog>
  );
};
