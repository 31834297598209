import React, { useState, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import TableRecursosHSList from "./TableRecursosList";
import { fechaHora } from "../helpers";
import { NovedadesContext } from "../contexts/NovedadesContext";
import "./FieldRecursosList/FieldRecursosList.css";
import { Dropdown } from "primereact/dropdown";

const FieldHorasExtrasNP = ({ setArrayHorasExtras, arrayHorasExtras, fechasSeleccionadas, setFechasSeleccionadas }) => {

    const [fechaHE, setFechaHE] = useState();
    const [cantidad, setCantidad] = useState("");
    const [porcentaje, setPorcentaje] = useState(null);
    const [tipohora, setTipohora] = useState("");
    const [tipodia, setTipodia] = useState("");
    const tipodias = [{ data: "50%" }, { data: "100%" }, { data: "feriado" }, { data: "dia gremio" }];
    const tipohoras = [{ data: "diurno" }, { data: "nocturno" }];
    const [valuePeriodo, setValuePeriodo] = useState(null);
    const { periodos, getPeriodos } = useContext(NovedadesContext);

    useEffect(() => {
        getPeriodos();
        // setArrayHorasExtras([])
    }, []);

    useEffect(() => {
        setFechaHE(minCalendarDate);
    }, [valuePeriodo]);
    
    
    const handleClick = (e) => {
        e.preventDefault();
        const nuevaHoraExtra = {
            Periodo: fechaHora(fechaHE).fecha,
            HorasExtras: [{ TipoDia: tipodia.data, TipoHora: tipohora.data, Cantidad: cantidad }],
            valorBorrar:
            fechaHora(fechaHE).fecha + ", " + cantidad,
        }
        setArrayHorasExtras([...arrayHorasExtras, nuevaHoraExtra])
        setFechasSeleccionadas([...fechasSeleccionadas, fechaHE])
        setFechaHE(null);
        setCantidad("");
        setPorcentaje("");
        setTipodia("");
        setTipohora("");
    }

    if (valuePeriodo) {
        const array = valuePeriodo.split("-");
        const month = parseInt(array[0]);
        const year = parseInt(array[1]);
        var minCalendarDate = new Date(year, month - 1, 1);
        var maxCalendarDate = new Date(year, month - 1, 31);
    } else {
        var minCalendarDate = new Date();
        var maxCalendarDate = new Date();
    }
    return (
        <>
            <h4 className="form-subtitle">Informe y autorización horas extras</h4>
            <div className="row-container cont-recursos">
                <div className="input-container small">
                    <span className="p-float-label">
                        <Dropdown
                            className="input-container row"
                            id="periodo"
                            name="periodo"
                            value={valuePeriodo}
                            onChange={(e) => {
                                setValuePeriodo(e.value);
                            }}
                            options={periodos}
                        />
                        <label htmlFor="periodo">Período*</label>
                    </span>
                </div>
                <div className="input-container medium">
                    <span className="p-float-label">
                        <Calendar
                            locale="es"
                            showIcon
                            value={valuePeriodo ? fechaHE : []}
                            disabled={(!valuePeriodo) && true}
                            onChange={(e) => setFechaHE(e.value)}
                            dateFormat="dd/mm/yy"
                            view="date"
                            maxDate={maxCalendarDate}
                            minDate={minCalendarDate}
                            disabledDates={fechasSeleccionadas}
                        />
                        <label htmlFor="fechaHE">Fecha</label>
                    </span>
                </div>
                <div className="input-container" style={{ width: "400px" }}>
                    <span className="p-float-label">
                        <InputNumber
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            inputId="cantidad"
                            value={cantidad}
                            onValueChange={(e) => setCantidad(e.value)}
                        />
                        <label htmlFor="cantidad">Cant.</label>
                    </span>
                </div>
                <div className="input-container" style={{ width: "70px" }}>
                    <span className="p-float-label">
                        <Dropdown
                            value={tipodia}
                            onChange={(e) => setTipodia(e.value)}
                            options={tipodias}
                            optionLabel="data"
                            className="w-full md:w-14rem"
                        />
                        <label htmlFor="porcentaje">Dia</label>
                    </span>
                </div>
                <div className="input-container" style={{ width: "150px" }}>
                    <span className="p-float-label">
                        <Dropdown
                            value={tipohora}
                            onChange={(e) => setTipohora(e.value)}
                            options={tipohoras}
                            optionLabel="data"
                            className="w-full md:w-14rem"
                        />
                        <label htmlFor="porcentaje">Horario</label>
                    </span>
                </div>
                <div className="input-container small">
                    <Button
                        disabled={
                            (!fechaHE || !cantidad || !tipodia || !tipohora) && true
                        }
                        onClick={(e) => handleClick(e)}
                        type="submit"
                        label="Agregar"
                        className="short-button"
                    />
                </div>
            </div>
            <TableRecursosHSList
                arrayHorasExtras={arrayHorasExtras}
                setArrayHorasExtras={setArrayHorasExtras}
                fechasSeleccionadas={fechasSeleccionadas}
                setFechasSeleccionadas={setFechasSeleccionadas}
            />
        </>
    );
}

export default FieldHorasExtrasNP