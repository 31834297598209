function fechaHora(date = new Date()) {
  let fecha =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  let hora =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);
  return { fecha, hora };
}

const formatoHoraAMPM = (hora) => {
  let horaMinutos = hora.slice(0, 5);

  let [hours, minutes] = horaMinutos.split(":");
  hours = parseInt(hours);
  const ampm = hours >= 12 ? "pm" : "am";

  // hours = hours % 12 || 12;
  return `${hours}:${minutes} ${ampm}`;
};

const IDBodyTemplate = (rowData) => {
  return (
    <>
      {rowData.IdSecuencial} - {rowData.Tipo}
    </>
  );
};

function fechaHoraPopup(date) {
  let fecha = date.split(".");
  let fechaYHora = fecha[0].slice(0, -3);
  let _fechaYHora = fechaYHora.split("T");
  let fechaHora = ` | ${_fechaYHora[0]} | ${_fechaYHora[1]}`;
  return { fechaHora };
}

const formatNumber = (number) => {
  const parts = number.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
};

const capitalizarPrimeraLetra = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
  fechaHora,
  IDBodyTemplate,
  fechaHoraPopup,
  formatNumber,
  capitalizarPrimeraLetra,
  formatoHoraAMPM,
};
