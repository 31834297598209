export const copyClipboard = (textToCopy) => {
  try {
    const clipboardContainer = document.createElement("div");
    clipboardContainer.style.opacity = "0";
    clipboardContainer.textContent = textToCopy;
    document.body.appendChild(clipboardContainer);

    const range = document.createRange();
    range.selectNode(clipboardContainer);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(clipboardContainer);
  } catch {
    console.error("No se pudo copiar al portapapeles");
  }
};
