import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ModalHistorial = ({
  setDisplayModal,
  displayModal,
  dataFreelance,
  freelanceHistorial,
  cambioNovedad,
}) => {
  const [historial, setHistorial] = useState();

  useEffect(() => {
    const test = freelanceHistorial.filter(
      (e) => e.Freelance.idFreelance === dataFreelance?.IdFreelance
    );
    setHistorial(test);
  }, [displayModal, cambioNovedad]);

  const BodyTest = (props) => {
    const obj = props.obj;
    return (
      <div>
        {Object.keys(obj).map(
          (key) =>
            key !== "idFreelance" && (
              <div key={key}>
                <strong>{key}:</strong>{" "}
                {Array.isArray(obj[key])
                  ? obj[key].map((ceco, index) => (
                      <div key={index}>
                        {Object.keys(ceco).map((cecoKey) => (
                          <div key={cecoKey}>
                            <strong>{cecoKey}:</strong> {ceco[cecoKey]}
                          </div>
                        ))}
                      </div>
                    ))
                  : obj[key]}
              </div>
            )
        )}
      </div>
    );
  };

  return (
    <Dialog
      draggable={false}
      header={`Historial freelance # ${dataFreelance.NroFreelance}`}
      visible={displayModal}
      onHide={() => setDisplayModal(false)}
    >
      {historial?.length ? (
        <Accordion multiple>
          {historial?.map((e, i) => (
            <AccordionTab
              key={i}
              header={`${
                e.Tipo === "insert" ? "Alta" : "Modificación"
              } - Fecha: ${e.FechaHora.split("T")[0]} - Usuario: ${
                e.UsuarioEmail
              }`}
            >
              <BodyTest obj={e.Freelance} />
            </AccordionTab>
          ))}
          <div className="final-submit-modal">
            <Button onClick={() => setDisplayModal(false)}>Aceptar</Button>
          </div>
        </Accordion>
      ) : (
        <h4>No se encontró historial.</h4>
      )}
    </Dialog>
  );
};

export default ModalHistorial;
