import React, { useRef } from "react";

import { Toast } from "primereact/toast";

import Menu from "../components/Menu";
import "../styles/pages/novedades.css";
import logoitp2 from "../imagenes/logo.png";

const Configuracion = () => {
  const toast = useRef(null);

  return (
    <div className="container">
      <Toast ref={toast} />
      <Menu />

      <div className="encabezado">
        <h3 className="titulo">Portal de Novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp2} alt="" />
      </div>
      <div className="titulo_novedades">
        <h3 className="">Configuración</h3>
      </div>
    </div>
  );
};

export default Configuracion;
