import { Button } from "primereact/button";
import CustomStep from "../CustomStep";
import { useNavigate } from "react-router-dom";
import TablaSeleccionTalento from "../TablaSeleccionTalento/TablaSeleccionTalento";

const SeleccionDeTalento = () => {

    const navigate = useNavigate();

    return (
        <>
            <div style={{ padding: '0px 10px'}}>
                <div style={{ marginLeft: "15px" }}>
                    <Button
                        label="Volver atrás"
                        icon="pi pi-angle-left"
                        className="button-volver"
                        onClick={() => navigate('/cotizador/datos-proyecto')}
                    />
                </div>
                <div style={{ margin: "10px 0px 30px 25px"}}>
                    <h1 style={{ fontSize: "24px" }}>Cotizador - Selección de Talento</h1>
                </div>
                <div style={{ padding: "0px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "6px",
                        }}
                    >
                        <CustomStep activeIndex={1} />
                    </div>
                    <TablaSeleccionTalento />
                </div>
            </div>

        </>
    );
};

export default SeleccionDeTalento;
