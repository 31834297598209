import { useState, useRef, useContext, useEffect } from "react";
import Menu from "../../components/Menu";
import logoitp from "../../imagenes/logo.png";

import SpeedDialCustom from "../../components/SpeedDialCustom";
import { Toast } from "primereact/toast";
import TableFreelance from "./TableFreelance";
import FormFreelance from "./FormFreelance";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { AuthContext } from "../../contexts/AuthContext";
import { Button } from "primereact/button";
import { fechaHora } from "../../helpers";

const PanelFreelance = () => {
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [freelanceFiltrados, setFreelanceFiltrados] = useState([]);

  const {
    getFreelance,
    cambioNovedad,
    loading,
    loadingExcel,
    getExcelFreelance,
  } = useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const toast = useRef(null);

  useEffect(() => {
    const handleGetFreelance = async () => {
      const data = await getFreelance();
      setFreelanceFiltrados(
        usuario.Rol === "board" || usuario.Rol === "po"
          ? data
          : data.filter((e) => e.Vertical === usuario.Rol)
      );
    };
    handleGetFreelance();
  }, [cambioNovedad]);

  const handleClick = async () => {
    const blob = await getExcelFreelance();
    if (blob) {
      const urlArchivo = URL.createObjectURL(blob);
      const linkDescarga = document.createElement("a");
      linkDescarga.href = urlArchivo;
      linkDescarga.download =
        "Informe freelance" +
        " _ " +
        fechaHora().fecha +
        " _ " +
        fechaHora().hora;
      linkDescarga.click();
      URL.revokeObjectURL(urlArchivo);
      //  strParam = "";
      //xlsDialogClose();
    }
  };

  const dialogFuncMap = {
    FormFreelance: setDisplayUserModal,
  };

  const speedDialClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades">
        <div>
          <h3 className="">Panel Freelance</h3>
          {usuario.Rol !== "po" && (
            <SpeedDialCustom speedDialClick={speedDialClick} tipoNovedad={4} />
          )}
        </div>
        <div className="boton-informe-freelance">
          <Button
            disabled={loadingExcel}
            className="p-button1"
            label="Descargar Excel"
            onClick={handleClick}
            style={{ padding: "3px", maxHeight: "50px" }}
            icon={
              loadingExcel ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "1rem" }}
                ></i>
              ) : (
                "pi pi-file-excel"
              )
            }
          />
        </div>
      </div>
      <TableFreelance data={freelanceFiltrados} loading={loading} />
      <FormFreelance
        registroId={0}
        setDisplayModal={setDisplayUserModal}
        displayModal={displayUserModal}
      />
    </div>
  );
};

export default PanelFreelance;
