import React from "react";
import "../styles/pages/notfound.css";
import logoitp from "../imagenes/logo.png";
import { NavLink } from "react-router-dom";


const NotFound = () => {
  return (
    <div className="notfound">
      <img src={logoitp} alt="logo it-patagonia" width={280} height={69}/>
      <div className="font">Error 404: Not found</div>
      <NavLink to="/miportal" >
     <div> <button 
     id="boton" 
     className="pi pi-home"></button></div>
    </NavLink>
    <div className="inicio">Volver a inicio</div>
  </div>
  );
};

export default NotFound;
