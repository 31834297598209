import { Toast } from "primereact/toast";
import { createContext, useContext, useState, useRef } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import ModalGuardarCotizacion from "../pages/Cotizador/ResumenDeCotizacion/ModalGuardarCotizacion";
import { fechaHora, formatoHoraAMPM } from "../helpers";

export const CotizadorContext = createContext();
const CotizadorContextProvider = ({ children }) => {
  const [tipoProyecto, setTipoProyecto] = useState([]);
  const [duracion, setDuracion] = useState([]);
  const [impuestos, setImpuestos] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [datosProyectos, setDatosProyectos] = useState([]);
  const [tarifario, setTarifario] = useState([]);
  const [selectedTipoProyecto, setSelectedTipoProyecto] = useState(null);
  const [beneficios, setBeneficios] = useState([]);
  const [vacaciones, setVacaciones] = useState([]);
  const [bases, setBases] = useState([]);
  const [roles, setRoles] = useState([]);
  const [horasDelProyecto, setHorasDelProyecto] = useState([]);
  const [duracionDelProyecto, setDuracionDelProyecto] = useState([]);
  const [montosCostosFijos, setMontosCostosFijos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [bandaSalarial, setBandaSalarial] = useState([]);
  const [dataTablaTalento, setDataTablaTalento] = useState([]);
  const [dataCalculoTalento, setDataCalculoTalento] = useState([]);
  const [facturaAEmitir, setFacturaAEmitir] = useState();
  const [totalOtrosCostos, setTotalOtrosCostos] = useState(0);
  const [version, setVersion] = useState("");
  const [versionFecha, setVersionFecha] = useState("");
  const [variables, setVariables] = useState([]);
  const [cotizaciones, setCotizaciones] = useState({
    paginatedData: [],
    amount: 1,
  });
  const [usuarios, setUsuarios] = useState([]);
  const [clientesFiltros, setClientesFiltros] = useState([]);
  const [idCotizacion, setIdCotizacion] = useState(null);
  const [guardarCoti, setGuardarCoti] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rutaSeleccionada, setRutaSeleccionada] = useState(null);
  const [tipoMoneda, setTipoMoneda] = useState([]);

  const key = process.env.REACT_APP_TOKEN_KEY;
  const apiUrl = process.env.REACT_APP_URL_COTIZADOR;
  const navigate = useNavigate();

  const { setLoadGoogle, getTokenFromSessionStorage, setUsuario } =
    useContext(AuthContext);
  const toast = useRef(null);

  const getTipoProyectos = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/projecttypes", options);

      if (response.ok) {
        const data = await response.json();
        setTipoProyecto(data);
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const getFormaPago = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/payments", options);

      if (response.ok) {
        const data = await response.json();
        setFormasPago(data);
      }
    } catch (error) {}
  };

  const getImpuestos = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/impuestos", options);

      if (response.ok) {
        const data = await response.json();
        setImpuestos(data);
      }
    } catch (error) {}
  };

  const getDuracion = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/periods", options);

      if (response.ok) {
        const data = await response.json();
        setDuracion(data);
      }
    } catch (error) {}
  };

  const getExcelCotizacion = async (body) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(
        apiUrl + "/api/quotations/detalle-cotizacion-excel",
        {
          method: "POST",
          mode: "cors",
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(body),
        }
      );
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Excel descargado con éxito.",
          life: 5000,
        });
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);

        return null;
      }
    } catch (error) {
      console.error("Error al descargar los archivos", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar los archivos",
        life: 5000,
      });
      return null;
    }
  };

  const getPdfCotizacion = async (body) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(
        apiUrl + "/api/quotations/detalle-cotizacion-pdf",
        {
          method: "POST",
          mode: "cors",
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(body),
        }
      );
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "PDF descargado con éxito.",
          life: 5000,
        });
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);

        return null;
      }
    } catch (error) {
      console.error("Error al descargar los archivos", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al descargar los archivos",
        life: 5000,
      });
      return null;
    }
  };

  const getTarifario = async (nuemroTabla, page, keyword) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${apiUrl}/api/basesDetails/${nuemroTabla}?keyword=${keyword}&page=${page}`,
        options
      );
      if (response.ok) {
        const data = await response.json();
        setTarifario(data);
        setVersion(data.paginatedData[0]?.version);
        setVersionFecha(
          fechaHora(new Date(data.paginatedData[0]?.createdAt))
            .fecha.split("-")
            .reverse()
            .join("-")
            .replaceAll("-", "/") +
            " - " +
            formatoHoraAMPM(
              fechaHora(new Date(data.paginatedData[0]?.createdAt)).hora
            )
        );
      }
      if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {}
  };

  const getBeneficios = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/benefitsPackages", options);

      if (response.ok) {
        const data = await response.json();
        setBeneficios(data);
      }
    } catch (error) {}
  };

  const getVacaciones = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/vacationDays", options);

      if (response.ok) {
        const data = await response.json();
        setVacaciones(data);
      }
    } catch (error) {}
  };

  const getBases = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/bases/details", options);

      if (response.ok) {
        const data = await response.json();
        setBases(data);
      }
    } catch (error) {}
  };

  const getRoles = async (idBase) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + `/api/basesRoles/idBase/${idBase}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        setRoles(data);
      }
    } catch (error) {}
  };

  const getCostosFijos = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/fixedAmount", options);

      if (response.ok) {
        const data = await response.json();
        setMontosCostosFijos(data);
      }
    } catch (error) {}
  };

  const getCostosVariables = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/variables", options);

      if (response.ok) {
        const data = await response.json();
        setVariables(data);
        setVersion(data[0].version);
      }
    } catch (error) {}
  };

  const getClientes = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/clients", options);

      if (response.ok) {
        const data = await response.json();
        const clientesNoVacios = data.filter(
          (cliente) => cliente.trim() !== ""
        );
        setClientes(clientesNoVacios);
      }
    } catch (error) {}
  };

  const getUsuarios = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/users/cotizador", options);

      if (response.ok) {
        const data = await response.json();
        const usuariosConNombreCompleto = data
          .filter((user) => user.nombre && user.apellido)
          .map((user) => ({
            ...user,
            nombreCompleto: `${user.nombre} ${user.apellido}`,
          }));
        setUsuarios(usuariosConNombreCompleto);
      }
    } catch (error) {}
  };

  const getBandaSalarial = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/salaryBands", options);

      if (response.ok) {
        const data = await response.json();
        setBandaSalarial(data);
      }
    } catch (error) {}
  };

  const postCotizaciones = async (cotizacion) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cotizacion),
      };
      const response = await fetch(apiUrl + "/api/cotizaciones", options);

      if (response.status === 201) {
        const data = await response.json();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Cotización guardada con éxito",
          life: 5000,
        });
        navigate("/cotizador");
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al guardar la cotizacion",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getCotizaciones = async (page = 1, clients = [], users = []) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const clientParam = clients ? `clients=${clients.join(",")}` : "";
      const userParam = users ? `users=${users.join(",")}` : "";
      const queryParams = [clientParam, userParam]
        .filter((param) => param)
        .join("&");
      const url = `${apiUrl}/api/cotizaciones?${queryParams}&page=${page}`;

      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        setCotizaciones(data);
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
    } catch (error) {
      console.error("Error fetching tarifario:", error);
    }
  };

  const deleteCotizacion = async (id) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + `/api/cotizaciones/${id}`, options);
      if (response.status === 200) {
        getCotizaciones();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Cotización eliminada con éxito",
          life: 5000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al eliminar la cotizacion",
          life: 5000,
        });
      }
    } catch (error) {}
  };

  const getClientesFiltros = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        apiUrl + "/api/clients/new-and-cecos-clients",
        options
      );

      if (response.ok) {
        const data = await response.json();
        const clientesNoVacios = data.filter(
          (cliente) => cliente.trim() !== ""
        );
        setClientesFiltros(clientesNoVacios);
      }
    } catch (error) {}
  };

  const putCotizaciones = async (cotizacion) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cotizacion),
      };
      const response = await fetch(
        apiUrl + "/api/cotizaciones/" + idCotizacion,
        options
      );

      if (response.status === 200) {
        const data = await response.json();
        toast.current.show({
          severity: "success",
          summary: "Confirmación",
          detail: "Cotización actualizada con éxito",
          life: 5000,
        });
        navigate("/cotizador");
        return data;
      } else if (response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Expiró la sesión, vuelva a ingresar",
          life: 5000,
        });
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error al actualizar la cotizacion",
          life: 5000,
        });
        return [];
      }
    } catch (error) {}
  };

  const getTipoMoneda = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(apiUrl + "/api/currencyRates", options);

      if (response.ok) {
        const data = await response.json();
        setTipoMoneda(data);
      }
    } catch (error) {}
  };

  return (
    <CotizadorContext.Provider
      value={{
        getTipoProyectos,
        tipoProyecto,
        getFormaPago,
        formasPago,
        impuestos,
        getImpuestos,
        getDuracion,
        duracion,
        getExcelCotizacion,
        datosProyectos,
        setDatosProyectos,
        getPdfCotizacion,
        getTarifario,
        tarifario,
        selectedTipoProyecto,
        setSelectedTipoProyecto,
        beneficios,
        getBeneficios,
        vacaciones,
        getVacaciones,
        bases,
        getBases,
        roles,
        getRoles,
        horasDelProyecto,
        setHorasDelProyecto,
        duracionDelProyecto,
        setDuracionDelProyecto,
        montosCostosFijos,
        getCostosFijos,
        clientes,
        getClientes,
        getBandaSalarial,
        bandaSalarial,
        dataTablaTalento,
        setDataTablaTalento,
        dataCalculoTalento,
        setDataCalculoTalento,
        facturaAEmitir,
        setFacturaAEmitir,
        totalOtrosCostos,
        setTotalOtrosCostos,
        version,
        versionFecha,
        variables,
        getCostosVariables,
        cotizaciones,
        usuarios,
        getUsuarios,
        postCotizaciones,
        getCotizaciones,
        deleteCotizacion,
        getClientesFiltros,
        clientesFiltros,
        putCotizaciones,
        idCotizacion,
        setIdCotizacion,
        guardarCoti,
        setGuardarCoti,
        isOpenModal,
        setIsOpenModal,
        rutaSeleccionada,
        setRutaSeleccionada,
        getTipoMoneda,
        tipoMoneda,
      }}
    >
      {children}
      <Toast ref={toast} />
      {isOpenModal && <ModalGuardarCotizacion />}
    </CotizadorContext.Provider>
  );
};

export default CotizadorContextProvider;
