import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "./TableRecursosList.css";

const TableRecursosHSList = ({
  arrayHorasExtras,
  setArrayHorasExtras,
  setFechasSeleccionadas,
  fechasSeleccionadas,
}) => {

  const deleteItem = (rowData) => {
    const array = rowData.Periodo.split("-");
    const year = parseInt(array[0]);
    const month = parseInt(array[1]) - 1;
    const day = parseInt(array[2]);
    const fechaRecTable = new Date(year, month, day);
    setFechasSeleccionadas(fechasSeleccionadas.filter((e) => e.getTime() !== fechaRecTable.getTime()))
    // setFechasSeleccionadas({
    //   ...fechasSeleccionadas,
    //   [rowData.id]: fechasSeleccionadas[rowData.id].filter(
    //     (e) => e.getTime() !== fechaRecTable.getTime()
    //   ),
    // });
    setArrayHorasExtras(arrayHorasExtras.filter((e) => e.valorBorrar !== rowData.valorBorrar));
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-rounded p-button-text button-icon"
        onClick={() => deleteItem(rowData)}
        aria-label="Submit"
      />
    );
  };

  const bodyTiposHE = (rowData, prop) => {
    return rowData.HorasExtras[0][prop]
  }

  return (
    arrayHorasExtras && (
      <div className="container-tableRecursosList">
        <DataTable
          emptyMessage="No hay registros"
          value={arrayHorasExtras}
          responsiveLayout="scroll"
          style={{ fontFamily: "var(--main-font)" }}
        >
          <Column field="Periodo" header="Fecha"></Column>
          <Column body={(e) => bodyTiposHE(e, "Cantidad")} field="HorasExtras" header="Cantidad "></Column>
          <Column body={(e) => bodyTiposHE(e, "TipoDia")} field="HorasExtras" header="Dia"></Column>
          <Column body={(e) => bodyTiposHE(e, "TipoHora")} field="HorasExtras" header="Horario"></Column>
          <Column
            headerStyle={{ width: "4rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    )
  );
};

export default TableRecursosHSList;

