import React, { useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { AuthContext } from "../../../contexts/AuthContext";
import { roleOptions } from "../../../types/rolesUsuario";

const FormNuevoUsuarioRol = ({
  setDisplayPosition,
  displayPosition,
  position,
}) => {
  const { getUser, updateUserRol, insertUser } = useContext(AuthContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const esAdministradorOptions = [
    { label: "Es administrador", data: true },
    { label: "No es administrador", data: false },
  ];

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    handleClickOnHide(name);
    if (name === "displayPosition") {
      formik.resetForm();
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      rol: "",
      email: "",
      esAdministrador: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.rol) {
        errors.rol = "Campo requerido.";
      }
      if (!data.email || !emailRegex.test(data.email)) {
        errors.email = "Debe ingresar un email válido.";
      }
      if (!data.esAdministrador) {
        errors.esAdministrador = "Campo requerido.";
      }
      return errors;
    },

    onSubmit: () => {
      const insertar = async () => {
        const ret = await getUser(formik.values.email);
        console.log(ret);
        if (ret.ok) {
          await updateUserRol({
            Email: formik.values.email,
            Rol: formik.values.rol,
            EsAdministrador: formik.values.esAdministrador.data,
          });
        } else {
          await insertUser({
            Email: formik.values.email,
            Rol: formik.values.rol,
            EsAdministrador: formik.values.esAdministrador.data,
          });
        }
      };
      insertar();
      formik.resetForm();
      onHide("displayPosition");
    },
  });

  return (
    <Dialog
      draggable={false}
      header="Nuevo usuario rol"
      blockScroll={true}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      {/* <Toast ref={toast} /> */}
      <div className="flex justify-content-center form-nuevo-cc">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="email"
                  value={formik.values.email}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  Email*
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>
          </div>
          <div className="row-container">
            <div className="input-container ">
              <span className="p-float-label">
                <Dropdown
                  id="rol"
                  name="rol"
                  value={formik.values.rol}
                  onChange={formik.handleChange}
                  options={roleOptions}
                  className={classNames({
                    "p-invalid": isFormFieldValid("rol"),
                  })}
                />
                <label
                  htmlFor="rol"
                  className={classNames({
                    "p-error": isFormFieldValid("rol"),
                  })}
                >
                  Rol*
                </label>
              </span>
              {getFormErrorMessage("rol")}
            </div>
            <div className="input-container ">
              <span className="p-float-label">
                <Dropdown
                  id="esAdministrador"
                  name="esAdministrador"
                  value={formik.values.esAdministrador}
                  onChange={formik.handleChange}
                  options={esAdministradorOptions}
                  className={classNames({
                    "p-invalid": isFormFieldValid("esAdministrador"),
                  })}
                />
                <label
                  htmlFor="esAdministrador"
                  className={classNames({
                    "p-error": isFormFieldValid("esAdministrador"),
                  })}
                >
                  Administrador*
                </label>
              </span>
              {getFormErrorMessage("esAdministrador")}
            </div>
          </div>

          <div className="final-submit">
            <Button type="submit" label="Guardar" />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormNuevoUsuarioRol;
