import React from 'react'
import Menu from "../../../components/Menu"
import logoitp from "../../../imagenes/logo.png";
import SeleccionDeTalento from './SeleccionDeTalento';

const SeleccionDeTalentoIndex = () => {
    
    return (
        <div className="container" style={{position:'relative'}}>
            <Menu />
            <div className="encabezado">
                <h3 className="titulo">Portal de novedades</h3>{" "}
                <img className="imagen_encabezado" src={logoitp} alt="" />
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0.5rem 0 0.2rem 0",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >

                </div>
            </div>
            <SeleccionDeTalento />
        </div>
    )
}

export default SeleccionDeTalentoIndex;