import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { nomina } from "./types";
import { roleOptions } from "../../types/rolesUsuario";
import FieldCecos from "../../components/FieldCecos";
import { InputTextarea } from "primereact/inputtextarea";

import "./freelance.css";
import { NovedadesContext } from "../../contexts/NovedadesContext";

const FormFreelance = ({
  setDisplayModal,
  displayModal,
  position = "right",
  datosRegistro = null,
  verSoloLectura = false,
}) => {
  const [facturaDesde, setFacturaDesde] = useState(undefined);
  const [facturaADDesde, setFacturaAdDesde] = useState(undefined);
  const [b21Desde, setB21Desde] = useState(undefined);
  const [fechaBaja, setFechaBaja] = useState(undefined);
  const [fechaIngreso, setFechaIngreso] = useState(undefined);
  const [fechaNac, setFechaNac] = useState(undefined);
  const [arrayCecos, setArrayCecos] = useState([]);
  const [porcentajeTotal, setPorcentajeTotal] = useState();
  const [dataGerente, setDataGerente] = useState("");
  const { insertFreelance, updateFreelance, recursosArray } =
    useContext(NovedadesContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const toast = useRef(null);

  const genero = [
    { label: "F", value: "f" },
    { label: "M", value: "m" },
    { label: "X", value: "x" },
  ];

  useEffect(() => {
    const legajoGerente = recursosArray.filter(
      (x) => x.Legajo === datosRegistro?.Gerente
    );
    setDataGerente(legajoGerente[0]?.datoGerente);
    let transformer = datosRegistro?.Cecos?.map((data, index) => {
      return {
        id: index,
        codigo: data.CcNum,
        cliente: data.CcNombre,
        porcentaje: data.CcPorcentaje,
      };
    });
    setArrayCecos(transformer || []);
  }, [displayModal]);

  useEffect(() => {
    if (datosRegistro) {
      setFacturaDesde(
        datosRegistro?.FacturaDesde !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.FacturaDesde)
          : undefined
      );

      setFechaIngreso(
        datosRegistro?.FechaIngreso !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.FechaIngreso)
          : undefined
      );

      setFechaNac(
        datosRegistro?.FechaNacimiento !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.FechaNacimiento)
          : undefined
      );

      setFacturaAdDesde(
        datosRegistro?.FacturaADDesde !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.FacturaADDesde)
          : undefined
      );
      setB21Desde(
        datosRegistro?.B21Desde !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.B21Desde)
          : undefined
      );
      setFechaBaja(
        datosRegistro?.FechaBaja !== "0001-01-01T00:00:00Z"
          ? new Date(datosRegistro?.FechaBaja)
          : undefined
      );
    }
  }, [displayModal]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const calcularPorcentaje = () => {
    let total = null;
    for (let i = 0; i < arrayCecos?.length; i++) {
      total += arrayCecos[i].porcentaje;
    }
    setPorcentajeTotal(total);
  };

  const errorPorcentaje = (valueCecosPorcentaje) => {
    if (valueCecosPorcentaje > 100) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "La suma de porcentajes de proyectos debe ser igual a 100",
        life: 5000,
      });
    }
  };

  const onHide = (name) => {
    setDisplayModal(false);
    handleClickOnHide(name);
    if (name === "displayModal") {
      formik.resetForm();
      setArrayCecos([]);
      setB21Desde();
      setFacturaAdDesde();
      setFacturaDesde();
      setFechaBaja();
      setFechaIngreso();
      setFechaNac();
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayModal") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayModal"),
      reject: () => setDisplayModal(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayModal(true);
        } else {
          onHide("displayModal");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      nroFreelance: datosRegistro?.NroFreelance || null,
      cuit: datosRegistro?.CUIT || null,
      apellido: datosRegistro?.Apellido || "",
      nombre: datosRegistro?.Nombre || "",
      nomina: datosRegistro?.Nomina || "",
      gerente:
        (verSoloLectura && dataGerente) ||
        (datosRegistro?.Gerente && !verSoloLectura && datosRegistro?.Gerente) ||
        null,
      vertical: datosRegistro?.Vertical || "",
      horasMen: datosRegistro?.HorasMen || null,
      cargo: datosRegistro?.Cargo || "",
      facturaMonto: datosRegistro?.FacturaMonto || null,
      facturaADCuit: datosRegistro?.FacturaADCuit || null,
      facturaADMonto: datosRegistro?.FacturaADMonto || null,
      b21Monto: datosRegistro?.B21Monto || null,
      comentario: datosRegistro?.Comentario || "",
      habilitado: datosRegistro?.Habilitado === "s" ? true : false || "",
      telefono: datosRegistro?.Telefono || "",
      mailLaboral: datosRegistro?.EmailLaboral || "",
      mailPersonal: datosRegistro?.EmailPersonal || "",
      genero: datosRegistro?.Genero || "",
      nacionalidad: datosRegistro?.Nacionalidad || "",
      domCalle: datosRegistro?.DomCalle || "",
      domNro: datosRegistro?.DomNumero || "",
      domPiso: datosRegistro?.DomPiso || "",
      domDepto: datosRegistro?.DomDepto || "",
      domLoc: datosRegistro?.DomLocalidad || "",
      domProv: datosRegistro?.DomProvincia || "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.nroFreelance) {
        errors.nroFreelance = "Campo requerido.";
      }
      if (!data.cuit) {
        errors.cuit = "Campo requerido.";
      }
      if (!data.apellido) {
        errors.apellido = "Campo requerido.";
      }
      if (!data.nombre) {
        errors.nombre = "Campo requerido.";
      }
      if (!data.nomina) {
        errors.nomina = "Campo requerido.";
      }
      if (!data.gerente) {
        errors.gerente = "Campo requerido.";
      }
      if (!data.vertical) {
        errors.vertical = "Campo requerido.";
      }
      if (data.mailLaboral && !emailRegex.test(data.mailLaboral)) {
        errors.mailLaboral = "Debe ingresar un email válido.";
      }
      if (data.mailPersonal && !emailRegex.test(data.mailPersonal)) {
        errors.mailPersonal = "Debe ingresar un email válido.";
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: () => {
      if (fechaIngreso === undefined) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una fecha de ingreso.",
          life: 5000,
        });
      } else {
        formik.resetForm();
        onHide("displayModal");
        let distArray = [];

        arrayCecos.forEach((e) => {
          distArray.push({
            CcPorcentaje: e.porcentaje,
            CcNombre: e.cliente,
            CcNum: e.codigo,
          });
        });

        datosRegistro === null
          ? insertFreelance({
              nroFreelance: formik.values.nroFreelance,
              cuit: formik.values.cuit,
              apellido: formik.values.apellido,
              nombre: formik.values.nombre,
              fechaIngreso: fechaIngreso,
              nomina: formik.values.nomina,
              gerente: formik.values.gerente,
              vertical: formik.values.vertical,
              horasMen: formik.values.horasMen,
              cargo: formik.values.cargo,
              facturaMonto: formik.values.facturaMonto,
              facturaDesde: facturaDesde,
              facturaADCuit: formik.values.facturaADCuit,
              facturaADMonto: formik.values.facturaADMonto,
              facturaADDesde: facturaADDesde,
              b21Monto: formik.values.b21Monto,
              b21Desde: b21Desde,
              comentario: formik.values.comentario,
              habilitado: formik.values.habilitado ? "s" : "n",
              fechaBaja: fechaBaja,
              cecos: distArray,
              telefono: formik.values.telefono,
              emailLaboral: formik.values.mailLaboral,
              emailPersonal: formik.values.mailPersonal,
              fechaNacimiento: fechaNac,
              genero: formik.values.genero,
              nacionalidad: formik.values.nacionalidad,
              domCalle: formik.values.domCalle,
              domNumero: formik.values.domNro,
              domPiso: formik.values.domPiso,
              domDepto: formik.values.domDepto,
              domLocalidad: formik.values.domLoc,
              domProvincia: formik.values.domProv,
            })
          : updateFreelance({
              idFreelance: datosRegistro?.IdFreelance,
              nroFreelance: formik.values.nroFreelance,
              cuit: formik.values.cuit,
              apellido: formik.values.apellido,
              nombre: formik.values.nombre,
              fechaIngreso: fechaIngreso,
              nomina: formik.values.nomina,
              gerente: formik.values.gerente,
              vertical: formik.values.vertical,
              horasMen: formik.values.horasMen,
              cargo: formik.values.cargo,
              facturaMonto: formik.values.facturaMonto,
              facturaDesde: facturaDesde,
              facturaADCuit: formik.values.facturaADCuit,
              facturaADMonto: formik.values.facturaADMonto,
              facturaADDesde: facturaADDesde,
              b21Monto: formik.values.b21Monto,
              b21Desde: b21Desde,
              comentario: formik.values.comentario,
              habilitado: formik.values.habilitado ? "s" : "n",
              fechaBaja: fechaBaja,
              cecos: distArray,
              telefono: formik.values.telefono,
              emailLaboral: formik.values.mailLaboral,
              emailPersonal: formik.values.mailPersonal,
              fechaNacimiento: fechaNac,
              genero: formik.values.genero,
              nacionalidad: formik.values.nacionalidad,
              domCalle: formik.values.domCalle,
              domNumero: formik.values.domNro,
              domPiso: formik.values.domPiso,
              domDepto: formik.values.domDepto,
              domLocalidad: formik.values.domLoc,
              domProvincia: formik.values.domProv,
            });

        setB21Desde();
        setFacturaAdDesde();
        setFacturaDesde();
        setFechaBaja();
        setFechaIngreso();
        setFechaNac();
      }
    },
  });

  return (
    <Dialog
      draggable={false}
      header={
        datosRegistro
          ? (verSoloLectura ? "Ver " : "Edición ") +
            "freelance #" +
            datosRegistro.NroFreelance
          : "Nuevo freelance"
      }
      blockScroll={true}
      visible={displayModal}
      position={position}
      modal
      className="dialog-freelance"
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => {
        verSoloLectura ? onHide("displayModal") : onHide("onHide");
      }}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-nuevo-cc">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="nroFreelance"
                  value={formik.values.nroFreelance}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("nroFreelance"),
                  })}
                  disabled={verSoloLectura || datosRegistro !== null}
                />
                <label
                  htmlFor="nroFreelance"
                  className={classNames({
                    "p-error": isFormFieldValid("nroFreelance"),
                  })}
                >
                  Nro Freelance*
                </label>
              </span>
              {getFormErrorMessage("nroFreelance")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputMask
                  id="cuit"
                  value={formik.values.cuit}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  readOnly={verSoloLectura}
                  mask="99-99999999-9"
                  placeholder="99-99999999-9"
                  className={classNames({
                    "p-invalid": isFormFieldValid("cuit"),
                  })}
                  disabled={verSoloLectura || datosRegistro !== null}
                />
                <label
                  htmlFor="cuit"
                  className={classNames({
                    "p-error": isFormFieldValid("cuit"),
                  })}
                >
                  CUIT*
                </label>
              </span>
              {getFormErrorMessage("cuit")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="apellido"
                  value={formik.values.apellido}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("apellido"),
                  })}
                  disabled={verSoloLectura || datosRegistro !== null}
                />
                <label
                  htmlFor="apellido"
                  className={classNames({
                    "p-error": isFormFieldValid("apellido"),
                  })}
                >
                  Apellido*
                </label>
              </span>
              {getFormErrorMessage("apellido")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="nombre"
                  value={formik.values.nombre}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("nombre"),
                  })}
                  disabled={verSoloLectura || datosRegistro !== null}
                />
                <label
                  htmlFor="nombre"
                  className={classNames({
                    "p-error": isFormFieldValid("nombre"),
                  })}
                >
                  Nombre*
                </label>
              </span>
              {getFormErrorMessage("nombre")}
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <Calendar
                  id="fechaIngreso"
                  locale="es"
                  showIcon
                  value={fechaIngreso}
                  onChange={(e) => setFechaIngreso(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                  disabled={verSoloLectura || datosRegistro !== null}
                />
                <label htmlFor="fechaIngreso">Fecha Ingreso*</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <Dropdown
                  className={classNames({
                    "p-invalid": isFormFieldValid("nomina"),
                  })}
                  id="nomina"
                  name="nomina"
                  value={formik.values.nomina}
                  onChange={formik.handleChange}
                  options={nomina}
                  optionLabel="value"
                  optionValue="id"
                  placeholder="Seleccione una opción"
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="nomina"
                  className={classNames({
                    "p-error": isFormFieldValid("nomina"),
                  })}
                >
                  Nómina*
                </label>
              </span>
              {getFormErrorMessage("nomina")}
            </div>{" "}
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="horasMen"
                  value={formik.values.horasMen}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="horasMen">Horas Mensuales</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            {" "}
            <div className="input-container medium">
              <span className="p-float-label">
                {verSoloLectura ? (
                  <InputText
                    id="gerente"
                    value={formik.values.gerente}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    readOnly={verSoloLectura}
                    className={classNames({
                      "p-invalid": isFormFieldValid("gerente"),
                    })}
                    disabled={verSoloLectura || datosRegistro !== null}
                  />
                ) : (
                  <InputNumber
                    id="gerente"
                    value={formik.values.gerente}
                    autoComplete="off"
                    onValueChange={formik.handleChange}
                    readOnly={verSoloLectura}
                    className={classNames({
                      "p-invalid": isFormFieldValid("gerente"),
                    })}
                    disabled={verSoloLectura}
                  />
                )}
                <label
                  htmlFor="gerente"
                  className={classNames({
                    "p-error": isFormFieldValid("gerente"),
                  })}
                >
                  Gerente*
                </label>
              </span>
              {getFormErrorMessage("gerente")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <Dropdown
                  className={classNames({
                    "p-invalid": isFormFieldValid("vertical"),
                  })}
                  id="vertical"
                  name="vertical"
                  value={formik.values.vertical}
                  onChange={formik.handleChange}
                  options={roleOptions}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Seleccione una opción"
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="vertical"
                  className={classNames({
                    "p-error": isFormFieldValid("vertical"),
                  })}
                >
                  Vertical*
                </label>
              </span>
              {getFormErrorMessage("vertical")}
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="cargo"
                  value={formik.values.cargo}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="cargo">Cargo</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="facturaMonto"
                  value={formik.values.facturaMonto}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
                <label htmlFor="facturaMonto">Factura Monto</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <Calendar
                  id="facturaDesde"
                  locale="es"
                  showIcon
                  // value={new Date(formik.values.facturaDesde)}
                  value={facturaDesde}
                  disabled={verSoloLectura}
                  onChange={(e) => setFacturaDesde(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                />
                <label htmlFor="facturaDesde">Factura Desde</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputMask
                  id="facturaADCuit"
                  value={formik.values.facturaADCuit}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                  mask="99-99999999-9"
                  placeholder="99-99999999-9"
                />
                <label htmlFor="facturaADCuit">Factura AD Cuit</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="facturaADMonto"
                  value={formik.values.facturaADMonto}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
                <label htmlFor="facturaADMonto">Factura AD Monto</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <Calendar
                  id="facturaADDesde"
                  locale="es"
                  showIcon
                  // value={new Date(formik.values.facturaADDesde)}
                  value={facturaADDesde}
                  disabled={verSoloLectura}
                  onChange={(e) => setFacturaAdDesde(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                />
                <label htmlFor="facturaADDesde">Factura AD Desde</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="b21Monto"
                  value={formik.values.b21Monto}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
                <label htmlFor="b21Monto">B21 Monto</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <Calendar
                  id="b21Desde"
                  locale="es"
                  showIcon
                  value={b21Desde}
                  disabled={verSoloLectura}
                  onChange={(e) => setB21Desde(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                />
                <label htmlFor="b21Desde">B21 Desde</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  className="textarea-freelance"
                  id="comentario"
                  maxLength={512}
                  autoResize
                  value={formik.values.comentario}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  rows={5}
                  cols={30}
                  disabled={verSoloLectura}
                />
                <label htmlFor="comentario">Comentarios</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container switch">
              <span>Habilitado</span>
              <InputSwitch
                id="habilitado"
                checked={formik.values.habilitado}
                onChange={formik.handleChange}
                disabled={verSoloLectura}
              />
            </div>
            <div className="input-container">
              <span className="p-float-label">
                <Calendar
                  id="fechaBaja"
                  locale="es"
                  showIcon
                  value={fechaBaja}
                  disabled={verSoloLectura}
                  onChange={(e) => setFechaBaja(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                />
                <label htmlFor="fechaBaja">Fecha Baja</label>
              </span>
            </div>
          </div>
          <FieldCecos
            calcularPorcentaje={calcularPorcentaje}
            arrayCecos={arrayCecos}
            setArrayCecos={setArrayCecos}
            errorPorcentaje={errorPorcentaje}
            verSoloLectura={verSoloLectura}
            novedadTipo={datosRegistro !== null && "freelance"}
          />
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="telefono"
                  value={formik.values.telefono}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="telefono">Telefono</label>
              </span>
            </div>
          </div>

          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="mailLaboral"
                  value={formik.values.mailLaboral}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("mailLaboral"),
                  })}
                />
                <label
                  htmlFor="mailLaboral"
                  className={classNames({
                    "p-error": isFormFieldValid("mailLaboral"),
                  })}
                >
                  Email laboral
                </label>
                {getFormErrorMessage("mailLaboral")}
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="mailPersonal"
                  value={formik.values.mailPersonal}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("mailPersonal"),
                  })}
                />
                <label
                  htmlFor="mailPersonal"
                  className={classNames({
                    "p-error": isFormFieldValid("mailPersonal"),
                  })}
                >
                  Email personal
                </label>
                {getFormErrorMessage("mailPersonal")}
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <Calendar
                  id="fechaNac"
                  locale="es"
                  showIcon
                  value={fechaNac}
                  onChange={(e) => setFechaNac(e.value)}
                  dateFormat="dd/mm/yy"
                  view="date"
                  disabled={verSoloLectura}
                />
                <label htmlFor="fechaNac">Fecha de nacimiento</label>
              </span>
            </div>

            <div className="input-container small">
              <span className="p-float-label">
                <Dropdown
                  id="genero"
                  name="genero"
                  value={formik.values.genero}
                  onChange={formik.handleChange}
                  options={genero}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Seleccione una opción"
                  disabled={verSoloLectura}
                />
                <label>Genero</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="nacionalidad"
                  value={formik.values.nacionalidad}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="nacionalidad">Nacionalidad</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="domCalle"
                  value={formik.values.domCalle}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domCalle">Calle</label>
              </span>
            </div>
            <div className="input-container small">
              <span className="p-float-label">
                <InputNumber
                  id="domNro"
                  value={formik.values.domNro}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domNro">Nro.</label>
              </span>
            </div>
            <div className="input-container small">
              <span className="p-float-label">
                <InputNumber
                  id="domPiso"
                  value={formik.values.domPiso}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domPiso">Piso</label>
              </span>
            </div>
            <div className="input-container small">
              <span className="p-float-label">
                <InputText
                  id="domDepto"
                  value={formik.values.domDepto}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domDepto">Dpto.</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="domLoc"
                  value={formik.values.domLoc}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domLoc">Localidad</label>
              </span>
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="domProv"
                  value={formik.values.domProv}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  disabled={verSoloLectura}
                />
                <label htmlFor="domProv">Provincia</label>
              </span>
            </div>
          </div>

          <div className="div_boton-fixed">
            <div className="final-submit">
              <Button type="submit" label="Guardar" disabled={verSoloLectura} />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormFreelance;
