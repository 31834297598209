const validaCuit = (cuit) => {
  if (cuit.length === 11) {
    let digitos = cuit.slice(0, 10);
    let digitoVerificador = parseInt(cuit.charAt(10), 10);
    let acumulado = 0;
    let coeficiente = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < 10; i++) {
      acumulado += parseInt(digitos.charAt(i), 10) * coeficiente[i];
    }
    let resultado = (11 - (acumulado % 11)) % 11;
    if (resultado !== digitoVerificador) {
      return false;
    }
    return true;
  }
  return false;
};

export default validaCuit;
