import React, { useState, useContext, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import PickListCC from "./PickListCC";
import AutoCompleteCustom from "../AutoCompleteCustom";
import FieldRecursos from "../FieldRecursos";
import FileUploadControl from "../FileUploadControl";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import categorias from "../../data/categorias";
import {
  TYPES_NOVEDAD,
  tipoNovedadesAjustes,
  tipoNovedadesDescuentos,
  tipoNovedadesGratificaciones,
} from "../../data/tipoNovedades";
import { fechaHora } from "../../helpers";
import { validateTotUploadSize } from "../../helpers/validateTotUploadSize";
import "../../styles/formFacturacion.css";
import styles from "./formRecursosRetroactivo.css";

const FormRecursos = ({ setDisplayPosition, displayPosition, position }) => {
  const { username } = useContext(AuthContext);
  const {
    addNovedad,
    cecos,
    getRecursosSameManager,
    getRecursosFilteredManager,
    getRecursosFromCeco,
    recursosFromCeco,
  } = useContext(NovedadesContext);
  const [adjuntos, setAdjuntos] = useState([]);
  const [arrayNovedades, setArrayNovedades] = useState([]);
  const [recursos, setRecursos] = useState([]);
  const [comentarios, setComentarios] = useState("");
  const [porcentaje, setPorcentaje] = useState(null);
  const [ceco, setCeco] = useState();
  const [retroactivo, setRetroactivo] = useState(false);
  const [visibleRecursosFromCeco, setVisibleRecursosFromCeco] = useState("");
  const toast = useRef(null);

  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);
    if (name === "displayPosition") {
      formik.resetForm();
      setComentarios("");
      setPorcentaje(null);
      setCeco();
      setRetroactivo(false);
      setRecursos([]);
      setVisibleRecursosFromCeco("");
      setCCSource();
      setCCTarget();
      setAdjuntos([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  useEffect(() => {
    getRecursosSameManager();
    getRecursosFilteredManager();
  }, []);

  const bodyRecursosFromCeco = (cecoValue) => {
    getRecursosFromCeco(cecoValue?.Codigo);
    if (visibleRecursosFromCeco === "") {
      setVisibleRecursosFromCeco("visibleRecursos");
      return;
    }

    setVisibleRecursosFromCeco("notVisible");
    setTimeout(() => {
      setVisibleRecursosFromCeco("visibleRecursos");
    }, "600");
  };

  const formik = useFormik({
    initialValues: {
      categoria: "",
      tipoNovedad: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.categoria) {
        errors.categoria = "Campo requerido.";
      }
      if (!data.tipoNovedad) {
        errors.tipoNovedad = "Campo Requerido";
      }
      return errors;
    },

    onSubmit: () => {
      if (
        formik.values.tipoNovedad.type !== TYPES_NOVEDAD.AMDS &&
        recursos.length <= 0
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No hay recursos cargados",
          life: 5000,
        });
      } else if (
        formik.values.tipoNovedad.type === TYPES_NOVEDAD.AMDS &&
        (porcentaje <= 0 || ceco.length <= 0)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No hay un Centro de Costos o porcentaje seleccionados",
          life: 5000,
        });
      } else if (
        formik.values.tipoNovedad.type === TYPES_NOVEDAD.AMDS &&
        (ccTarget.length <= 0)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No se seleccionaron recursos",
          life: 5000,
        });
      } else {
        setRetroactivo(false);
        onHide("displayPosition");
        formik.resetForm();
        let array = [
          {
            Porcentaje: porcentaje,
            Cecos: {
              IdCecos: ceco?.IdCecos,
              Cliente: ceco?.Cliente,
              Proyecto: ceco?.Proyecto,
              Codigo: ceco?.Codigo,
              Descripcion: ceco?.Descripcion,
            },
          },
        ];              

        let recursosStr = ccTarget?.map(i=> {return  {
          Recurso: `${i.Apellido} ${i.Nombre} (${i.Legajo})`,
          Porc: array.Porcentaje,
        }});

        addNovedad(
          {
            Tipo: "RH",
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Usuario: username,
            Descripcion:
              formik.values.tipoNovedad.type === TYPES_NOVEDAD.AJSB
                ? retroactivo
                  ? "Actualizar sueldo básico retroactivo"
                  : formik.values.tipoNovedad.desc
                : formik.values.tipoNovedad.desc,
            RecursosStr:
              formik.values.tipoNovedad.type === TYPES_NOVEDAD.AMDS
                ? JSON.stringify(recursosStr)
                : JSON.stringify(recursos),
            DistribucionesStr:
              formik.values.tipoNovedad.type === TYPES_NOVEDAD.AMDS
                ? JSON.stringify(array)
                : [],
            Comentarios: comentarios,
          },
          adjuntos
        );
        setAdjuntos([]);
      }
    },
  });

  useEffect(() => {
    formik.values.tipoNovedad = "";
    switch (formik.values.categoria?.desc) {
      case "Ajustes":
        setArrayNovedades(tipoNovedadesAjustes);
        break;
      case "Descuentos":
        setArrayNovedades(tipoNovedadesDescuentos);
        break;
      case "Gratificaciones":
        setArrayNovedades(tipoNovedadesGratificaciones);
        break;
      default:
        setArrayNovedades([]);
        break;
    }
  }, [formik.values.categoria?.desc]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const [ccSource, setCCSource] = useState([]);
  const [ccTarget, setCCTarget] = useState([]);

  useEffect(() => {
    setCCSource(recursosFromCeco);
    setCCTarget([]);
  }, [recursosFromCeco]);

  return (
    <Dialog
      draggable={false}
      header={"Patagonian"}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-novedad">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <AutoCompleteCustom
                  id="categoria"
                  // arrayValues={arrayCategorias}
                  arrayValues={categorias}
                  field="desc"
                  dropdown
                  value={formik.values.categoria}
                  forceSelection
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("categoria"),
                  })}
                />
                <label
                  htmlFor="categoria"
                  className={classNames({
                    "p-error": isFormFieldValid("categoria"),
                  })}
                >
                  Categoria*
                </label>
              </span>
              {getFormErrorMessage("categoria")}
            </div>

            <div className="input-container medium">
              <>
                <span className="p-float-label">
                  <AutoCompleteCustom
                    id="tipoNovedad"
                    arrayValues={arrayNovedades}
                    field="desc"
                    dropdown
                    value={formik.values.tipoNovedad}
                    forceSelection
                    disabled={!formik.values.categoria}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("tipoNovedad"),
                    })}
                  />
                  <label
                    htmlFor="tipoNovedad"
                    className={classNames({
                      "p-error": isFormFieldValid("tipoNovedad"),
                    })}
                  >
                    Tipo de Novedad*
                  </label>
                </span>
                {getFormErrorMessage("tipoNovedad")}
              </>
            </div>
          </div>
          {formik.values.tipoNovedad && formik.values.categoria && (
            <>
              {formik.values.tipoNovedad.type !== TYPES_NOVEDAD.AMDS ? (
                <>
                  <FieldRecursos
                    recursos={recursos}
                    setRecursos={setRecursos}
                    tipoNovedad={formik.values.tipoNovedad.type}
                  />
                  <div className="row-container">
                    <div className="input-container coment">
                      <span className="p-float-label">
                        <InputTextarea
                          id="comentarios"
                          maxLength={500}
                          autoResize
                          value={comentarios}
                          autoComplete="off"
                          onChange={(e) => {
                            setComentarios(e.target.value);
                          }}
                        />
                        <label htmlFor="comentarios">Comentarios</label>
                      </span>
                    </div>
                    {formik.values.tipoNovedad.type === TYPES_NOVEDAD.AJSB && (
                      <div className="input-container">
                        <span className="p-float-label">
                          <label
                            htmlFor="retroactivo"
                            className={styles.labelRetroactivo}
                          >
                            Ajuste retroactivo
                          </label>
                          <InputSwitch
                            id="retroactivo"
                            name="retroactivo"
                            className={styles.inputSwRetroactivo}
                            checked={retroactivo}
                            onChange={(e) => {
                              setRetroactivo(!retroactivo);
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="row-container">
                    <div className="input-container  medium">
                      <span className="p-float-label">
                        <AutoCompleteCustom
                          id="ceco"
                          arrayValues={cecos}
                          field="Descripcion"
                          dropdown
                          value={ceco}
                          forceSelection
                          onChange={(e) => {
                            bodyRecursosFromCeco(e.target.value);
                            setCeco(e.target.value);
                          }}
                        />
                        <label htmlFor="ceco">Centro de Costos*</label>
                      </span>
                    </div>
                    <div className="input-container small">
                      <span className="p-float-label">
                        <InputNumber
                          id="porcentaje"
                          value={porcentaje}
                          autoComplete="off"
                          onValueChange={(e) => {
                            setPorcentaje(e.value);
                          }}
                        />
                        <label htmlFor="porcentaje">%</label>
                      </span>
                    </div>
                  </div>
                  {/* {ceco !== undefined && (
                    <div className={visibleRecursosFromCeco}>
                      <div className={"titleRecursosFromCeco"}>
                        <b>RECURSOS DEL CENTRO DE COSTOS</b>
                      </div>
                      <div style={{ padding: "2%" }}>
                        {recursosFromCeco?.map((e, i) => (
                          <div
                            key={i}
                            style={{ padding: "1%", fontSize: "13px" }}
                          >
                            {e.Apellido}, {e.Nombre}-
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}
                  {ceco !== undefined && (
                    <div style={{ marginTop: "10px" }}>
                      <PickListCC
                        source={ccSource}
                        setSource={setCCSource}
                        target={ccTarget}
                        setTarget={setCCTarget}
                      ></PickListCC>
                    </div>
                  )}
                  <div className="row-container">
                    <div className="input-container">
                      <span className="p-float-label">
                        <InputTextarea
                          id="comentarios"
                          maxLength={500}
                          autoResize
                          value={comentarios}
                          autoComplete="off"
                          onChange={(e) => setComentarios(e.target.value)}
                        />
                        <label htmlFor="comentarios">Comentarios</label>
                      </span>
                    </div>
                  </div>
                </>
              )}
              <FileUploadControl setAdjuntos={setAdjuntos} />
              <div className="final-submit">
                <Button type="submit" label="Guardar" className="mt-2" disabled={!validateTotUploadSize(adjuntos)} />
              </div>
            </>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default FormRecursos;
