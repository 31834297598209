const roleOptions = [
  { label: "Administración", value: "admin" },
  { label: "Servicios", value: "servicios" },
  { label: "People Operation", value: "po" },
  { label: "Talent Acquisition", value: "ta" },
  { label: "Cultura", value: "cultura" },
  { label: "Comercialización", value: "comercial" },
  { label: "Empleado", value: "empleado" },
  { label: "Marketing", value: "marketing" },
  { label: "Comunicaciones", value: "comunicaciones" },
  { label: "Comercialización soporte", value: "comercialSop" },
  { label: "Servicios soporte", value: "serviciosSop" },
  { label: "Sustentabilidad", value: "sustentabilidad" },
  { label: "Board", value: "board" },
  { label: "Codeki", value: "codeki" },
  {label: "TimeSheetReport", value: "timesheetReport"},
];

export { roleOptions };
