import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import "./Tarifario.css";
import { useContext, useState } from "react";
import BasesNominaInterna from "./TablasTarifario/BasesNominaInterna";
import BasesCostosMercado from "./TablasTarifario/BasesCostoMercado";
import BasesICBC from "./TablasTarifario/BasesICBC";
import { CotizadorContext } from "../../contexts/CotizadorContext";

const Tarifario = () => {
  const [selectedComponent, setSelectedComponent] = useState(
    "Bases Nómina Interna"
  );
  const { version } = useContext(CotizadorContext);
  const navigate = useNavigate();

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Bases Nómina Interna":
        return <BasesNominaInterna />;
      case "Bases Costos de mercado":
        return <BasesCostosMercado />;
      case "Bases ICBC":
        return <BasesICBC />;
      default:
        return null;
    }
  };
  return (
    <>
      <div
        style={{ marginLeft: "23px", display: "flex", alignItems: "center" }}
      >
        <Button
          label="Portal de novedades"
          icon="pi pi-angle-right"
          iconPos="right"
          className="button-volver"
          onClick={() => navigate("/novedades")}
        />
        <p className="p-tarifario">Tarifario</p>
      </div>
      <div
        style={{ marginLeft: "25px", marginTop: "10px", marginRight: "25px" }}
      >
        <h1 style={{ fontSize: "24px" }}>Tarifario</h1>
        <p className="version">Versión TAR {version}</p>
        <div style={{ marginTop: "20px" }}>
          <div style={{ display: "flex" }}>
            <button
              className={`buttons-tarifario ${
                selectedComponent === "Bases Nómina Interna" ? "selected" : ""
              }`}
              onClick={() => setSelectedComponent("Bases Nómina Interna")}
            >
              Bases Nómina Interna
            </button>
            <button
              className={`buttons-tarifario ${
                selectedComponent === "Bases Costos de mercado"
                  ? "selected"
                  : ""
              }`}
              onClick={() => setSelectedComponent("Bases Costos de mercado")}
            >
              Bases Costos de mercado
            </button>
            <button
              className={`buttons-tarifario ${
                selectedComponent === "Bases ICBC" ? "selected" : ""
              }`}
              onClick={() => setSelectedComponent("Bases ICBC")}
            >
              Bases ICBC
            </button>
          </div>
          <div>{renderComponent()}</div>
        </div>
      </div>
    </>
  );
};

export default Tarifario;
