import React, { useState, useContext } from "react";
import { Button } from "primereact/button";
import { AuthContext } from "../../../contexts/AuthContext";
import { reportesExcel } from "../services/Services";

const ReporteUsuario = ({ formatPeriodo, showToast }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { getTokenFromSessionStorage } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
  const key = process.env.REACT_APP_TOKEN_KEY;

  const handleClick = async () => {
    setIsFetching(true);
    const endpoint = `/api/users/exporta-detalle-horas-usuario/${formatPeriodo}`;
    const fullUrl = apiUrl + endpoint;
    const token = getTokenFromSessionStorage(key);
    const nombreArchivo = `Reporte_Usuario_${formatPeriodo}.xlsx`;
    await reportesExcel(fullUrl, token, nombreArchivo, showToast);
    setIsFetching(false);
  };
  return (
    <>
      <Button
        icon={"pi pi-download"}
        loading={isFetching}
        tooltip="Reporte propio"
        style={{ height: "30px", width: "30px" }}
        onClick={handleClick}
      />
    </>
  );
};

export default ReporteUsuario;
