import React, { useEffect } from "react";

const key = process.env.REACT_APP_GOOGLE_KEY;

const GoogleLoginButton = () => {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      });
    };

    Promise.all([
      loadScript("https://accounts.google.com/gsi/client"),
      loadScript("https://apis.google.com/js/platform.js"),
      loadScript("https://apis.google.com/js/api:client.js"),
    ]).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div>
      <div id="google-signin-button">
        <div
          id="g_id_onload"
          data-client_id={key}
          data-context="use"
          data-ux_mode="popup"
          data-callback="handleCredentialResponse"
          data-auto_prompt="false"
        ></div>

        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
        ></div>
      </div>
    </div>
  );
};

export default GoogleLoginButton;
