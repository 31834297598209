import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { CardHabilidades } from "./CardHabilidades";
import { ModalHabilidaes } from "./ModalHabilidaes";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";
import { ModalVerTodos } from "./ModalVerTodos";

const Habilidades = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [infoModal, setInfoModal] = useState("");
  const [isOpenModalVerTodos, setIsOpenModalVerTodos] = useState(false);
  const [datos, setDatos] = useState([]);
  const navigate = useNavigate();
  const {
    selectSkills,
    getSkills,
    postSkills,
    getSkillsUser,
    skillUser,
    editSkill,
    setEditSkill,
    deleteSkills,
    getTecnologias,
    selectTecnologias,
    selectSubTecnologias,
    getCertificaciones,
    certificaciones,
    postCertificaciones,
    getNivelEstudios,
    selectNivelEstudio,
    getNivelEstudioUser,
    nivelEstudioUser,
  } = useContext(HabilidadesContext);

  useEffect(() => {
    getSkills();
    getSkillsUser();
    getTecnologias();
    getCertificaciones();
    getNivelEstudios();
    getNivelEstudioUser()
  }, []);

  const handleGuardarSkills = (skills) => {
    const skillsIds = skills.map((skill) => ({ idSkill: skill._id }));
    postSkills(skillsIds);
    setIsOpenModal(false);
  };

  const handleGuardarcertificaciones = (certificate) => {
    postCertificaciones(certificate);
    setIsOpenModal(false);
  };

  const handleEliminar = (skill) => {
    deleteSkills(skill);
    setIsOpenModal(false);
    setEditSkill(false);
  };

  return (
    <>
      <div style={{ marginLeft: "20px" }}>
        <Button
          label="Volver atrás"
          icon="pi pi-angle-left"
          className="button-volver"
          onClick={() => {
            navigate("/novedades");
          }}
        />
      </div>
      <div style={{ marginLeft: "33px", marginTop: "10px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
          Mis habilidades
        </h1>
        <p>
          Acá vas a poder ingresar todos los datos relacionados a tu perfil
          profesional
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          marginLeft: "20px",
          marginTop: "20px",
        }}
      >
        <CardHabilidades
          icon={"psychology"}
          title={"Skills Complementarias"}
          description={"No tenés skills ingresadas"}
          info={
            "Acá vas a poder agregar Skills complementarias al rol que estás desempeñando"
          }
          cancel={setIsOpenModal}
          setInfoModal={setInfoModal}
          setDatos={setDatos}
          data={skillUser}
          setEditSkill={setEditSkill}
          open={setIsOpenModalVerTodos}
        />
        <CardHabilidades
          icon={"computer"}
          title={"Tecnologías"}
          description={"No tenés tecnologías ingresadas"}
          info={"Acá vas a poder agregar todas las tecnologías que manejás"}
          cancel={setIsOpenModal}
          setInfoModal={setInfoModal}
          setDatos={setDatos}
          data={[]}
          setEditSkill={setEditSkill}
          open={setIsOpenModalVerTodos}
        />
        <CardHabilidades
          icon={"workspace_premium"}
          title={"Certificaciones"}
          description={"No tenés certificaciones ingresadas"}
          info={"Acá vas a poder adjuntar todas tus certificaciones"}
          cancel={setIsOpenModal}
          setInfoModal={setInfoModal}
          setDatos={setDatos}
          data={certificaciones}
          setEditSkill={setEditSkill}
          open={setIsOpenModalVerTodos}
        />
        <CardHabilidades
          icon={"school"}
          title={"Nivel de estudios"}
          description={"No tenés estudios ingresados"}
          info={"Acá vas a poder informar tu nivel de estudios"}
          cancel={setIsOpenModal}
          setInfoModal={setInfoModal}
          setDatos={setDatos}
          data={nivelEstudioUser}
          setEditSkill={setEditSkill}
          open={setIsOpenModalVerTodos}
        />
      </div>

      {isOpenModal && (
        <ModalHabilidaes
          cancel={setIsOpenModal}
          infoModal={infoModal}
          skills={selectSkills}
          tecnologias={selectTecnologias}
          subTecnologias={selectSubTecnologias}
          datos={datos}
          guardarSkill={handleGuardarSkills}
          guardarCertificaciones={handleGuardarcertificaciones}
          editSkill={editSkill}
          setEditSkill={setEditSkill}
          deleteSkills={handleEliminar}
          nivelEstudios={selectNivelEstudio}
        />
      )}

      {isOpenModalVerTodos && (
        <ModalVerTodos
          open={setIsOpenModalVerTodos}
          infoModal={infoModal}
          datos={[]}
          setEditSkill={setEditSkill}
          cancel={setIsOpenModal}
        />
      )}
    </>
  );
};

export default Habilidades;
