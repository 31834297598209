import { useContext } from "react";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { AuthContext } from "../contexts/AuthContext";

const SpeedDialCustom = ({ speedDialClick, tipoNovedad }) => {
  const { usuario } = useContext(AuthContext);

  const speedDialItems = [
    [
      {
        label: "Pago a proveedores",
        icon: "pi pi-dollar",
        command: () => {
          speedDialClick("FormPP", "right");
        },
      },
    ],
    [
      {
        label: "Facturación de servicios",
        icon: "pi pi-briefcase",
        command: () => {
          speedDialClick("FormFS", "right");
        },
      },
      {
        label: "Facturación de horas extras",
        icon: "pi pi-clock",
        command: () => {
          speedDialClick("FormHE", "right");
        },
      },
      {
        label: "Rendición de gastos",
        icon: "pi pi-credit-card",
        command: () => {
          speedDialClick("FormIG", "right");
        },
      },
    ],
    [
      {
        label: "Nuevo cecos",
        icon: "pi pi-user",
        command: () => {
          speedDialClick("FormCC", "right");
        },
      },
    ],
    [
      {
        label: "Patagonian",
        icon: "pi pi-user",
        command: () => {
          speedDialClick("FormRE", "right");
        },
      },
      {
        label: "Asignación centro de costos",
        icon: "pi pi-user-edit",
        command: () => {
          speedDialClick("FormRA", "right");
        },
      },
      {
        label: "Pago B-21",
        icon: "pi pi-envelope",
        command: () => {
          speedDialClick("FormPB", "right");
        },
      },
    ],
    [
      {
        label: "Nuevo usuario",
        icon: "pi pi-user-edit",
        command: () => {
          speedDialClick("FormUser", "right");
        },
      },
    ],
    [
      {
        label: "Nuevo freelance",
        icon: "pi pi-user-edit",
        command: () => {
          speedDialClick("FormFreelance", "right");
        },
      },
    ],
    [
      {
        label: "Nuevo usuario externo",
        icon: "pi pi-user-edit",
        command: () => {
          speedDialClick("FormUsuarioExterno", "right");
        },
      },
    ],
    [
      {
        label: "Nuevo proyecto",
        icon: "pi pi-briefcase",
        command: () => {
          speedDialClick("FormProyectos", "right");
        },
      },
    ],
  ];

  return (
    <div className="speeddial-tooltip-demo">
      <Tooltip
        target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
        position="top"
      />
      <SpeedDial
        className="speed_dial speeddial-right"
        model={
          (tipoNovedad === 0 && speedDialItems[0]) ||
          (tipoNovedad === 1 &&
            (usuario.Rol === "comercial"
              ? speedDialItems[2]
              : speedDialItems[1])) ||
          (tipoNovedad === 2 && speedDialItems[3]) ||
          (tipoNovedad === 3 && speedDialItems[4]) ||
          (tipoNovedad === 4 && speedDialItems[5]) ||
          (tipoNovedad === 5 && speedDialItems[6])
        }
        direction="right"
      />
    </div>
  );
};

export default SpeedDialCustom;
