import { Steps } from "primereact/steps";
import "./DatosDelProyecto/DatosDelProyecto.css";

const CustomStep = ({ activeIndex }) => {
  const items = [
    { label: "Datos del proyecto" },
    { label: "Selección de Talento" },
    { label: "Resumen de cotización" },
  ];

  return (
    <div className="estile-step">
      <Steps
        model={items}
        readOnly={true}
        activeIndex={activeIndex}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default CustomStep;
