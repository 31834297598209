import React, { useContext, useEffect } from "react";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { Dialog } from "primereact/dialog";

const PopUpRecursos = ({
    visible,
    setVisible,
    idCeco,
}) => {
    const { getRecursosFromCeco, recursosFromCeco } = useContext(NovedadesContext);

    useEffect(() => {
        getRecursosFromCeco(idCeco);
    }, [idCeco]);

    return (
        <>
            {visible && (
                <Dialog
                    draggable={false}
                    header={`Recursos del centro de costos`}
                    visible={visible}
                    onHide={() => {
                        setVisible(false)
                    }}
                >
                    {!recursosFromCeco && (
                        <>
                            <h3>No hay recursos en este centro de costos</h3>
                        </>
                    )}
                    {recursosFromCeco !== null &&
                        recursosFromCeco?.map((e, i) => (
                            <div key={i}>
                                <b>Id: </b>{e.IdRecurso} | 
                                <b> Nombre: </b> {e.Nombre}, {e.Apellido}
                            </div>
                        ))}
                </Dialog>
            )}
        </>
    )
}

export default PopUpRecursos