
import { Dialog } from "primereact/dialog";
import "./ModalDelete.css";

const ModalDeleteButton = ({ cotizacion, close, data }) => {

  const handleClick = () => {
    data(cotizacion._id)
  }

  return (
    <div className="menu-opciones-home" style={{ width: "100%" }}>
      <Dialog visible={true} className="dialog-modal" closable={false}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="pi pi-exclamation-circle"
            id="ciruculo-exclamacion-modal-home"
          ></i>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <p className="p-modal-home">
            ¿Estás seguro que querés eliminar{" "}
            <strong>‘Proyecto {cotizacion.projectData?.projectName}’</strong>?
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => close(false)}
            className="button-cancelar-modal"
          >
            Cancelar
          </button>
          <button className="button-editar-modal" onClick={handleClick}>Sí, eliminar</button>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalDeleteButton;
