import React, { useState, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import FileUploadControl from "./FileUploadControl";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { validateTotUploadSize } from "../helpers/validateTotUploadSize";
import "../styles/modalEstado.css";

const ModalAceptada = ({ visible, setVisible, novedadSeleccionada }) => {
  const [motivo, setMotivo] = useState("");
  const { agregaAdjuntos } = useContext(NovedadesContext);
  const [adjuntos, setAdjuntos] = useState([]);
  const { updateEstado, updateMotivo } = useContext(NovedadesContext);

  const handleUpdateEstado = () => {
    updateEstado("Aprobar", novedadSeleccionada.IdSecuencial);
    agregaAdjuntos(novedadSeleccionada.IdSecuencial, adjuntos);
    updateMotivo(novedadSeleccionada.IdSecuencial, {
      Motivo: motivo,
    });
    setVisible(false);
  };

  return (
    <Dialog
      draggable={false}
      header="La novedad pasara a estado Aceptada"
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="row-container">
        <div className="input-container">
        <div className="input-container">
          <span className="p-float-label">
            <InputTextarea
              id="motivo"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              autoResize
            />
            <label htmlFor="motivo">Comentario</label>
          </span>
        </div>
        <br />
          <FileUploadControl setAdjuntos={setAdjuntos} />
        </div>
      </div>
      <div className="final-submit-modal">
        <Button onClick={() => setVisible(false)}>Cancelar</Button>
        <Button onClick={handleUpdateEstado} disabled={!validateTotUploadSize(adjuntos)}>Guardar</Button>
      </div>
    </Dialog>
  );
};

export default ModalAceptada;
