import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { AuthContext } from "../../contexts/AuthContext";
import { DialogExportExcel } from "./DialogExportExcel";
import Menu from "../../components/Menu";
import PopUpRecursos from "../../components/PopUpRecursos";
import logoitp from "../../imagenes/logo.png";
import { FilterMatchMode } from "primereact/api";
import { FilterOperator } from "primereact/api";
import "./informes.css";
import { EXCEL_TYPES } from "./types";

const Informes = () => {
  const { getRecursos, recursosArray, getCecos, cecos } =
    useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const [listadoOpt, setListadoOpt] = useState(0);
  const [visible, setVisible] = useState(false);
  const [xlsDialogVis, setXlsDialogVis] = useState(false);
  const [xlsTipo, setXlsTipo] = useState(0);

  const [idCeco, setIdCeco] = useState(0);

  const [filters, setFilters] = useState({
    Legajo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Apellido: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Gerente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ccNumero: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ccNombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Codigo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Cliente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Proyecto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CuitCuil: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });
  
  useEffect(() => {
    getRecursos();
    getCecos();
  }, []);

  const speedDialItems = [
    {
      label: "Informe Exportable Clientes",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.clientes);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable Proveedores",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.proveedores);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable PO",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.po);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "po",
    },
    {
      label: "Informe Recursos",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.recursos);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol !== "",
    },
  ];


  // const cecoDelRecursoBody = (rowData) => {
  //   console.log(rowData)
  //   return (
  //     <div className="campoCecoPorRecuro">
  //       {rowData.Rcc?.map((e, i) => (
  //         <div key={i}>{e.CcCliente}</div>
  //       ))}
  //     </div>
  //   );
  // };

  const onClickGetRecursos = (rowData) => {
    setVisible(true);
    setIdCeco(rowData.Codigo);
  };

  const columnGerente = (rowData) => {
    const legajoGerente = parseInt(rowData.Gerente);
    const dataGerente = recursosArray.filter((x) => x.Legajo === legajoGerente);
    return <div>{dataGerente[0]?.datoGerente}</div>;
  };

  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades" style={{ marginBottom: "20px" }}>
        <div>
          <h3 className="">Informes y consultas</h3>
          {(usuario.Rol === "po" || usuario.Rol === "admin") && (
            <div className="speeddial-tooltip-demo">
              <Tooltip
                target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
                position="top"
              />
              <SpeedDial
                className="speed_dial speeddial-right"
                model={speedDialItems}
                direction="right"
              />
            </div>
          )}
        </div>
      </div>
      <DialogExportExcel
        usuario={usuario}
        xlsTipo={xlsTipo}
        opciones={xlsTipo !== EXCEL_TYPES.recursos}
        xlsDialogVis={xlsDialogVis}
        setXlsDialogVis={setXlsDialogVis}
      />
      <div className="input-container div_dropdown-informes">
        <span className="p-float-label">
          <Dropdown
            inputId="option"
            name="option"
            className="dropdown-informes"
            value={listadoOpt}
            onChange={(e) => setListadoOpt(e.value)}
            options={[
              { label: "Recursos", id: 0 },
              { label: "Cecos", id: 1 },
            ]}
            optionLabel="label"
          />
          <label htmlFor="option" style={{ fontSize: "13px" }}>
            Seleccione una lista
          </label>
        </span>
      </div>
      {listadoOpt.id === 0 && (
        <DataTable
          value={recursosArray}
          scrollable
          paginator
          className="p-datatable-customers"
          rows={12}
          dataKey="IdSecuencial"
          filterDisplay="menu"
          filters={filters}
          responsiveLayout="scroll"
          emptyMessage="Sin resultados."
          style={{ fontFamily: "var(--main-font)" }}
          sortField="IdSecuencial"
          sortMode="single" //sortMode="multiple"
          sortOrder={-1}
        >
          <Column
            sortable
            field="Legajo"
            header="Legajo"
            style={{ minWidth: "4rem", maxWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por legajo"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Apellido"
            header="Apellido"
            style={{
              minWidth: "8rem",
              maxWidth: "18rem",
              textAlign: "start",
              justifyContent: "start",
            }}
            filter
            filterPlaceholder="Buscar por apellido"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Nombre"
            header="Nombre"
            style={{
              minWidth: "8rem",
              maxWidth: "16rem",
              textAlign: "start",
              justifyContent: "start",
            }}
            filter
            filterPlaceholder="Buscar por nombre"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Gerente"
            header="Gerente"
            style={{
              minWidth: "12rem",
              maxWidth: "20rem",
              textAlign: "start",
              justifyContent: "start",
            }}
            body={columnGerente}
            filter
            filterPlaceholder="Buscar por gerente"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="ccNumero"
            header="Numero cecos"
            style={{
              minWidth: "5rem",
              maxWidth: "14rem",
              textAlign: "start",
              justifyContent: "start",
            }}
            filter
            filterPlaceholder="Buscar por centro de costos"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="ccNombre"
            header="Nombre cecos"
            style={{
              minWidth: "12rem",
              textAlign: "start",
              justifyContent: "start",
            }}
            filter
            filterPlaceholder="Buscar por centro de costos"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false} />
        </DataTable>
      )}
      {listadoOpt.id === 1 && (
        <>
          <DataTable
            value={cecos}
            scrollable
            paginator
            className="p-datatable-customers"
            rows={12}
            filters={filters}
            dataKey="IdSecuencial"
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage="Sin resultados."
            style={{ fontFamily: "var(--main-font)" }}
            sortField="IdSecuencial"
            sortMode="single" //sortMode="multiple"
            sortOrder={-1}
          >
            <Column
              sortable
              field="Codigo"
              header="Codigo"
              style={{ minWidth: "2rem" }}
              filter
              filterPlaceholder="Buscar por codigo"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
            <Column
              sortable
              field="Cliente"
              header="Cliente"
              style={{
                minWidth: "8rem",
                maxWidth: "18rem",
                textAlign: "start",
                justifyContent: "start",
              }}
              filter
              filterPlaceholder="Buscar por cliente"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
            <Column
              sortable
              field="Proyecto"
              header="Proyecto"
              style={{
                minWidth: "8rem",
                maxWidth: "18rem",
                textAlign: "start",
                justifyContent: "start",
              }}
              filter
              filterPlaceholder="Buscar por proyecto"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
            <Column
              sortable
              field="CuitCuil"
              header="CUIT/CUIL"
              style={{
                minWidth: "8rem",
                maxWidth: "18rem",
                textAlign: "start",
                justifyContent: "start",
              }}
              filter
              filterPlaceholder="Buscar por cuit/cuil"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
            <Column
              style={{ minWidth: "4rem", maxWidth: "15rem" }}
              header="Recursos"
              bodyStyle={{ textAlign: "center" }}
              body={(rowData) => (
                <i
                  onClick={() => onClickGetRecursos(rowData)}
                  style={{
                    color: "#084bfa",
                    cursor: "pointer",
                  }}
                  className="pi pi-eye cc-recursos-column"
                />
              )}
            />
          </DataTable>
          <PopUpRecursos
            visible={visible}
            setVisible={setVisible}
            idCeco={idCeco}
          />
        </>
      )}
    </div>
  );
};

export default Informes;
