const API_URL = process.env.REACT_APP_API_URL || "";

const APP_ENV_TYPES = Object.freeze({
  DEV: "dev",
  QA: "qa",
  PROD: "prod",
  ND: "",
});

function appEnvironment() {
  if (API_URL.includes("localhost")) {
    return APP_ENV_TYPES.DEV;
  } else if (API_URL.includes("portaldev")) {
    return APP_ENV_TYPES.QA;
  } else if (API_URL.includes("portal.itpatagonia")) {
    return APP_ENV_TYPES.PROD;
  } else return APP_ENV_TYPES.ND;
}

export { appEnvironment, APP_ENV_TYPES };
