import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import CryptoJS, { AES } from "crypto-js";

const AuthContext = createContext();

const getTokenFromSessionStorage = (key) => {
  const token = localStorage.getItem("token");
  if (token) {
    const decryptedToken = AES.decrypt(token, key).toString(CryptoJS.enc.Utf8);
    return decryptedToken;
  }
  return null;
};

const AuthContextProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [username, setUsername] = useState("");
  const [loadGoogle, setLoadGoogle] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const key = process.env.REACT_APP_TOKEN_KEY;

  useEffect(() => {
    const token = getTokenFromSessionStorage(key);
    if (token) {
      fetch(apiUrl + "/user", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            localStorage.clear();
            navigate("/login", { replace: true });
            setLoadingUser(false);
          } else if (response.status === 404) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Expiró la sesión, vuelva a ingresar",
              life: 5000,
            });
            navigate("/login", { replace: true });
            setLoadingUser(false);
            localStorage.clear();
          } else {
            console.log(response);
          }
        })
        .then((data) => {
          if (data) {
            setUsuario(data);
            setLoadingUser(false);
            //const index = data?.Email.indexOf("@");
            setUsername(data?.Email);
          }
        });
    } else {
      setLoadingUser(false);
      setUsuario(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllUsers = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/users", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 404) {
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      }
      const data = await response.json();
      if (!data) {
        return [];
      }
      return data;
    } catch (err) {
      console.log("error", err);
      return [];
    }
  };

  const loginGoogle = (token) => {
    setLoadGoogle(true);
    setTimeout(() => {
      const Tokenencriptado = AES.encrypt(token, key).toString();
      fetch(apiUrl + "/user", {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            localStorage.clear();
            navigate("/login", { replace: true });
          } else if (response.status === 404) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Debe ingresar un mail con dominio '@itpatagonia.com'",
              life: 5000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              //detail: "",
              life: 5000,
            });
          }
          setLoadGoogle(false);
        })
        .then((data) => {
          if (data) {
            setUsuario(data);
            setLoadingUser(false);
            //  const index = data.Email.indexOf("@");
            setUsername(data.Email);
            localStorage.setItem("token", Tokenencriptado);
          }
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 5000,
          });
        });
    }, 1000);
  };

  const getUser = async (userEmail) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/user/" + userEmail, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response) {
        return response;
      }
    } catch (err) {
      console.log("error", err);
      return err;
    }
  };

  const insertUser = async (newRolObj) => {
    const token = getTokenFromSessionStorage(key);
    try {
      const response = await fetch(apiUrl + "/user", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRolObj),
      });
      if (response) {
        setRefresh(!refresh);
        return response;
      }
    } catch (err) {
      return err;
    }
  };

  const updateUserRol = async (newRolObj) => {
    try {
      const token = getTokenFromSessionStorage(key);
      const response = await fetch(apiUrl + "/user", {
        method: "PATCH",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRolObj),
      });

      const data = await response.json();
      if (!data) {
        return [];
      }
      setRefresh(!refresh);
      return data;
    } catch (err) {
      console.error("Portal Error: ", err);
    }
  };

  const deleteUser = (email) => {
    const token = getTokenFromSessionStorage(key);
    fetch(apiUrl + "/user/" + email, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((data) => console.log())
      .catch((err) => console.log("error", err));
  };

  const logout = () => {
    setUsuario(null);
    localStorage.clear();
    setLoadGoogle(false);
  };

  const loggedIn = () => {
    return usuario ? true : false;
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
        usuario,
        setUsuario,
        loggedIn,
        loginGoogle,
        loadingUser,
        username,
        getAllUsers,
        getTokenFromSessionStorage,
        loadGoogle,
        setLoadGoogle,
        getUser,
        insertUser,
        updateUserRol,
        deleteUser,
        refresh,
      }}
    >
      {children}
      <Toast ref={toast} />
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
export { AuthContext };
