import { PickList } from "primereact/picklist";

const PickListCC = ({ source, setSource, target, setTarget }) => {
  const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2">
          <span>{item.Legajo} - {item.Apellido}, {item.Nombre}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <PickList
        dataKey="IdRecurso"
        source={source}
        target={target}
        onChange={onChange}
        itemTemplate={itemTemplate}
        breakpoint="1280px"
        sourceHeader="Disponibles"
        targetHeader="Seleccionados"
        sourceStyle={{ height: "24rem" }}
        targetStyle={{ height: "24rem" }}
      />
    </div>
  );
};

export default PickListCC;
