import React, { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import validaCuit from "../helpers/validaCuit";

import "../styles/fieldProveedor.css";
import { NovedadesContext } from "../contexts/NovedadesContext";

const FieldProveedor = ({
  setAgregarProv,
  setRazonSocial,
  setCuit,
  razonSocial,
  cuit,
  setNuevoProv,
  novedadTipo,
}) => {
  const [clienteExistente, setClienteExistente] = useState(false);
  const { clientes } = useContext(NovedadesContext);

  useEffect(() => {
    novedadTipo !== "PP"
      ? setClienteExistente(
          clientes.some((ceco) => ceco.CuitCuil.toString() === cuit)
        )
      : setClienteExistente(false);
  }, [cuit]);

  const guardarProveedor = () => {
    setNuevoProv(`${razonSocial.toUpperCase()} - ${cuit}`);
    setAgregarProv(false);
  };

  //const cuitClassName = validaCuit(cuit) ? "" : "p-invalid";

  return (
    <div className="field-proveedor-container">
      <h4>{novedadTipo === "PP" ? "Agregar proveedor" : "Agregar cliente"} </h4>
      <div className="row-container">
        <div className="input-container">
          <span className="p-float-label">
            <InputText
              id="razonSocial"
              value={razonSocial}
              autoComplete="off"
              onChange={(e) => setRazonSocial(e.target.value)}
            />
            <label htmlFor="razonSocial">Razon Social</label>
          </span>
        </div>
        <div className="input-container">
          <span className="p-float-label">
            <InputText
              id="cuit"
              value={cuit}
              onChange={(e) => setCuit(e.target.value)}
              maxLength={11}
              keyfilter="int"
              // className={cuitClassName}
              autoComplete="off"
              required={true}
            />
            <label htmlFor="cuit">CUIT</label>
          </span>
        </div>
      </div>
      {novedadTipo === "PP" && <h4>*Recuerde adjuntar los datos bancarios</h4>}
      {clienteExistente && <h4>El CUIT ingresado pertenece a un cliente.</h4>}
      {(cuit.length ===11 && !validaCuit(cuit)) && <h4>El CUIT ingresado no es válido</h4>}

      <div className="final-submit">
        <Button
          disabled={!razonSocial || !validaCuit(cuit) || clienteExistente}
          onClick={guardarProveedor}
          label="Aceptar"
        />
        <Button onClick={() => setAgregarProv(false)} label="Cancelar" />
      </div>
    </div>
  );
};

export default FieldProveedor;
