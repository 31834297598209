import { useRef } from "react";
import { usePDF } from "@react-pdf/renderer";
import { Toast } from "primereact/toast";
import Menu from "../../components/Menu";
import ListadoClientesPDF from "./ListadoClientesPDF";
import logoitp from "../../imagenes/logo.png";
//import styles from "./listados.module.css";
import "../../styles/pages/listados.css";

const Listados = () => {
  const toast = useRef(null);
  const [instance, updateInstance] = usePDF({ document: ListadoClientesPDF });

  if (instance.loading) return <div>Loading ...</div>;
  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <div className="container">
      <Toast ref={toast} />
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de Novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades">
        <h3 className="">Listados</h3>
      </div>
      <div>
        <ul>
          <li>
            <a
              href={instance.url}
              className="listados"
              download="portal-itp-listado-facturacion.pdf">
              Facturación de Clientes
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Listados;
