import React, { useState, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { NovedadesContext } from "../contexts/NovedadesContext";
import "../styles/modalEstado.css";

const ModalRechazada = ({ visible, setVisible, novedadSeleccionada }) => {
  const [motivo, setMotivo] = useState("");
  const { updateEstado, updateMotivo } = useContext(NovedadesContext);

  const handleUpdateEstado = () => {
    updateEstado("Rechazar", novedadSeleccionada.IdSecuencial);
    updateMotivo(novedadSeleccionada.IdSecuencial, {
      Motivo: motivo,
    });
    setVisible(false);
  };

  return (
    <Dialog
      draggable={false}
      header="La novedad pasara a estado Rechazada"
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="row-container">
        <div className="input-container">
          <span className="p-float-label">
            <InputTextarea
              id="motivo"
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              autoResize
            />
            <label htmlFor="motivo">Motivo de rechazo</label>
          </span>
        </div>
      </div>
      <div className="final-submit-modal">
        <Button onClick={() => setVisible(false)}>Cancelar</Button>
        <Button onClick={handleUpdateEstado}>Guardar</Button>
      </div>
    </Dialog>
  );
};

export default ModalRechazada;
