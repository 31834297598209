import React, { useContext } from "react";
import { Routes as RoutesRRD, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Configuracion from "../pages/Configuracion";
import PortalPersonal from "../pages/PortalPersonal";
import BandejaDeEntrada from "../pages/BandejaDeEntrada";
import Listados from "../pages/Listados";
import PanelAdmin from "../pages/PanelAdmin";
import UsuariosRoles from "../pages/PanelAdmin/UsuariosRoles";
import NotFound from "../pages/NotFound";
import Informes from "../pages/Informes";
import PanelFreelance from "../pages/PanelFreelance/index";
// import UsuariosExternos from "../pages/PanelAdmin/UsuariosExternos";
import PanelUsuariosExternos from "../pages/PanelUsuariosExternos/index";

import TimesheetPage from "../pages/Timesheet/TimesheetPage";
import DatosDelProyectoIndex from "../pages/Cotizador/DatosDelProyecto";
import ResumenDeCotizacion from "../pages/Cotizador/ResumenDeCotizacion/ResumenDeCotizacion";
import PantallaInicioIndex from "../pages/Cotizador/PantallaInicio/PantallaInicioIndex";
import TarifarioIndex from "../pages/Tarifario/index";
import SeleccionDeTalentoIndex from "../pages/Cotizador/SeleccionDeTalento";
import Habilidades from "../pages/Habilidades";
const Routes = () => {
  const { loggedIn, usuario } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    if (!loggedIn()) {
      return <Navigate to="/login" replace={true} />;
    }
    return children;
  };

  return (
    <div className="App">
      <RoutesRRD>
        <Route
          path="/"
          element={
            usuario && usuario.Rol !== "" ? (
              usuario.Rol === "admin" ? (
                <Navigate to="/bandeja" />
              ) : (
                <Navigate to="/novedades" />
              )
            ) : (
              <Navigate to="/miportal" />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/novedades"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/bandeja"
          element={
            <RequireAuth>
              <BandejaDeEntrada />
            </RequireAuth>
          }
        />
        <Route
          path="/configuracion"
          element={
            <RequireAuth>
              <Configuracion />
            </RequireAuth>
          }
        />
        <Route
          path="/miportal"
          element={
            <RequireAuth>
              <PortalPersonal />
            </RequireAuth>
          }
        />
        <Route
          path="/listados"
          element={
            <RequireAuth>
              <Listados />
            </RequireAuth>
          }
        />
        <Route
          path="/paneladmin"
          exact
          element={
            <RequireAuth>
              <PanelAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="paneladmin/usuarios-roles"
          exact
          element={
            <RequireAuth>
              <UsuariosRoles />
            </RequireAuth>
          }
        />
        <Route
          path="paneladmin/usuarios-externos"
          exact
          element={
            <RequireAuth>
              <PanelUsuariosExternos />
            </RequireAuth>
          }
        />
        <Route
          path="/informes"
          exact
          element={
            <RequireAuth>
              <Informes />
            </RequireAuth>
          }
        />
        <Route
          path="/panelfreelance"
          exact
          element={
            <RequireAuth>
              <PanelFreelance />
            </RequireAuth>
          }
        />

        <Route
          path="/cotizador/datos-proyecto"
          exact
          element={
            <RequireAuth>
              <DatosDelProyectoIndex />
            </RequireAuth>
          }
        />

        <Route
          path="/cotizador/resumen-cotizacion"
          exact
          element={
            <RequireAuth>
              <ResumenDeCotizacion />
            </RequireAuth>
          }
        />
        <Route
          path="/cotizador"
          exact
          element={
            <RequireAuth>
              <PantallaInicioIndex />
            </RequireAuth>
          }
        />

        <Route
          path="/Tarifario"
          exact
          element={
            <RequireAuth>
              <TarifarioIndex />
            </RequireAuth>
          }
        />

        <Route
          path="/cotizador/talentos"
          exact
          element={
            <RequireAuth>
              <SeleccionDeTalentoIndex />
            </RequireAuth>
          }
        />

        <Route
          path="/timesheet"
          exact
          element={
            <RequireAuth>
              <TimesheetPage />
            </RequireAuth>
          }
        />

        <Route
          path="/habilidades"
          exact
          element={
            <RequireAuth>
              <Habilidades/>
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFound />} />
      </RoutesRRD>
    </div>
  );
};

export default Routes;
