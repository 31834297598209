import "./PantallaInicioCotizador.css";
import { Button } from "primereact/button";
import Pantalla from "../../../imagenes/Pantalla.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Card } from "primereact/card";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import ModalDeleteButton from "./ModalDelete";
import { Paginator } from "primereact/paginator";
import { AuthContext } from "../../../contexts/AuthContext";

const PantallaInicioCotizador = () => {
  const navigate = useNavigate();
  const {
    setDatosProyectos,
    setDataTablaTalento,
    cotizaciones,
    version,
    getClientesFiltros,
    clientesFiltros,
    usuarios,
    getUsuarios,
    getCostosVariables,
    getCotizaciones,
    deleteCotizacion,
    setSelectedTipoProyecto,
    setDataCalculoTalento,
    setTotalOtrosCostos,
    setIdCotizacion,
    setDuracionDelProyecto,
    setHorasDelProyecto,
    getTarifario,
    beneficios
  } = useContext(CotizadorContext);
  const { usuario } = useContext(AuthContext);

  const [selectedClientes, setSelectedClientes] = useState([]);
  const [selectedUsuarios, setSelectedUsuarios] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cotizacionSeleccionada, setCotizacionSeleccionada] = useState(null);
  const [first, setFirst] = useState(1);
  const overlayPanelRefs = useRef({});

  const toggleOverlayPanel = (e, id) => {
    if (overlayPanelRefs.current[id]) {
      overlayPanelRefs.current[id].toggle(e);
    }
  };

  useEffect(() => {
    setDatosProyectos([]);
    setDataTablaTalento([]);
    getClientesFiltros();
    getUsuarios();
    getCostosVariables();
    getCotizaciones();
    setIdCotizacion(null);
    getTarifario(1, 1, "");
  }, []);

  useEffect(() => {
    setFiltros([...selectedClientes, ...selectedUsuarios]);
  }, [selectedClientes, selectedUsuarios]);

  const removeClient = (cliente) => {
    const updatedClientes = selectedClientes.filter((c) => c !== cliente);
    setSelectedClientes(updatedClientes);
    fetchFilteredData(updatedClientes, selectedUsuarios);
  };

  const removeUsuario = (usuario) => {
    const updatedUsuarioIds = selectedUsuarios.filter(
      (id) => id._id !== usuario._id
    );
    setSelectedUsuarios(updatedUsuarioIds);
    fetchFilteredData(selectedClientes, updatedUsuarioIds);
  };

  const fetchFilteredData = (clientes, usuarios) => {
    const userIds = usuarios.map((usuario) => usuario._id);
    getCotizaciones(first, clientes, userIds);
  };

  const handleClienteChange = (e) => {
    if (e.value.length <= 5) {
      setSelectedClientes(e.value);
      getCotizaciones(
        first,
        e.value,
        selectedUsuarios.map((u) => u._id)
      );
    }
  };

  const handleUsuarioChange = (e) => {
    if (e.value.length <= 5) {
      setSelectedUsuarios(e.value);
      getCotizaciones(
        first,
        selectedClientes,
        e.value.map((u) => u._id)
      );
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const ensureCOTPrefix = (version) => {
    if (version.startsWith("COT")) {
      return version;
    }
    return `COT ${version}`;
  };

  const onPageChange = async (event) => {
    const paginaActual = event.page + 1;
    setFirst(event.first);
    const userIds = selectedUsuarios.map((usuario) => usuario._id);
    getCotizaciones(paginaActual, selectedClientes, userIds);
  };

  const eliminarCotizacion = (cotizacion) => {
    deleteCotizacion(cotizacion);
    setIsOpenModal(false);
  };

  const cargarCotizacion = (cotizacion) => {
    setDatosProyectos({
      esCliente: cotizacion.projectData.isClient ? "si" : "no",
      cliente: cotizacion.projectData.client,
      nombreOportunidad: cotizacion.projectData.projectName,
      tipoProyecto: cotizacion.projectData.projectType,
      duracion: { name: cotizacion.projectData.duration },
      horas: cotizacion.projectData.hours
        ? parseInt(cotizacion.projectData?.hours.replace(/\D/g, ""), 10)
        : null,
      formaPago: { name: cotizacion.projectData.paymentTerms },
      impuesto: {
        name: cotizacion.projectData.grossIncomeTax,
        percent: cotizacion.calculateTable.modal.impIngresosBrutos,
      },
      moneda:
        cotizacion.currency === "Peso argentino" ? "$" : cotizacion.currency,
      tieneLEC: cotizacion.projectData.LEC ? "si" : "no",
    });
    setDuracionDelProyecto({ name: cotizacion.projectData.duration });
    setHorasDelProyecto(
      cotizacion.projectData.hours
        ? parseInt(cotizacion.projectData?.hours.replace(/\D/g, ""), 10)
        : null
    );
    const arrayDeObjetos = cotizacion.talentos.map((coti, index) => {
      const matchedBenefit = beneficios.find(benefit => benefit.name === coti.benefitsPackage)
      return {
        bandaSalarial: coti.bandaSalarial,
        base: coti.base,
        cantHoras: coti.cantHoras,
        cantidad: coti.amount,
        cfteVenta: coti.cfteVenta,
        costoUnitario: coti.costs,
        costosFijos: coti.fijosCosts,
        costosVariables: coti.variablesCosts,
        diasVacaciones: { daysAmount: coti.vacationsDays },
        horaVenta: coti.valorHoraVenta,
        id: index + 1,
        paqueteBeneficios: {
          name: coti.benefitsPackage,
          percent: matchedBenefit ? matchedBenefit.percent : 0
        },
        perfilado: coti.perfilado,
        perfiladoPorcentaje: coti.perfiladoPorcentaje,
        rol: coti.role,
        selected: false,
        seniority: coti.seniority,
        skill: coti.skill,
        subTotal: coti.subtotalVenta,
        subtotalCosto: coti.subtotalCosto,
        sueldoBruto: coti.SB,
        tipoContratacion: coti.employmentType,
        valorHoraCosto: coti.valorHoraCosto,
        ventaTotal: coti.ventaTotal,
      };
    });
    setDataTablaTalento(arrayDeObjetos);
    setDataCalculoTalento({
      costoTotal: cotizacion.calculateTable.table.totalCosts,
      facturaEmitir: cotizacion.calculateTable.table.invoiceToIssue,
      facturaEmitirTotal: cotizacion.calculateTable.table.totalInvoice,
      porcentajeMensual: cotizacion.calculateTable.table.mProfitMargin,
      porcentajeTotal: cotizacion.calculateTable.table.oProfitMargin,
      rentabilidadMensual: cotizacion.calculateTable.table.monthlyProfit,
      rentabilidadTotal: cotizacion.calculateTable.table.overallProfitability,
      subtotalCostos: cotizacion.calculateTable.table.subtotalCosts,
      sumaTotalOtrosCostos: cotizacion.calculateTable.table.additionalCosts,
      idUser: cotizacion.idUser,
      simboloMoneda:
        cotizacion.currency === "Peso argentino" ? "$" : cotizacion.currency,
      rentabilidadTotalOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.overallProfitability
          : cotizacion.calculateTable.table.arsOverallProfitability,
      rentabilidadMensualOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.monthlyProfit
          : cotizacion.calculateTable.table.arsMonthlyProfit,
      facturaEmitirOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.invoiceToIssue
          : cotizacion.calculateTable.table.arsInvoiceToIssue,
      facturaEmitirTotalOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.totalInvoice
          : cotizacion.calculateTable.table.arsTotalInvoice,
      subtotalCostosOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.subtotalCosts
          : cotizacion.calculateTable.table.arsSubtotalCosts,
      costoTotalOriginal:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.totalCosts
          : cotizacion.calculateTable.table.arsTotalCosts,
    });
    setTotalOtrosCostos({
      comisionFacturacion: cotizacion.calculateTable.modal.comisions,
      costoFinanciero: cotizacion.calculateTable.modal.costoFinanciero,
      estructuraServicios: cotizacion.calculateTable.table.estructuraServices,
      gastosGenerales: cotizacion.calculateTable.modal.overallGastos,
      impuestoDebitoCredito: cotizacion.calculateTable.modal.impDebYCred,
      impuestoIngresosBrutos: cotizacion.calculateTable.modal.impIngresosBrutos,
      otherGastos: cotizacion.calculateTable.modal.otherGastos,
      otrosGastosPorc: cotizacion.calculateTable.modal.otherGastosPercentage,
      suma:
        cotizacion.currency === "Peso argentino"
          ? cotizacion.calculateTable.table.additionalCosts
          : cotizacion.calculateTable.table.arsAdditionalCosts,
    });
    setSelectedTipoProyecto({ name: cotizacion.projectData.projectType });
    setIdCotizacion(cotizacion._id);
  };

  return (
    <>
      {cotizaciones.paginatedData.length === 0 ? (
        <div style={{ marginLeft: "20px" }}>
          <Button
            label="Volver atrás"
            icon="pi pi-angle-left"
            className="button-volver"
            onClick={() => navigate("/novedades")}
          />
          <h1 className="text-h1" style={{ marginLeft: "5px" }}>
            Cotizador
          </h1>
          <p style={{ marginLeft: "5px" }}>Versión COT {version}</p>
        </div>
      ) : (
        <div style={{ marginLeft: "20px" }}>
          <Button
            label="Volver atrás"
            icon="pi pi-angle-left"
            className="button-volver"
            onClick={() => navigate("/novedades")}
          />
          <div className="titulo_novedades" style={{ marginLeft: "10px" }}>
            <div>
              <h3 className="">Cotizaciones</h3>
              <div className="speeddial-tooltip-demo">
                <Tooltip
                  target=".speeddial-tooltip-demo .p-speeddial-button"
                  content="Crear cotización"
                  position="top"
                />
                <SpeedDial
                  className="speed_dial speeddial-right"
                  direction="right"
                  onClick={() => navigate("/cotizador/datos-proyecto")}
                />
              </div>
            </div>
          </div>
          <p className="version" style={{ marginLeft: "10px" }}>
            Versión COT {version}
          </p>
        </div>
      )}
      {cotizaciones.paginatedData.length === 0 &&
      selectedClientes.length === 0 &&
      selectedUsuarios.length === 0 ? (
        <div className="div-imagen-cotizador">
          <div className="center-content">
            <div className="content-wrapper">
              <img src={Pantalla} alt="Pantalla" />
              <h3 className="title">No tenés cotizaciones ingresadas.</h3>
            </div>
            <div>
              <Button
                label="Agregar cotización"
                icon="pi pi-plus"
                className="button-cotizar"
                onClick={() => navigate("/cotizador/datos-proyecto")}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginLeft: "25px" }}>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <MultiSelect
                placeholder="Cliente"
                style={{ width: "255px", marginRight: "10px", height: "41px" }}
                className="w-full md:w-20rem select-clientes-home"
                filter
                options={clientesFiltros.map((cliente) => cliente)}
                value={selectedClientes}
                onChange={handleClienteChange}
              />
              {usuario.Rol === "board" && (
                <MultiSelect
                  placeholder="Usuario"
                  className="w-full md:w-20rem select-clientes-home"
                  options={usuarios}
                  optionLabel="nombreCompleto"
                  filter
                  value={selectedUsuarios}
                  onChange={handleUsuarioChange}
                  style={{ width: "255px", height: "41px" }}
                />
              )}
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              {filtros.map((filtro) => (
                <div
                  key={filtro.nombreCompleto || filtro}
                  className="selected-client"
                >
                  {filtro.nombreCompleto || filtro}
                  <i
                    className="pi pi-times"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      filtro.nombreCompleto
                        ? removeUsuario(filtro)
                        : removeClient(filtro)
                    }
                  ></i>
                </div>
              ))}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {cotizaciones.paginatedData.map((coti) => {
                return (
                  <div
                    key={coti._id}
                    style={{
                      width: "407px",
                      height: "233px",
                      marginTop: "20px",
                    }}
                  >
                    <Card
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        height: "233px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            marginBottom: "15px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Proyecto:{" "}
                          <strong> {coti.projectData.projectName}</strong>
                        </p>
                        <Button
                          icon="pi pi-ellipsis-v"
                          className="p-button-rounded p-button-text"
                          onClick={(e) => toggleOverlayPanel(e, coti._id)}
                          style={{
                            padding: "0px",
                            color: "rgba(76, 78, 100, 0.38)",
                          }}
                        />
                        <OverlayPanel
                          ref={(el) =>
                            (overlayPanelRefs.current[coti._id] = el)
                          }
                          style={{
                            width: "154px",
                            height: "96px",
                          }}
                        >
                          <div className="menu-opciones-home">
                            <Button
                              className="p-button-edit-delete"
                              onClick={() => {
                                cargarCotizacion(coti);
                                navigate("/cotizador/datos-proyecto");
                              }}
                            >
                              <i
                                className="pi pi-pencil"
                                id="pencil-edit-home"
                              ></i>
                              Editar
                            </Button>
                            <Button
                              className="p-button-edit-delete"
                              onClick={() => {
                                setIsOpenModal(true);
                                setCotizacionSeleccionada(coti);
                              }}
                            >
                              <i
                                className="pi pi-trash"
                                id="trash-delete-home"
                              ></i>
                              Eliminar
                            </Button>
                          </div>
                        </OverlayPanel>
                      </div>
                      <div
                        onClick={() => {
                          cargarCotizacion(coti);
                          navigate("/cotizador/resumen-cotizacion");
                        }}
                      >
                        <p className="p-cards">
                          <strong>Cliente:</strong> {coti.projectData.client}
                        </p>
                        <p className="p-cards">
                          <strong>Creación:</strong>{" "}
                          {formatDate(coti.fechaCreacion)}
                        </p>
                        <p className="p-cards">
                          <strong>Última actualización:</strong>{" "}
                          {formatDate(coti.fechaActualizacion)}
                        </p>
                        <p className="p-cards">
                          <strong>Versión:</strong>{" "}
                          {ensureCOTPrefix(coti.version)}
                        </p>
                        <div className="user-card">
                          <i
                            className="pi pi-user"
                            id="pi-user-card-cotizador-home"
                          ></i>
                          <p className="p-user-card">{coti.userName}</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
            {cotizaciones.paginatedData.length !== 0 && (
              <div className="card">
                <Paginator
                  first={first}
                  rows={10}
                  totalRecords={cotizaciones.amount}
                  onPageChange={onPageChange}
                />
              </div>
            )}
            {cotizaciones.paginatedData.length === 0 &&
              (selectedClientes.length > 0 || selectedUsuarios.length > 0) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <h2>No se ha encontrado nada con esos filtros</h2>
                </div>
              )}

            {isOpenModal && (
              <ModalDeleteButton
                close={setIsOpenModal}
                cotizacion={cotizacionSeleccionada}
                data={eliminarCotizacion}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PantallaInicioCotizador;
