import { Button } from "primereact/button";
import "./DatosDelProyecto.css";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import CustomStep from "../CustomStep";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
//import { moneda } from "./mockupMoneda";

const DatosDelProyecto = () => {
  const {
    getTipoProyectos,
    tipoProyecto,
    formasPago,
    getFormaPago,
    impuestos,
    getImpuestos,
    getDuracion,
    duracion,
    setDatosProyectos,
    setSelectedTipoProyecto,
    setHorasDelProyecto,
    setDuracionDelProyecto,
    datosProyectos,
    clientes,
    getClientes,
    setDataTablaTalento,
    setTotalOtrosCostos,
    setDataCalculoTalento,
    setGuardarCoti,
    getTipoMoneda,
    tipoMoneda,
  } = useContext(CotizadorContext);

  const navigate = useNavigate();

  useEffect(() => {
    getTipoProyectos();
    getFormaPago();
    getImpuestos();
    getDuracion();
    getClientes();
    setGuardarCoti(false);
    getTipoMoneda();
  }, []);

  const initialDatos = useRef(datosProyectos);

  const formik = useFormik({
    initialValues: {
      esCliente: !Array.isArray(datosProyectos)
        ? datosProyectos.esCliente
        : "si",
      cliente: !Array.isArray(datosProyectos) ? datosProyectos.cliente : "",
      nombreOportunidad: !Array.isArray(datosProyectos)
        ? datosProyectos.nombreOportunidad
        : "",
      tipoProyecto: !Array.isArray(datosProyectos)
        ? datosProyectos.tipoProyecto
        : "",
      duracion: !Array.isArray(datosProyectos) ? datosProyectos.duracion : "",
      horas: !Array.isArray(datosProyectos) ? datosProyectos.horas : "",
      formaPago: !Array.isArray(datosProyectos) ? datosProyectos.formaPago : "",
      impuesto: !Array.isArray(datosProyectos) ? datosProyectos.impuesto : "",
      moneda: !Array.isArray(datosProyectos) ? datosProyectos.moneda : "$",
      tieneLEC: !Array.isArray(datosProyectos) ? datosProyectos.tieneLEC : "si",
    },
    validate: (values) => {
      const errors = {};
      if (!values.esCliente) {
        errors.esCliente = "Campo requerido.";
      }
      if (!values.cliente) {
        errors.cliente = "Campo requerido.";
      }
      if (!values.nombreOportunidad) {
        errors.nombreOportunidad = "Campo requerido.";
      }
      if (!values.tipoProyecto) {
        errors.tipoProyecto = "Campo requerido.";
      }
      if (!values.duracion) {
        errors.duracion = "Campo requerido.";
      }
      if (!values.formaPago) {
        errors.formaPago = "Campo requerido.";
      }
      if (!values.impuesto) {
        errors.impuesto = "Campo requerido.";
      }
      if (!values.moneda) {
        errors.moneda = "Campo requerido.";
      }
      if (!values.tieneLEC) {
        errors.tieneLEC = "Campo requerido.";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (JSON.stringify(values) !== JSON.stringify(initialDatos.current)) {
        setDataTablaTalento([]);
        setTotalOtrosCostos(0);
        setDataCalculoTalento([]);
      }
      setDatosProyectos(values);
      navigate("/cotizador/talentos");
    },
  });

  const isFormFieldValid = (name) => {
    const isValid = !!(formik.touched[name] && formik.errors[name]);
    return isValid;
  };
  const getFormErrorMessage = (name) => {
    return (
      <div style={{ position: "relative" }}>
        {isFormFieldValid(name) ? (
          <small className="p-error-datos-proyecto">
            {formik.errors[name]}
          </small>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div style={{ marginLeft: "20px" }}>
        <Button
          label="Volver atrás"
          icon="pi pi-angle-left"
          className="button-volver"
          onClick={() => {
            navigate("/cotizador");
            setDatosProyectos([]);
          }}
        />
      </div>
      <div style={{ marginLeft: "33px", marginTop: "10px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
          Datos del proyecto
        </h1>
      </div>
      <div style={{ padding: "0px 30px 0px 30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <CustomStep activeIndex={0} />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="div-datos-proyectos">
            <div className="primer-div-datos-proyectos">
              <div className="margin-media-right">
                <label htmlFor="esCliente" className="label-datos-proyectos">
                  ¿Es cliente?
                </label>
                <div className="es-cliente-datosproyectos">
                  <div className="margin-radio-buttons">
                    <label
                      htmlFor="esClienteSi"
                      style={{ marginRight: "6px" }}
                      className="label-si-no"
                    >
                      Si
                    </label>
                    <RadioButton
                      id="esClienteSi"
                      name="esCliente"
                      value="si"
                      onChange={formik.handleChange}
                      checked={formik.values.esCliente === "si"}
                      className={
                        isFormFieldValid("esCliente") ? "p-invalid" : ""
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="esClienteNo"
                      style={{ marginRight: "6px" }}
                      className="label-si-no"
                    >
                      No
                    </label>
                    <RadioButton
                      id="esClienteNo"
                      name="esCliente"
                      value="no"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue("cliente", "");
                        }
                      }}
                      checked={formik.values.esCliente === "no"}
                      className={
                        isFormFieldValid("esCliente") ? "p-invalid" : ""
                      }
                    />
                  </div>
                </div>
                {getFormErrorMessage("esCliente")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="cliente"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Cliente
                </label>
                {formik.values.esCliente === "si" ? (
                  <Dropdown
                    id="cliente"
                    placeholder="Seleccioná un cliente"
                    className={`w-full md:w-14rem select-datos-proyecto ${
                      isFormFieldValid("cliente") ? "p-invalid" : ""
                    }`}
                    options={clientes.map((cliente) => cliente)}
                    name="cliente"
                    value={formik.values.cliente}
                    onChange={(e) => formik.setFieldValue("cliente", e.value)}
                  />
                ) : (
                  <InputText
                    id="cliente"
                    placeholder="Ingresá el nombre del cliente"
                    className={`w-full md:w-14rem select-datos-proyecto ${
                      isFormFieldValid("cliente") ? "p-invalid" : ""
                    }`}
                    name="cliente"
                    value={formik.values.cliente}
                    onChange={formik.handleChange}
                    maxLength={175}
                  />
                )}

                {getFormErrorMessage("cliente")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="nombreOportunidad"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Nombre de la oportunidad
                </label>
                <InputText
                  id="nombreOportunidad"
                  placeholder="Ingresá el nombre de la oportunidad"
                  className={`select-datos-proyecto input-cotizador ${
                    isFormFieldValid("nombreOportunidad") ? "p-invalid" : ""
                  }`}
                  name="nombreOportunidad"
                  value={formik.values.nombreOportunidad}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  maxLength={175}
                />
                {getFormErrorMessage("nombreOportunidad")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="tipoProyecto"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Tipo de proyecto
                </label>
                <Dropdown
                  id="tipoProyecto"
                  placeholder="Seleccioná el tipo de proyecto"
                  className={`w-full md:w-14rem select-datos-proyecto ${
                    isFormFieldValid("tipoProyecto") ? "p-invalid" : ""
                  }`}
                  style={{ paddingRight: "0px" }}
                  name="tipoProyecto"
                  options={tipoProyecto}
                  optionLabel="name"
                  value={
                    tipoProyecto.find(
                      (item) => item.name === formik.values.tipoProyecto
                    ) || null
                  }
                  onChange={(e) => {
                    formik.setFieldValue("tipoProyecto", e.value.name);
                    setSelectedTipoProyecto(e.value);
                  }}
                />
                {getFormErrorMessage("tipoProyecto")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="duracion"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Duración
                </label>
                <Dropdown
                  id="duracion"
                  placeholder="Seleccioná la duración"
                  className={`w-full md:w-14rem select-datos-proyecto ${
                    isFormFieldValid("duracion") ? "p-invalid" : ""
                  }`}
                  name="duracion"
                  options={duracion}
                  optionLabel="name"
                  value={
                    duracion.find(
                      (item) => item.name === formik.values.duracion.name
                    ) || null
                  }
                  onChange={(e) => {
                    formik.setFieldValue("duracion", e.value);
                    setDuracionDelProyecto(e.value);
                  }}
                />
                {getFormErrorMessage("duracion")}
              </div>
            </div>
            <div
              className="datos-proyecto-2"
              style={
                formik.values.tipoProyecto === "Llave en mano"
                  ? { maxWidth: "600px" }
                  : {}
              }
            >
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="horas"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Horas (Opcional)
                </label>
                <InputNumber
                  id="horas"
                  placeholder="Ingresá la cantidad de horas"
                  name="horas"
                  maxLength={6}
                  value={formik.values.horas}
                  className={`select-datos-proyecto-2 input-cotizador ${
                    isFormFieldValid("horas") ? "p-invalid" : ""
                  }`}
                  onValueChange={(e) => {
                    formik.setFieldValue("horas", e.value);
                    setHorasDelProyecto(e.value);
                  }}
                />
                {getFormErrorMessage("horas")}
              </div>

              <div
                style={{
                  display: "grid",
                  marginRight: "15px",
                  minWidth: "200px",
                }}
              >
                <label
                  htmlFor="formaPago"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Forma de pago
                </label>
                <Dropdown
                  id="formaPago"
                  placeholder="Seleccioná la forma de pago"
                  className={`w-full md:w-14rem select-datos-proyecto-2 ${
                    isFormFieldValid("formaPago") ? "p-invalid" : ""
                  }`}
                  name="formaPago"
                  options={formasPago}
                  optionLabel="name"
                  value={
                    formasPago.find(
                      (item) => item.name === formik.values.formaPago.name
                    ) || null
                  }
                  onChange={(e) => formik.setFieldValue("formaPago", e.value)}
                />
                {getFormErrorMessage("formaPago")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="impuesto"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Impuesto a Ingresos Brutos
                </label>
                <Dropdown
                  id="impuesto"
                  placeholder="Seleccioná una provincia"
                  className={`w-full md:w-14rem select-datos-proyecto-2 ${
                    isFormFieldValid("impuesto") ? "p-invalid" : ""
                  }`}
                  name="impuesto"
                  options={impuestos}
                  optionLabel="name"
                  value={
                    impuestos.find(
                      (item) => item.name === formik.values.impuesto.name
                    ) || null
                  }
                  onChange={(e) => formik.setFieldValue("impuesto", e.value)}
                />
                {getFormErrorMessage("impuesto")}
              </div>
              <div style={{ display: "grid", marginRight: "15px" }}>
                <label
                  htmlFor="tipoProyecto"
                  className="label-datos-proyectos"
                  style={{ marginLeft: "5px" }}
                >
                  Tipo de moneda
                </label>
                <Dropdown
                  id="tipoProyecto"
                  placeholder="Seleccioná el tipo de moneda"
                  className={`w-full md:w-14rem select-datos-proyecto ${
                    isFormFieldValid("tipoProyecto") ? "p-invalid" : ""
                  }`}
                  style={{ paddingRight: "0px" }}
                  name="tipoMoneda"
                  options={tipoMoneda}
                  optionLabel="name"
                  value={formik.values.moneda}
                  onChange={(e) => {
                    formik.setFieldValue("moneda", e.value);
                  }}
                />
                {getFormErrorMessage("tipoMoneda")}
              </div>
              <div>
                <label htmlFor="tieneLEC" className="label-datos-proyectos">
                  ¿Tiene LEC?
                </label>
                <div className="es-cliente-datosproyectos">
                  <div className="margin-radio-buttons">
                    <label
                      htmlFor="tieneLECSi"
                      style={{ marginRight: "6px" }}
                      className="label-si-no"
                    >
                      Si
                    </label>
                    <RadioButton
                      id="tieneLECSi"
                      name="tieneLEC"
                      value="si"
                      onChange={formik.handleChange}
                      checked={formik.values.tieneLEC === "si"}
                      className={
                        isFormFieldValid("tieneLEC") ? "p-invalid" : ""
                      }
                    />
                  </div>

                  <div>
                    <label
                      htmlFor=""
                      style={{ marginRight: "6px" }}
                      className="label-si-no"
                    >
                      No
                    </label>
                    <RadioButton
                      name="tieneLEC"
                      id="tieneLECNo"
                      value="no"
                      onChange={formik.handleChange}
                      checked={formik.values.tieneLEC === "no"}
                      className={
                        isFormFieldValid("tieneLEC") ? "p-invalid" : ""
                      }
                    />
                  </div>
                </div>
                {getFormErrorMessage("tieneLEC")}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              type="button"
              label="VOLVER"
              className="button-volver volver-footer"
              onClick={() => {
                navigate("/cotizador");
                setDatosProyectos([]);
              }}
            />
            <Button
              type="submit"
              label="CONTINUAR"
              className="button-continuar-datos-proyectos"
              disabled={!formik.isValid}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default DatosDelProyecto;
