import { useContext, useEffect, useState } from "react";

import { FilterOperator, FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import ModalAceptada from "./ModalAceptada";
import ModalRechazada from "./ModalRechazada";
import { IDBodyTemplate, capitalizarPrimeraLetra, formatNumber } from "../helpers";
import { InputMask } from "primereact/inputmask";
import PopUpResumen from "./PopUpResumen";

const TablaFacturacion = ({ bandeja, allChecked, novedadesGrupoState }) => {
  const { usuario, username } = useContext(AuthContext);
  const {
    loading,
    getNovedades,
    cambioNovedad,
    getNovedadesAYR,
    getNovedadesPendientes,
  } = useContext(NovedadesContext);
  const [novedades, setNovedades] = useState([]);
  const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);
  const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
  const [visibleModalAceptar, setVisibleModalAceptar] = useState(false);
  const [visibleModalRechazar, setVisibleModalRechazar] = useState(false);
  const [novedadSeleccionada, setNovedadSeleccionada] = useState({});
  const [resumenVisible, setResumenVisible] = useState(false);
  const [rowDataSelection, setRowDataSelection] = useState({});
  const [tipoDeNovedad, setTipoDeNovedad] = useState();

  // const index = usuario.Email.indexOf("@");
  // const user = usuario.Email.slice(0, index);
  const [filters, setFilters] = useState({
    IdSecuencial: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Fecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Periodo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Cliente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConceptoDeFacturacion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Resumen: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Usuario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Estado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  useEffect(() => {
    const handleGetNovedades = async () => {
      const userRol = () => {
        const roles = {
          serviciosSop: "servicios",
        };
        const rol = roles[usuario.Rol] || usuario.Rol;
        return usuario.Rol === "comercialSop"
          ? "validos=true"
          : usuario.Rol === "board"
          ? ""
          : `rol=${rol}`;
      };

      const data = !bandeja
        ? await getNovedades(
            usuario.Rol === "comercial" ? "tipo=NC" : "tipo=FS|HE|IG|NC",
            novedadesGrupoState ? userRol() : "usuario=" + username
          )
        : await getNovedadesPendientes();
      const dataAceptada = await getNovedadesAYR();
      const dataRol = data.filter((e) => {
        if (usuario.Rol === "comercial") {
          return e.Tipo === "NC";
        } else {
          return ["FS", "HE", "IG", "NC", "PB"].includes(e.Tipo);
        }
      });
      const dataRolAceptada = dataAceptada.filter((e) => {
        if (usuario.Rol === "comercial") {
          return e.Tipo === "NC";
        } else {
          return ["FS", "HE", "IG", "NC"].includes(e.Tipo);
        }
      });
      setNovedades(!bandeja ? data : dataRol.concat(dataRolAceptada));
      setNovedadesFiltradas(
        !bandeja ? data.filter((e) => e.Estado === "pendiente") : dataRol
      );
    };
    handleGetNovedades();
  }, [cambioNovedad, novedadesGrupoState]);

  const handleResumenClick = (rowData) => {
    setTipoDeNovedad("Clientes");
    setResumenVisible(true);
    setRowDataSelection(rowData);
  };

  const limitarCaracteres = (value, maxLength) => {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }
    return value;
  };

  const resumenBodyTemplate = (rowData) => {
    return (
      <div className="campoResumen" onClick={() => handleResumenClick(rowData)}>
        {novedadesGrupoState && (
          <>
            <strong>Usuario: </strong> {rowData.Usuario} <br />
          </>
        )}
        <strong>Descripción: </strong>
        {rowData.Descripcion} {rowData.Promovido && <strong>PROMOVIDO</strong>}
        <br />
        {rowData.Periodo !== "" && (
          <>
            <strong>Fecha: </strong>
            {rowData.Periodo}
            <br />
          </>
        )}
        {rowData.Cliente !== "" && (
          <>
            <strong>
              {rowData.ClienteNuevo === true ? "Nuevo Cliente:" : "Cliente:"}
            </strong>
            {rowData.Cliente}
            <br />
          </>
        )}
        {rowData.CecosNuevo !== "" && (
          <>
            <strong>Nuevo centro de costros: </strong>
            {rowData.CecosNuevo}
            <br />
          </>
        )}
        {rowData.ImporteTotal > 0 && (
          <>
            <strong>Importe: </strong>$ {formatNumber(rowData.ImporteTotal)}
            <br />
          </>
        )}
        {rowData.OrdenDeCompra !== "" && (
          <>
            <strong>Orden de Compra: </strong>
            {rowData.OrdenDeCompra}
            <br />
          </>
        )}
        {rowData.Recursos !== null && rowData.Recursos.length > 0 && (
          <>
            {rowData.Recursos.length > 1 ? (
              <strong>Recursos: </strong>
            ) : (
              <strong>Recurso: </strong>
            )}

            {rowData.Recursos.map((e, index) => {
              if (index >= 0 && index <= 1) {
                return (
                  <div key={index}>
                    {e.Recurso} - {e.Periodo}
                    {e.Porc?.map((p, i) => (
                      <div key={i}>
                        HS: {p.Cantidad} - {p.PorcCC}%
                      </div>
                    ))}
                  </div>
                );
              } else if (index === 2) {
                return (
                  <div key={index}>
                    <b>
                      ...<i>Click para ver todos los recursos</i>
                    </b>
                  </div>
                );
              }
            })}
          </>
        )}
        {rowData.Adjuntos?.length > 0 && (
          <>
            <strong>Adjuntos: </strong>
            {rowData.Adjuntos}
            <br />
          </>
        )}
        {rowData.Motivo !== "" && (
          <>
            <strong>Motivo: </strong> {rowData.Motivo}
            <br />
          </>
        )}
        {rowData.Comentarios !== "" && (
          <>
            <strong>Comentarios: </strong>{" "}
            {limitarCaracteres(rowData.Comentarios, 30)}
            <br />
          </>
        )}
      </div>
    );
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <div>
        {bandeja === true ? (
          rowData.Estado === "pendiente" ? (
            <>
              <button
                onClick={() => {
                  setVisibleModalAceptar(true);
                  setNovedadSeleccionada(rowData);
                }}
                className="button-estado-modal green"
              >
                <i
                  className="custom-target-icon pi pi-fw pi-check-circle"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Aceptar"
                ></i>
              </button>
              <br />
              <br />
              <button
                onClick={() => {
                  setVisibleModalRechazar(true);
                  setNovedadSeleccionada(rowData);
                }}
                className="button-estado-modal red"
              >
                <i
                  className="custom-target-icon pi pi-fw pi-times-circle"
                  style={{ width: "60px" }}
                  data-pr-tooltip="Rechazar"
                ></i>
              </button>
            </>
          ) : (
            capitalizarPrimeraLetra(rowData.Estado)
          )
        ) : (
          capitalizarPrimeraLetra(rowData.Estado)
        )}
      </div>
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <div>
        <div style={{ marginBottom: "5px", fontSize: "12px" }}>
          Fecha Exacta*
        </div>
        <InputMask
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          mask="9999-99-99"
          // slotChar="yyyy-mm-dd"
          placeholder="Año-Mes-Dia"
        />
        <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
        <InputMask
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          mask="9999-99"
          // slotChar="yyyy-mm-dd"
          placeholder="Año-Mes"
        />
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={estados}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Seleccione"
        className="p-column-filter"
        //showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  return (
    <>
      <ModalAceptada
        visible={visibleModalAceptar}
        setVisible={setVisibleModalAceptar}
        novedadSeleccionada={novedadSeleccionada}
      />
      <ModalRechazada
        visible={visibleModalRechazar}
        setVisible={setVisibleModalRechazar}
        novedadSeleccionada={novedadSeleccionada}
      />
      <Tooltip target=".custom-target-icon" />
      <DataTable
        value={allChecked ? novedades : novedadesFiltradas}
        scrollable
        paginator
        className="p-datatable-customers"
        rows={10}
        dataKey="IdSecuencial"
        filters={filters}
        filterDisplay="menu"
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)" }}
        sortField="IdSecuencial"
        sortMode="single" //sortMode="multiple"
        sortOrder={-1}
      >
        <Column
          sortable
          field="IdSecuencial"
          header="ID"
          style={{ justifyContent: "center", minWidth: "2rem" }}
          filter
          filterPlaceholder="Buscar por ID"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={IDBodyTemplate}
        />
        <Column
          sortable
          field="Fecha"
          header="Fecha"
          style={{ justifyContent: "center", minWidth: "8rem" }}
          filter
          filterElement={dateFilterTemplate}
          filterPlaceholder="Buscar por fecha"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />

        {usuario.Rol === "admin" && bandeja === true && (
          <Column
            sortable
            field="Usuario"
            header="Usuario"
            style={{ minWidth: "8rem" }}
            filter
            filterPlaceholder="Buscar por Usuario"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        )}
        {usuario.Rol === "comercial" && (
          <Column
            sortable
            field="CecosNuevo"
            header="Cecos Nuevo"
            filterField="CecosNuevo"
            style={{ minWidth: "10rem", maxWidth: "13rem" }}
            filter
            filterPlaceholder="Buscar por cecos"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        )}
        {usuario.Rol !== "comercial" && (
          <Column
            sortable
            field="Periodo"
            header="Periodo"
            filterField="Periodo"
            style={{ minWidth: "10rem", maxWidth: "13rem" }}
            filter
            filterPlaceholder="Buscar por período"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        )}
        <Column
          sortable
          field="Cliente"
          header="Cliente"
          filterField="Cliente"
          style={{ minWidth: "10rem", maxWidth: "13rem" }}
          filter
          filterPlaceholder="Buscar por cliente"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        {usuario.Rol !== "comercial" && (
          <Column
            sortable
            field="ConceptoDeFacturacion"
            header="Concepto"
            filterField="ConceptoDeFacturacion"
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Buscar por concepto"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        )}

        <Column
          sortable
          field="Estado"
          header="Estado"
          style={{ minWidth: "12rem" }}
          filter
          filterPlaceholder="Buscar por status"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={estadoBodyTemplate}
          filterElement={statusFilterTemplate}
        />
        <Column
          field="Resumen"
          header="Resumen"
          style={{
            justifyContent: "start",
            minWidth: "300px",
            whiteSpace: "pre-line",
            textAlign: "start",
          }}
          body={resumenBodyTemplate}
          filter
          filterPlaceholder="Buscar"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
      </DataTable>
      <PopUpResumen
        resumenVisible={resumenVisible}
        setResumenVisible={setResumenVisible}
        rowDataSelection={rowDataSelection}
        tipoDeNovedad={tipoDeNovedad}
        setTipoDeNovedad={setTipoDeNovedad}
      />
    </>
  );
};

export default TablaFacturacion;
