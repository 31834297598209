import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styles from "./tableCecos.module.css";

const TableRecCecos = ({ arrayRecCecos }) => {
  return (
    <>
      <h4 className="form-subtitle">Asignación actual</h4>
      <div className={styles.containerTable}>
        <DataTable
          emptyMessage="No hay registros"
          value={arrayRecCecos}
          responsiveLayout="scroll"
          style={{ fontFamily: "var(--main-font)" }}
        >
          <Column field="CcNum" header="Numero CC"></Column>
          <Column field="CcNombre" header="Cliente"></Column>
          <Column field="CcPorc" header="Porcentaje"></Column>
        </DataTable>
      </div>
    </>
  );
};

export default TableRecCecos;
