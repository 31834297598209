import React, { useRef, useState, useContext, useEffect } from "react";
import Menu from "../components/Menu";
import logoITP from "../imagenes/logo.png";

import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import { FilterOperator } from "primereact/api";
import { InputSwitch } from "primereact/inputswitch";
import { NovedadesContext } from "../contexts/NovedadesContext";
import TableProveedores from "../components/TableProveedores";
import TablaFacturacion from "../components/TableFacturacion";
import TableRecursos from "../components/TableRecursos";
import TableServiciosBDE from "../components/TableServiciosBDE";
import { AuthContext } from "../contexts/AuthContext";
import "../styles/pages/novedades.css";
import { useNavigate } from "react-router-dom";

const BandejaDeEntrada = () => {
  const history = useNavigate();
  const { tipoNovedad, setTipoNovedad } = useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const toast = useRef(null);
  const [allChecked, setAllChecked] = useState(false);

  const rolIndexMap = {
    admin: 0,
    comercialSop: 3,
    serviciosSop: 3,
    po: 2,
    ta: 2,
    cultura: 2,
    servicios: 3,
    comercial: 3,
    board: 3,
    sustentabilidad: 3,
    codeki: 3,
  };

  useEffect(() => {
    const index =
      rolIndexMap[usuario.Rol] !== undefined ? rolIndexMap[usuario.Rol] : -1;
    if (index !== -1) {
      setTipoNovedad(index);
    }
  }, []);

  useEffect(() => {
    setAllChecked(false);
  }, [tipoNovedad]);

  const [filters, setFilters] = useState({
    Tipo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Recurso: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Fecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Periodo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Usuario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  const handleRedirect = () => {
    history("/500-patagonians");
  };

  return (
    <div className="PatagonianFest-container">
      <div className="container">
        <Toast ref={toast} />
        <Menu />
        <div className="encabezado">
          <h3 className="titulo">Portal de novedades</h3>
          <img className="imagen_encabezado" src={logoITP} alt="" />
        </div>
        <div
          className="titulo_novedades"
          style={{ margin: "0.5rem 0 0.2rem 0" }}
        >
          <h3 className="">Bandeja de entrada</h3>
          <div className=" switch">
            <InputSwitch
              checked={allChecked}
              onChange={(e) => setAllChecked(e.value)}
            />
            <span style={{ fontSize: "12px" }}>
              Incluir novedades aceptadas y rechazadas
            </span>
          </div>
        </div>
        <div>
          <TabView
            activeIndex={tipoNovedad}
            onTabChange={(e) => {
              setTipoNovedad(e.index);
            }}
            className="card"
          >
            {usuario.Rol === "admin" && (
              <TabPanel header="Proveedores" className="titulo_solapa">
                <TableProveedores bandeja={true} allChecked={allChecked} />
              </TabPanel>
            )}
            {usuario.Rol === "admin" && (
              <TabPanel header="Clientes" className="titulo_solapa">
                <TablaFacturacion bandeja={true} allChecked={allChecked} />
              </TabPanel>
            )}
            {(usuario.Rol === "po" ||
              usuario.Rol === "ta" ||
              usuario.Rol === "cultura") && (
              <TabPanel header="Equipo" className="titulo_solapa">
                <TableRecursos bandeja={true} allChecked={allChecked} />
              </TabPanel>
            )}
            <TabPanel header="Novedades a aprobar" className="titulo_solapa">
              <TableServiciosBDE allChecked={allChecked} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default BandejaDeEntrada;
