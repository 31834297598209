import React, { useState, useContext, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import TableRecCecos from "./TableRecCecos";
import FieldCecos from "../FieldCecos";
import AutoCompleteCustom from "../AutoCompleteCustom";
import FileUploadControl from "../FileUploadControl";
import { fechaHora } from "../../helpers";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { validateTotUploadSize } from "../../helpers/validateTotUploadSize";
import "./formAsignaCC.css";

const FormAsignaCC = ({ setDisplayPosition, displayPosition, position }) => {
  const { addNovedad, periodos, getPeriodos, recursosArray } =
    useContext(NovedadesContext);
  const { username } = useContext(AuthContext);

  const [arrayRecCecos, setArrayRecCecos] = useState([]);
  const [cecosArray, setCecosArray] = useState("");
  const [arrayRecursos, setArrayRecursos] = useState();
  const [comentarios, setComentarios] = useState("");
  const [adjuntos, setAdjuntos] = useState([]);
  const [porcentajeTotal, setPorcentajeTotal] = useState();
  const [arrayCecos, setArrayCecos] = useState([]);

  const toast = useRef(null);

  useEffect(() => {
    getPeriodos();
  }, []);

  const calcularPorcentaje = () => {
    let total = 0;
    for (let i = 0; i < arrayCecos.length; i++) {
      total += arrayCecos[i].porcentaje;
    }
    setPorcentajeTotal(total);
  };

  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);
    if (name === "displayPosition") {
      setArrayCecos([]);
      formik.resetForm();
      setArrayRecCecos([]);
      setComentarios("");
      setAdjuntos([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const errorPorcentaje = (valueCecosPorcentaje) => {
    if (valueCecosPorcentaje > 100) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "La suma de porcentajes de proyectos debe ser igual a 100",
        life: 5000,
      });
    }
  };

  useEffect(() => {
    calcularPorcentaje();
  }, [arrayCecos]);

  useEffect(() => {
    let array = recursosArray.map((item) => {
      let Resumen =
        item.Apellido + " " + item.Nombre + " (" + item.Legajo + ")";
      return {
        ...item,
        Resumen,
      };
    });
    setArrayRecursos(array);
  }, [recursosArray]);

  const formik = useFormik({
    initialValues: {
      recurso: null,
      periodo: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.recurso) {
        errors.recurso = "Campo requerido.";
      }
      if (!data.periodo) {
        errors.periodo = "Campo requerido.";
      }
      return errors;
    },
    onSubmit: (data) => {
      if (porcentajeTotal < 100 || porcentajeTotal > 100) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "La suma de porcentajes de proyectos debe ser igual a 100",
          life: 5000,
        });
      } else {
        onHide("displayPosition");
        setAdjuntos([]);
        formik.resetForm();
        let distArray = [];
        arrayCecos.forEach((e) => {
          distArray.push({
            Porcentaje: e.porcentaje,
            Cecos: {
              IdCecos: e.id,
              Cliente: e.cliente,
              Proyecto: e.proyecto,
              Codigo: e.codigo,
            },
          });
        });
        addNovedad(
          {
            Tipo: "RH",
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Usuario: username,
            Descripcion: "Asignación centro de costos",
            RecursosStr: JSON.stringify([
              { recurso: formik.values.recurso.Resumen },
            ]),
            Periodo: formik.values.periodo,
            DistribucionesStr: JSON.stringify(distArray),
            Comentarios: comentarios,
          },
          adjuntos
        );
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <Dialog
      draggable={false}
      header="Asignación a centro de costos"
      blockScroll={true}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => onHide("onHide")}
    >
      {/* <ConfirmDialog /> */}
      <Toast ref={toast} />
      <div className="flex justify-content-center form-pago-prov">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="input-container">
            <span className="p-float-label">
              <AutoCompleteCustom
                id="recurso"
                dropdown
                arrayValues={arrayRecursos}
                value={formik.values.recurso}
                field="Resumen"
                forceSelection
                onChange={formik.handleChange}
                onSelect={(e) => setArrayRecCecos(e.value.Rcc)}
                className={classNames({
                  "p-invalid": isFormFieldValid("recurso"),
                })}
              />
              <label
                htmlFor="recurso"
                className={classNames({
                  "p-error": isFormFieldValid("recurso"),
                })}
              >
                Recurso*
              </label>
            </span>
            {getFormErrorMessage("recurso")}
          </div>
          <div className="input-container">
            <span className="p-float-label">
              <TableRecCecos arrayRecCecos={arrayRecCecos} />
            </span>
          </div>
          <div className="p-fluid grid">
            <div className="input-container small">
              <span className="p-float-label">
                <Dropdown
                  id="periodo"
                  name="periodo"
                  value={formik.values.periodo}
                  onChange={formik.handleChange}
                  options={periodos}
                  className={classNames({
                    "p-invalid": isFormFieldValid("periodo"),
                  })}
                />
                <label
                  htmlFor="periodo"
                  className={classNames({
                    "p-error": isFormFieldValid("periodo"),
                  })}
                >
                  Periodo*
                </label>
              </span>
              {getFormErrorMessage("periodo")}
            </div>
          </div>
          <FieldCecos
            cecosArray={cecosArray}
            setCecosArray={setCecosArray}
            calcularPorcentaje={calcularPorcentaje}
            arrayCecos={arrayCecos}
            setArrayCecos={setArrayCecos}
            errorPorcentaje={errorPorcentaje}
          />
          <div className="row-container">
            <div className="input-container small">
              <span className="p-float-label">
                <InputNumber
                  inputId="porcentaje"
                  value={porcentajeTotal}
                  autoComplete="off"
                  disabled
                />
                <label htmlFor="porcentaje">Porcentaje Total</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  id="comentarios"
                  maxLength={500}
                  autoResize
                  value={comentarios}
                  autoComplete="off"
                  onChange={(e) => setComentarios(e.target.value)}
                />
                <label htmlFor="comentarios">Comentarios</label>
              </span>
            </div>
          </div>
          <FileUploadControl setAdjuntos={setAdjuntos} />
          <div className="final-submit">
            <Button type="submit" label="GUARDAR" disabled={!validateTotUploadSize(adjuntos)} />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormAsignaCC;
