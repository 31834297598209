import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
//import { Button } from "primereact/button";
import { Tarjeta } from "../../components/Tarjeta";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import Menu from "../../components/Menu";
import logoitp from "../../imagenes/logo.png";
import styles from "./paneladmin.css";

const PanelAdmin = () => {
  const { getAllUsers, loading: loadingUsers } = useContext(AuthContext);
  const {
    getNovedades,
    loading: loadingNovedades,
    getRecursos,
    recursosArray,
  } = useContext(NovedadesContext);
  const [usuariosCount, setUsuariosCount] = useState(0);
  const [usuariosExtCount, setUsuariosExtCount] = useState(0);
  const [novedadesCount, setNovedadesCount] = useState(0);

  useEffect(() => {
    const handleGetUsers = async () => {
      const data = await getAllUsers();
      if (data) {
        let usuarios = data.filter((x) => x.Rol !== "");
        setUsuariosCount(usuarios.length);
      }
    };
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleGetUsersExt = async () => {
      await getRecursos();
      let usuariosExt = recursosArray.filter((x) => x.Legajo > 10000);
      setUsuariosExtCount(usuariosExt.length);
    };
    handleGetUsersExt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleGetData = async () => {
      const data = await getNovedades();
      if (data) {
        setNovedadesCount(data.length);
      }
    };
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleUsuariosRol = () => {
    navigate("/paneladmin/usuarios-roles");
  };

  const handleUsuariosExt = () => {
    navigate("/paneladmin/usuarios-externos");
  };
  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades">
        <h3 className="">Panel de administrador</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexWrap:'wrap' }}>
        <Tarjeta
          icon="pi pi-users"
          title="Usuarios con rol"
          value={usuariosCount}
          animation={true}
          style={{ cursor: "pointer" }}
          handleClick={handleUsuariosRol}
        />
        <Tarjeta
          icon="pi pi-users"
          title="Usuarios externos"
          animation={true}
          value={usuariosExtCount}
          style={{ cursor: "pointer" }}
          handleClick={handleUsuariosExt}
        />
        <Tarjeta
          icon="pi pi-th-large"
          title="Novedades"
          value={novedadesCount}
        />
      </div>
      {(loadingNovedades || loadingUsers) && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }} />
        </div>
      )}
    </div>
  );
};

export default PanelAdmin;
