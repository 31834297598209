import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "../styles/tableCecos.css";

const TableCecos = ({
  calcularPorcentaje,
  arrayCecos,
  setArrayCecos,
  setTableValueCecos,
  novedadTipo,
  verSoloLectura,
}) => {
  const deleteNovedad = (rowData) => {
    setArrayCecos(arrayCecos.filter((e) => e.id !== rowData.id));
    calcularPorcentaje();
    setTableValueCecos([]);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {!verSoloLectura && (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-rounded p-button-text button-icon"
            onClick={() => deleteNovedad(rowData)}
            aria-label="Submit"
          />
        )}
      </>
    );
  };

  return (
    <div className="container-tableCecos">
      <DataTable
        emptyMessage="No hay registros"
        value={arrayCecos}
        responsiveLayout="scroll"
        style={{ fontFamily: "var(--main-font)" }}
      >
        <Column
          field={novedadTipo !== "PB" ? "codigo" : "CcNum"}
          header="Numero CC"
        ></Column>
        <Column
          field={novedadTipo !== "PB" ? "cliente" : "CcNombre"}
          header="Centro de Costos"
        ></Column>
        <Column
          field={novedadTipo !== "PB" ? "porcentaje" : "CcPorc"}
          header="Porcentaje"
        ></Column>
        {novedadTipo !== "PB" && (
          <Column
            headerStyle={{ width: "4rem", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={actionBodyTemplate}
          />
        )}
      </DataTable>
    </div>
  );
};

export default TableCecos;
