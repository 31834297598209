import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useContext } from "react";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";

export const ModalEliminarHabilidades = ({
  infoModal,
  setEditSkill,
  open,
  position,
}) => {
  const { habilidadSeleccionada, deleteCertificaciones, deleteNivelEstudio } =
    useContext(HabilidadesContext);
  const onHide = () => {
    open(false);
    setEditSkill(false);
  };

  const headerContent = (
    <div>
      <p className="p1-modal-habilidades" style={{ fontWeight: "bold" }}>{`
    Eliminar ${
      infoModal === "Certificaciones"
        ? "certificación"
        : infoModal === "Nivel de estudios"
        ? "Nivel de estudio"
        : ""
    } `}</p>
    </div>
  );

  const eliminarHabilidad = () => {
    infoModal === "Certificaciones"
      ? deleteCertificaciones(habilidadSeleccionada._id)
      : deleteNivelEstudio(habilidadSeleccionada._id);
    open(false);
  };

  return (
    <Dialog
      draggable={false}
      closable={true}
      id="custom-bg-modal"
      style={{
        width: "384px",
        height: "168px",
      }}
      className="custom-modal"
      visible={true}
      position={position}
      onHide={() => onHide("onHide")}
      header={headerContent}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: infoModal === "Nivel de estudios" ? "20px" : null,
        }}
      >
        <p>
          ¿Estás seguro que querés eliminar{" "}
          {infoModal === "Certificaciones"
            ? "la certificación"
            : infoModal === "Nivel de estudios"
            ? "el Nivel de estudio"
            : ""}
          ?
        </p>
      </div>
      <footer className="footer-modal-habilidades" style={{ border: "none" }}>
        <Button
          className="button-modal-habilidades-cancelar"
          onClick={() => {
            open(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          className="button-modal-habilidades"
          onClick={() => {
            eliminarHabilidad();
          }}
        >
          Sí, eliminar
        </Button>
      </footer>
    </Dialog>
  );
};
