import React from "react";
import Menu from "../../../components/Menu";
import DetalleDeCotizacion from "../ResumenDeCotizacion/DetalleDeCotizacion";
import logoitp from "../../../imagenes/logo.png";
import CustomStep from "../CustomStep";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const ResumenDeCotizacion = () => {

  const navigate = useNavigate()
  
  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div style={{ marginTop: "15px" }}>
        <Button
          label="Volver atrás"
          icon="pi pi-angle-left"
          className="button-volver-inicio"
          style={{ marginTop: "10px" }}
          onClick={()=>navigate("/cotizador/talentos")}
        />
      </div>
      <div style={{ marginLeft: "25px", marginTop: "10px" }}>
      <h1
       style={{ fontSize: "24px" }}
      >
        Resumen de cotización
      </h1>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
      <CustomStep activeIndex={2} />
      </div>
      </div>
      <DetalleDeCotizacion />
    </div>
  );
};

export default ResumenDeCotizacion;
