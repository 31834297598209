const dataFreelance = [
  {
    nroFreelance: 1001,
    cuit: 20123456789,
    apellido: "Lipa",
    nombre: "Dua",
    fechaIngreso: "2020-05-30",
    nomina: "N",
    gerente: 1037,
    vertical: "servicios",
    horasMen: 156,
    cargo: "Desarrolador Jr.",
    facturaMonto: 272722,
    facturaDesde: "2020-01-01T00:00:00",
    facturaADCuit: 20382938221,
    facturaADMonto: 272722.23,
    facturaADDesde: "2020-06-01T00:00:00",
    b21Monto: 272722.23,
    b21Desde: "2020-06-02T00:00:00",
    comentario: "",
    habilitado: true,
    fechaBaja: "",
    cecos: [
      { id: 0, CcPorcentaje: 10, CcNum: 120, CcNombre: "NNN SWFactory" },
      { id: 1, CcPorcentaje: 20, CcNum: 765, CcNombre: "NNN ICBC" },
      { id: 2, CcPorcentaje: 70, CcNum: 213, CcNombre: "NNN Galicia" },
    ],
    telefono: "093 2932-321321",
    mailLaboral: "dlipa@itpatagonia.com",
    mailPersonal: "dlipa@gmail.com",
    fechaNac: "1995-05-14",
    genero: "f",
    nacionalidad: "Gran Bretaña",
    domCalle: "",
    domNro: 2782,
    domPiso: 9,
    domDepto: "A",
    domLoc: "San Martín",
    domProv: "Buenos Aires",
  },
  {
    nroFreelance: 1002,
    cuit: 30876543212,
    apellido: "Styles",
    nombre: "Harry",
    fechaIngreso: "2019-10-22",
    nomina: "Si",
    gerente: 1034,
    vertical: "servicios",
    horasMen: 121,
    cargo: "Desarrolador Sr.",
    facturaMonto: 212721.98,
    facturaDesde: "2019-11-01T00:00:00",
    facturaADCuit: 303823238294,
    facturaADMonto: 272722.23,
    facturaADDesde: "2019-11-01T00:00:00",
    b21Monto: 2763722.31,
    b21Desde: "2020-01-10T00:00:00",
    comentario: "",
    habilitado: "S",
    fechaBaja: "",
    cecos: [
      { id: 0, porcentaje: 10, codigo: 120, cliente: "NNN SWFactory" },
      { id: 1, porcentaje: 20, codigo: 765, cliente: "NNN ICBC" },
      { id: 2, porcentaje: 70, codigo: 213, cliente: "NNN Galicia" },
    ],
    telefono: "11 3356-3893",
    mailLaboral: "hstyles@itpatagonia.com",
    mailPersonal: "hstyles@gmail.com",
    fechaNac: "1993-10-20",
    genero: "X",
    nacionalidad: "Chile",
    domCalle: "Araucarias",
    domNro: 938,
    domPiso: 0,
    domDepto: "",
    domLoc: "San Isidro",
    domProv: "Buenos Aires",
  },
  {
    nroFreelance: 1003,
    cuit: 20123456789,
    apellido: "Eastwood",
    nombre: "Clint",
    fechaIngreso: "2020-05-30",
    nomina: "Si",
    gerente: 1037,
    vertical: "servicios",
    horasMen: 156,
    cargo: "Desarrolador Jr.",
    facturaMonto: 272722.23,
    facturaDesde: "2020-06-01",
    facturaADCuit: 20382938221,
    facturaADMonto: 272722.23,
    facturaADDesde: "2020-06-01",
    b21Monto: 272722.23,
    b21Desde: "2020-06-01",
    comentario: "",
    habilitado: "S",
    fechaBaja: "",
    cecos: [
      { id: 0, porcentaje: 10, codigo: 120, cliente: "NNN SWFactory" },
      { id: 1, porcentaje: 20, codigo: 765, cliente: "NNN ICBC" },
      { id: 2, porcentaje: 70, codigo: 213, cliente: "NNN Galicia" },
    ],
    telefono: "0221 43-5643",
    mailLaboral: "ceastwood@itpatagonia.com",
    mailPersonal: "ceastwood@gmail.com",
    fechaNac: "1935-11-05",
    genero: "M",
    nacionalidad: "Argentina",
    domCalle: "Campana",
    domNro: 1272,
    domPiso: 1,
    domDepto: "D",
    domLoc: "CABA",
    domProv: "Buenos Aires",
  },
  {
    nroFreelance: 1004,
    cuit: 20124326789,
    apellido: "Duran",
    nombre: "Miguel Angel",
    fechaIngreso: "2018-01-30",
    nomina: "Si",
    gerente: 997,
    vertical: "servicios",
    horasMen: 116,
    cargo: "Arquitect",
    facturaMonto: 479724.21,
    facturaDesde: "2018-06-12",
    facturaADCuit: 20354328221,
    facturaADMonto: 272722.23,
    facturaADDesde: "2018-07-01",
    b21Monto: 523722.32,
    b21Desde: "2020-03-12",
    comentario: "",
    habilitado: "S",
    fechaBaja: "",
    cecos: [
      { id: 0, porcentaje: 10, codigo: 120, cliente: "NNN SWFactory" },
      { id: 1, porcentaje: 20, codigo: 765, cliente: "NNN ICBC" },
      { id: 2, porcentaje: 70, codigo: 213, cliente: "NNN Galicia" },
    ],
    telefono: "0271 51-5973",
    mailLaboral: "maduran@itpatagonia.com",
    mailPersonal: "midudev@gmail.com",
    fechaNac: "1985-02-15",
    genero: "M",
    nacionalidad: "España",
    domCalle: "Havana",
    domNro: 262,
    domPiso: 2,
    domDepto: "F",
    domLoc: "CABA",
    domProv: "Buenos Aires",
  },
  {
    nroFreelance: 1005,
    cuit: 30976043010,
    apellido: "Aznar",
    nombre: "Pedro",
    fechaIngreso: "2017-08-11",
    nomina: "Si",
    gerente: 991,
    vertical: "servicios",
    horasMen: 159,
    cargo: "Devops Jr.",
    facturaMonto: 112921.03,
    facturaDesde: "2017-04-11",
    facturaADCuit: 30393028294,
    facturaADMonto: 172792.43,
    facturaADDesde: "2018-11-01",
    b21Monto: 1763922.21,
    b21Desde: "2020-01-10",
    comentario: "",
    habilitado: "S",
    fechaBaja: "",
    cecos: [
      { id: 0, porcentaje: 10, codigo: 120, cliente: "NNN SWFactory" },
      { id: 1, porcentaje: 20, codigo: 765, cliente: "NNN ICBC" },
      { id: 2, porcentaje: 70, codigo: 213, cliente: "NNN Galicia" },
    ],
    telefono: "11 9356-3123",
    mailLaboral: "paznar@itpatagonia.com",
    mailPersonal: "pedroazn52@gmail.com",
    fechaNac: "1952-04-20",
    genero: "M",
    nacionalidad: "Uruguay",
    domCalle: "Alvear",
    domNro: 5180,
    domPiso: 0,
    domDepto: "",
    domLoc: "Salsipuedes",
    domProv: "Córdoba",
  },
];
export { dataFreelance };
