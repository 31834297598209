import React, { useState, useContext, useRef, useEffect } from "react";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { AuthContext } from "../contexts/AuthContext";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import Menu from "../components/Menu";
import SpeedDialCustom from "../components/SpeedDialCustom";
import TableProveedores from "../components/TableProveedores";
import TablaFacturacion from "../components/TableFacturacion";
import TableRecursos from "../components/TableRecursos";
import FormPagoProveedores from "../components/FormPagoProveedores";
import FormFacturacion from "../components/FormFacturacion";
import FormHorasExtras from "../components/FormHorasExtras";
import FormRecursos from "../components/FormRecursos";
import FormAsignaCC from "../components/FormAsignaCC";
import FormNuevoCC from "../components/FormNuevoCC";
import logoITP from "../imagenes/logo.png";
import "../styles/pages/novedades.css";
import { useNavigate } from "react-router-dom";

const Novedades = () => {
  const history = useNavigate();
  const { setTipoNovedad, tipoNovedad, getProveedores, getRecursos } =
    useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const [displayPPModal, setDisplayPPModal] = useState(false);
  const [displayFSModal, setDisplayFSModal] = useState(false);
  const [displayIGModal, setDisplayIGModal] = useState(false);
  const [displayPBModal, setDisplayPBModal] = useState(false);
  const [displayHEModal, setDisplayHEModal] = useState(false);
  const [displayREModal, setDisplayREModal] = useState(false);
  const [displayRAModal, setDisplayRAModal] = useState(false);
  const [displayCCModal, setDisplayCCModal] = useState(false);
  const [positionModal, setPositionModal] = useState("right");
  const [allChecked, setAllChecked] = useState(false);
  const [novedadesGrupoState, setNovedadesGrupoState] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    setTipoNovedad(0);
    getProveedores();
    getRecursos();
  }, []);

  useEffect(() => {
    setAllChecked(false);
  }, [tipoNovedad]);

  const speedDialClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    if (position) {
      setPositionModal(position);
    }
  };

  const dialogFuncMap = {
    FormPP: setDisplayPPModal,
    FormFS: setDisplayFSModal,
    FormIG: setDisplayIGModal,
    FormPB: setDisplayPBModal,
    FormHE: setDisplayHEModal,
    FormRE: setDisplayREModal,
    FormRA: setDisplayRAModal,
    FormCC: setDisplayCCModal,
  };

  const handleRedirect = () => {
    history('/500-patagonians');
  }

  return (
    <div className="PatagonianFest-container">
    <div className="container">
      <Toast ref={toast} />
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoITP} alt="" />
      </div>
      <div className="titulo_novedades">
        <div>
          <h3 className="">Bandeja de salida</h3>
          <SpeedDialCustom
            speedDialClick={speedDialClick}
            tipoNovedad={tipoNovedad}
          />
        </div>
        <div className="containerSwitch">
          {(usuario.EsAdministrador ||
            usuario.Rol === "comercialSop" ||
            usuario.Rol === "serviciosSop") && (
            <div className="switch switchGrupo">
              <InputSwitch
                checked={novedadesGrupoState}
                onChange={(e) => setNovedadesGrupoState(e.value)}
              />
              <span style={{ fontSize: "12px" }}>
                Incluir novedades del grupo
              </span>
            </div>
          )}

          <div className="switch">
            <InputSwitch
              checked={allChecked}
              onChange={(e) => setAllChecked(e.value)}
            />
            <span style={{ fontSize: "12px" }}>
              Incluir novedades aceptadas y rechazadas
            </span>
          </div>
        </div>
      </div>
      <div>
        <TabView
          activeIndex={tipoNovedad}
          onTabChange={(e) => setTipoNovedad(e.index)}
          className="card"
        >
          <TabPanel header="Proveedores" className="titulo_solapa">
            <TableProveedores
              allChecked={allChecked}
              novedadesGrupoState={novedadesGrupoState}
            />
          </TabPanel>
          {usuario.Rol !== "comunicaciones" &&
            usuario.Rol !== "marketing" &&
            usuario.Rol !== "sustentabilidad" && 
            usuario.Rol !== "po" && (
              <TabPanel header="Clientes" className="titulo_solapa">
                <TablaFacturacion
                  allChecked={allChecked}
                  novedadesGrupoState={novedadesGrupoState}
                />
              </TabPanel>
            )}
          {usuario.Rol !== "serviciosSop" && (
            <TabPanel header="Equipo" className="titulo_solapa">
              <TableRecursos
                allChecked={allChecked}
                novedadesGrupoState={novedadesGrupoState}
              />
            </TabPanel>
          )}
        </TabView>
      </div>
      <FormPagoProveedores
        setDisplayPosition={setDisplayPPModal}
        displayPosition={displayPPModal}
        position={positionModal}
      />
      <FormFacturacion
        setDisplayPosition={setDisplayFSModal}
        displayPosition={displayFSModal}
        position={positionModal}
        novedadTipo="FS"
      />
      <FormFacturacion
        setDisplayPosition={setDisplayIGModal}
        displayPosition={displayIGModal}
        position={positionModal}
        novedadTipo="IG"
      />
      <FormFacturacion
        setDisplayPosition={setDisplayPBModal}
        displayPosition={displayPBModal}
        position={positionModal}
        novedadTipo="PB"
      />
      <FormHorasExtras
        setDisplayPosition={setDisplayHEModal}
        displayPosition={displayHEModal}
        position={positionModal}
      />
      <FormRecursos
        setDisplayPosition={setDisplayREModal}
        displayPosition={displayREModal}
        position={positionModal}
      />
      <FormAsignaCC
        setDisplayPosition={setDisplayRAModal}
        displayPosition={displayRAModal}
        position={positionModal}
      />
      <FormNuevoCC
        setDisplayPosition={setDisplayCCModal}
        displayPosition={displayCCModal}
        position={positionModal}
      />
    </div>
    </div>
  );
};

export default Novedades;
