import { useContext } from "react";
import Novedades from "../Novedades";
import { CotizadorContext } from "../../contexts/CotizadorContext";

const Home = () => {
  const { setGuardarCoti } = useContext(CotizadorContext);

  setGuardarCoti(false);

  return (
    <>
      <Novedades />;
    </>
  );
};

export default Home;
