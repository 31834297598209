import React from "react";
import ReactDOM from "react-dom/client";
import AuthContextProvider from "./contexts/AuthContext";
import NovedadesContextProvider from "./contexts/NovedadesContext";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import CotizadorContextProvider from "./contexts/CotizadorContext";
import HabilidadesContextProvider from "./contexts/HabilidadesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <NovedadesContextProvider>
          <CotizadorContextProvider>
            <HabilidadesContextProvider>
              <App />
            </HabilidadesContextProvider>
          </CotizadorContextProvider>
        </NovedadesContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
